import React, { useState, useEffect } from 'react';
import { doc, updateDoc, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase_service';
import { uploadChatImage } from '../../Controller/firebase/chat_image_upload';
import Button from './button';
import PopupMenu from './chat_popup';
import { getLastSeenStatus } from '../../utils/chat_userid';

const ChatWindow = ({ message: initialMessage, selectedUser, currentUser }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isAccepted, setIsAccepted] = useState(initialMessage?.accepted || false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isImportant, setIsImportant] = useState(false);
    const [lastSeen, setLastSeen] = useState(''); // State to hold the last seen status
  
    useEffect(() => {
      if (initialMessage) {
        const messagesRef = collection(db, 'chats', initialMessage.id, 'messages');
        const q = query(messagesRef, orderBy('createdAt', 'asc'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const messagesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMessages(messagesData);
        });
        return () => unsubscribe();
      }
    }, [initialMessage]);
  
    useEffect(() => {
      // Fetch and set the last seen status
      if (selectedUser) {
        const status = getLastSeenStatus(selectedUser);
        setLastSeen(`Last seen: ${status}`);
      }
    }, [selectedUser]);
  
    const handleAccept = async () => {
      try {
        if (initialMessage && initialMessage.id) {
          await updateDoc(doc(db, 'chats', initialMessage.id), {
            accepted: true,
          });
          setIsAccepted(true);
        } else {
          console.error("Initial message or message ID is undefined");
        }
      } catch (error) {
        console.error("Error accepting chat: ", error);
      }
    };
  
    const handleSendMessage = async (e) => {
      e.preventDefault();
  
      if (!initialMessage || !selectedUser || !currentUser) {
        console.error('Initial message, selected user, or current user is undefined');
        return;
      }
  
      if (newMessage.trim() === '' && !selectedFile) return;
  
      try {
        let messageData = {
          text: newMessage || '',
          createdAt: serverTimestamp(),
          userId: currentUser.id,
          userName: currentUser.userName,
        };
  
        if (selectedFile) {
          const imageUrl = await uploadChatImage(initialMessage.id, selectedFile, currentUser);
          messageData = { ...messageData, imageUrl };
        }
  
        await addDoc(collection(db, 'chats', initialMessage.id, 'messages'), messageData);
  
        await updateDoc(doc(db, 'chats', initialMessage.id), {
          lastMessage: newMessage || 'Sent an image',
          lastMessageTime: serverTimestamp(),
          lastMessageUid: currentUser.id,
          lastMessageSeen: false,
        });
  
        setNewMessage('');
        setSelectedFile(null); 
      } catch (error) {
        console.error("Error sending message:", error);
      }
    };
  
    const formatTimestamp = (timestamp) => {
      if (!timestamp) return '';
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };
  
    const handleMenuToggle = () => {
      setIsMenuOpen((prev) => !prev); // Toggle the menu
    };
  
    const handleMenuClose = () => {
      setIsMenuOpen(false); // Close the menu
    };
  
    const handleMoveToImportant = async () => {
      if (initialMessage && initialMessage.id) {
        try {
          const isCurrentUserSender = currentUser.id === initialMessage.senderUid;
          const updateField = isCurrentUserSender ? 'isImportantForSender' : 'isImportantForReceiver';
          
          await updateDoc(doc(db, 'chats', initialMessage.id), {
            [updateField]: !isImportant
          });
          
          setIsImportant(!isImportant);
          console.log("Chat importance toggled successfully");
        } catch (error) {
          console.error("Error toggling chat importance: ", error);
        }
      }
    };
  
    return (
      <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
        <div className="flex justify-between items-center border-b p-4 bg-[#F9F9F9]">
          <div className="flex items-center space-x-4">
            <img 
              src={selectedUser?.userImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'} 
              alt={selectedUser?.userName || 'Unknown User'} 
              className="w-10 h-10 rounded-full" 
            />
            <div>
              <h2 className="font-semibold text-lg">{selectedUser?.userName || 'Unknown User'}</h2>
              <p className="text-xs text-gray-500">{lastSeen}</p> {/* Show last seen with prefix */}
            </div>
          </div>
          <div className="flex items-center space-x-4 relative">
            <button className="p-2" onClick={handleMoveToImportant}>
              <img 
                src={isImportant ? "/Assets/important_star.svg" : "/Assets/move_important.svg"} 
                alt="Important" 
                className="w-7 h-7" 
              />
            </button>
            <button className="p-2" onClick={handleMenuToggle}>
              <img src="/Assets/dots.svg" alt="More options" className="w-6 h-6" />
            </button>
            {/* PopupMenu component */}
            <PopupMenu isOpen={isMenuOpen} onClose={handleMenuClose} />
          </div>
        </div>
        
        <div className="flex-grow overflow-y-auto p-4">
          {messages.map((msg) => (
            <div key={msg.id} className={`flex flex-col mb-4 ${msg.userId === currentUser.id ? 'items-end' : 'items-start'}`}>
              <div 
                className={`p-3 rounded-lg max-w-xs ${msg.userId === currentUser.id ? 'bg-[#DB0000] text-white' : 'bg-gray-200'}`}
                style={msg.imageUrl ? { backgroundColor: 'transparent', padding: 0 } : {}}
              >
                {msg.imageUrl ? (
                  <img src={msg.imageUrl} alt="Chat Image" className="max-w-xs rounded-lg" />
                ) : (
                  <p>{msg.text}</p>
                )}
              </div>
              <span className="text-xs text-gray-500 mt-1">
                {formatTimestamp(msg.createdAt)}
              </span>
            </div>
          ))}
        </div>
        
        {isAccepted ? (
          <form onSubmit={handleSendMessage} className="p-4 border-t lg:mb-24 mb-10">
            <div className="flex items-center bg-[#D9D9D9] rounded-full p-1">
              <input 
                type="text" 
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Write a message" 
                className="flex-grow bg-transparent outline-none px-3 py-2 rounded-full"
              />
              <input 
                type="file" 
                accept="image/*" 
                onChange={(e) => setSelectedFile(e.target.files[0])} 
                className="hidden" 
                id="imageUpload"
              />
              <label htmlFor="imageUpload" className="cursor-pointer p-2">
                <img src="/Assets/paperclip.svg" alt="Attach" className="w-9 h-9" />
              </label>
              <button type="submit" className="p-2">
                <img src="/Assets/send_message.svg" alt="Send" className="w-9 h-9" />
              </button>
            </div>
          </form>
        ) : (
          <div className="p-4 lg:mx-16 flex flex-col items-center lg:mb-24 mb-10">
            <p className="text-center mb-4">Accept message request from {selectedUser?.userName || 'Unknown User'}?</p>
            <div className="flex justify-between space-x-2 w-full">
              <Button 
                text="Block"
                borderColor="border-gray-300"
                textColor="text-black"
                fontWeight="600"
              />
              <Button 
                text="Delete"
                borderColor="border-gray-300"
                textColor="text-black"
                fontWeight="600"
              />
              <Button 
                onClick={handleAccept}
                text="Accept"
                borderColor="border-black"
                backgroundColor="bg-black"
                textColor="text-white"
                fontWeight="600"
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default ChatWindow;