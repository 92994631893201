import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Steps = ({ number, text, destination }) => {
  return (
    <Link to={destination} className="flex items-center border-b p-6 bg-[#F9F9F9] font-poppins font-normal rounded-lg hover:bg-[#D9D9D9] text-[#232323]">
      <div className="flex-shrink-0 text-4xl font-bold text-[#DB0000]">{number}</div>
      <div className="flex-grow mx-4">
        <p className="text-[#232323]">{text}</p>
      </div>
      <div className="flex-shrink-0">
        <img 
          src="Assets/arrow_forward.svg" 
          alt="Arrow Icon" 
          className="w-6 h-6 cursor-pointer" 
        />
      </div>
    </Link>
  );
};

Steps.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default Steps;
