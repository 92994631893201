// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react';

// import { EffectCoverflow, Pagination } from 'swiper/modules';
// import Sidebar from '../../Components/main_navbar';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';

// // Install Swiper modules


// const DetailedView = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { item } = location.state || {};

//   // Redirect back to ShowcaseGrid if item is not available
//   if (!item) {
//     navigate('/');
//     return null;
//   }

//   const swiperStyles = {
//     width: '100%',
//     height: '100%',
//   };

//   const slideStyles = {
//     backgroundPosition: 'center',
//     backgroundSize: 'cover',
//     width: '300px',
//     height: '400px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   };

//   const imgStyles = {
//     display: 'block',
//     width: '100%',
//     height: '400px',
   
//   };
  

//   const handleBackClick = () => {
//     navigate('/view_photos');
//   };


//   return (
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-techna overflow-y-auto">
//     <Sidebar />
//     <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
//       <div className="flex items-center space-x-2">
//         <img
//           src="/Assets/arrow_back.svg"
//           alt="Back"
//           className="cursor-pointer mr-2 h-6 w-6"
//           onClick={handleBackClick}
//         />
//         <h1 className="text-black font-bold text-xl">Post</h1>
//         </div>
//       </div>
//     <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//     <div className="container mx-auto p-6 mt-6">
     
//       <Swiper
//         effect="coverflow"
//         grabCursor={true}
//         centeredSlides={true}
//         slidesPerView="auto"
//         coverflowEffect={{
//           rotate: 0,
//           stretch: 0,
//           depth: 250,
//           modifier: 1,
//           slideShadows: false,
//         }}
//         pagination={{ clickable: true }}
//         modules={[EffectCoverflow, Pagination]}
//         className="mySwiper"
//         style={swiperStyles}
//       >
//         {item.images.map((image, index) => (
//           <SwiperSlide key={index} style={slideStyles}>
//             <img
//               src={image}
//               alt={'Detail ${index}'}
//               style={imgStyles}
//             />
//           </SwiperSlide>
//         ))}
//       </Swiper>
//       <div className="text-center">
//         <h3 className="text-xl font-bold">{item.company}</h3>
//         <p>Details</p>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default DetailedView;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMediaImageById, deleteMediaById } from '../../../Controller/company_products'; // Import the deleteMediaById function
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import Sidebar from '../../Components/main_navbar';
import DeletePopup from '../../Components/delete_pop_up';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const DetailedView = () => {
  const { id } = useParams(); // Get the ID from the URL params
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false); // State to show/hide the delete popup

  // Fetch the media image by ID when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMediaImageById(id); // Call the API function
        setItem(response.data.data); // Update state with the fetched data
        setLoading(false); // Turn off loading
      } catch (error) {
        console.error('Error fetching media image by ID:', error);
        setLoading(false); // Turn off loading even if there is an error
      }
    };

    fetchData();
  }, [id]);

  const handleBackClick = () => {
    navigate('/view_photos');
  };

  const handleDelete = async () => {
    try {
      console.log("Deleting media with ID:", id); // Log the media ID
      const response = await deleteMediaById(id); // Call the delete API
      if (response.status === 200) {
        console.log(response)
        navigate('/view_photos'); // Navigate back to view_photos on successful delete
      } else {
        console.error('Error deleting media:', response);
      }
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!item) {
    return <div>No data available</div>;
  }

  const swiperStyles = {
    width: '100%',
    height: '100%',
  };

  const slideStyles = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '350px',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imgStyles = {
    display: 'block',
    width: '100%',
    height: '400px',
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
      <Sidebar />

      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Post</h1>
        </div>
        <div className="mr-2 md:mr-7 pr-1 flex self-end">
          <img
            src="/Assets/bin.svg"
            alt="Delete"
            className="h-7 w-7 cursor-pointer"
            onClick={() => setShowDeletePopup(true)} // Show the delete popup on bin click
          />
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="container mx-auto p-6 mt-6">
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 250,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{ clickable: true }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          style={swiperStyles}
        >
          {item.image_url && item.image_url.length > 0 && item.image_url.map((image, index) => (
            <SwiperSlide key={index} style={slideStyles}>
              <img
                src={image} // Display each image in the swiper
                alt={`Detail ${index}`}
                style={imgStyles}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="text-center">
          <h3 className="text-xl font-bold">{item.title}</h3>
          <p>{item.description}</p>
        </div>
      </div>

      {/* Show delete popup when bin is clicked */}
      {showDeletePopup && (
        <DeletePopup
          message="Are you sure you want to delete?"
          onConfirm={handleDelete} // Call the delete function on confirmation
          onClose={() => setShowDeletePopup(false)} // Close the popup without action
        />
      )}
    </div>
  );
};

export default DetailedView;

