// import React, { useState } from "react";
// import { FaStar } from "react-icons/fa";

// const Rate = () => {
//     const [rate, setRate] = useState(0);

//     return (
//         <div className="flex justify-center items-center min-h-[60vh] text-3xl">
//             {[...Array(5)].map((item, index) => {
//                 const givenRating = index + 1;
//                 return (
//                     <label key={index} className="cursor-pointer">
//                         <input
//                             type="radio"
//                             value={givenRating}
//                             className="hidden"
//                             onClick={() => {
//                                 setRate(givenRating);
//                             }}
//                         />
//                         <FaStar
//                             style={{
//                                 color: givenRating <= rate ? "yellow" : "transparent",
//                                 stroke: "red",
//                                 strokeWidth: "1px",
//                             }}
//                         />
//                     </label>
//                 );
//             })}
//         </div>
//     );
// };

// export default Rate;
// StarRating.js
import React from 'react';
import './rating_component.css'; // Assuming you have a CSS file for styling

const StarRating = ({ rating, setRating }) => {
  const handleClick = (value) => {
    if (setRating) {
      setRating(value);
    }
  };

  const renderStars = () => {
    return [1, 2, 3, 4, 5].map((star) => (
      <svg
        key={star}
        className={rating >= star ? 'filled' : 'empty'}
        onClick={() => handleClick(star)}
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill={rating >= star ? '#DB0000' : '#F2F3F3'}
        stroke={rating >= star ? '#DB0000' : '#DB0000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
      </svg>
    ));
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;

  