import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';
import { get_subscriptions_api } from '../../../Controller/subscriptions';

const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await get_subscriptions_api();
        if (response.status === 200) {
          setPlans(response.data.data);
        } else {
          setError('Failed to fetch subscriptions');
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        setError('An error occurred while fetching subscriptions');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleGoldSupplierClick = (product_id, backgroundColor) => {
    navigate('/subscription_details', { state: { product_id, backgroundColor } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] overflow-y-auto">
      <div className="flex ml-7 mt-4 w-4/5">
        <Sidebar />
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription Plans</h1>
        </div>
        <div className="lg:mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/crown.svg"
            alt="crown"
            className="h-8 w-8"
          />
        </div>
      </div>

      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex justify-center w-4/5 lg:ml-5 mx-auto overflow-auto p-4">
        <div className='flex flex-col space-y-4 w-full mt-2 md:px-0 max-w-lg'>
          <p className="font-bold text-base sm:text-lg">Plans</p>
          {plans.map((plan, index) => (
  <GoldSupplier
    key={plan.product_id}
    backgroundColor={index % 2 === 0 ? "#E5E5E5" : index % 3 === 0 ? "#A2A2A2" : "#DB0000"}
    textColor={index % 2 === 0 ? "#171717" : "#F9F9F9"}
    title={`${plan.name} Supplier`}
    subtitle="Standard Extended"
    price={`${plan.price}$`}
    height="170px"
    width="full"
    onClick={() => handleGoldSupplierClick(plan.product_id, index % 2 === 0 ? "#E5E5E5" : index % 3 === 0 ? "#A2A2A2" : "#DB0000")}
  />
))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
