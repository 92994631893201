import React, { useState } from 'react';
import { mockCompanies } from './mockcompanies';
import SearchSuggestions from './search_suggestions';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'; // Ensure this is imported correctly

const CompanyHeader = ({ setSearch, toggleMobileMenu, hideLogo,onLocationSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearch(query);
  };

  const handleLocationChange = (value) => {
    setLocationQuery(value);
  };

  const handleSelectLocation = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);
      setLocationQuery(address);
      onLocationSelect(lat, lng); // Call the provided callback with lat and lng
    } catch (error) {
      console.error('Error selecting location:', error);
    }
  };
  const handleSelectSuggestion = (suggestion) => {
    setSearchQuery(suggestion);
    setSearch(suggestion);
    setSuggestions([]); // Clear suggestions after selection
  };

  return (
    <div className="p-4 relative">
      <div className="flex flex-col md:flex-row items-center md:ml-4 md:space-x-4 w-full h-1/2 mt-10">
        <div className="relative flex items-center w-full md:w-1/3">
          <input
            type="text"
            value={searchQuery}
            placeholder="Search"
            className="p-1 md:p-2 pr-10 w-full border border-gray-400 rounded bg-[rgba(242,243,243,1)]"
            onChange={handleSearchChange}
          />
          <img src="./search1.svg" alt="Search Icon" className="absolute right-2 h-5 w-5 text-gray-400" />
          {searchQuery && (
            <div className="absolute top-full left-0 right-0 mt-1">
              <SearchSuggestions
                suggestions={suggestions}
                onSelectSuggestion={handleSelectSuggestion}
              />
            </div>
          )}
        </div>
        <div className="relative flex items-center w-full md:w-1/3 mt-4 md:mt-0">
          <PlacesAutocomplete
            value={locationQuery}
            onChange={handleLocationChange}
            onSelect={handleSelectLocation}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="relative w-full">
                <input
                  {...getInputProps({
                    placeholder: 'Location',
                    className: 'p-1 md:p-2 pr-10 w-full border border-gray-400 rounded bg-[rgba(242,243,243,1)]'
                  })}
                />
                <img src="./location.svg" alt="Location Icon" className="absolute right-2 bottom-3 h-5 w-5" />
                <div className="absolute top-full left-0 right-0 mt-1 bg-white border rounded-md overflow-y-auto z-50">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: suggestion.active ? 'suggestion-item--active' : 'suggestion-item',
                        style: suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                      })}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    </div>
  );
}

export default CompanyHeader;
