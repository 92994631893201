import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Components/main_navbar';
import FeedbackCard from '../Components/feedback_c';
import Tab from '../Components/tab';
import FeedbackSubmitted from '../Components/feedback_submitted';
import Button from '../Components/button';
import TextArea from '../Components/text_area';
import ReportContainer from '../Components/report';
import DeletePopup from '../Components/delete_pop_up';
import { getSpecificUserFeedback, getFeedback, uploadFeedback, deleteFeedback } from '../../Controller/feedback';
import { FaSpinner } from 'react-icons/fa';
import { getFeedbackById } from '../../Controller/feedback';

const FeedbackView = () => {
    const { uid } = useParams(); // Get feedback uid from the URL params
    const isOwnerView = !uid; // If uid is not present, it is the owner view

    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [activeTab, setActiveTab] = useState('received');
    const [showForm, setShowForm] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileAlert, setShowMobileAlert] = useState(false);
    const [showMobileFeedbackForm, setShowMobileFeedbackForm] = useState(false);
    const [feedbackList, setFeedbackList] = useState([]); // List from GET request
    const [newlyAddedFeedback, setNewlyAddedFeedback] = useState([]); // List for newly added feedback
    const [submittedFeedbackList, setSubmittedFeedbackList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [feedbackToDelete, setFeedbackToDelete] = useState(null);
    const [expandedFeedbackId, setExpandedFeedbackId] = useState(null); // Track expanded feedback for "more" link

    const navigate = useNavigate();
    const [skip, setSkip] = useState(0);
    const limit = 10;
    const company_uid = localStorage.getItem('company_uid'); // Fetch company_uid from localStorage

    // Fetch feedback for owner or based on uid
    useEffect(() => {
        const fetchFeedback = async () => {
            setLoading(true);
            try {
                let response;
                if (isOwnerView) {
                    if (activeTab === 'received') {
                        // Fetch only feedbacks where feedback_to matches the user's company_uid (received feedback)
                        response = await getFeedback(company_uid, skip, parseInt(limit));
                        setFeedbackList(response.data.filter(item => item.feedback_to === company_uid));
                    } else if (activeTab === 'submitted') {
                        // Fetch only feedbacks where feedback_by matches the user's company_uid (submitted feedback)
                        response = await getSpecificUserFeedback(skip, parseInt(limit));
                        setSubmittedFeedbackList(response.data.filter(item => item.feedback_by === company_uid));
                    }
                }

                if (response) {
                    if (activeTab === 'received') {
                        setFeedbackList((prev) => [...prev, ...response.data]);
                    } else if (activeTab === 'submitted') {
                        setSubmittedFeedbackList((prev) => [...prev, ...response.data]);
                    }

                    if (response.data.length < limit) {
                        setHasMore(false); // No more data to fetch
                    }
                }
            } catch (error) {
                console.error("Failed to fetch feedback", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedback();
    }, [uid, skip, limit, isOwnerView, activeTab]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to fetch feedback details by ID when 'more' is clicked
    const handleViewFeedback = async (feedback) => {
        console.log('Viewing feedback:', feedback); // Log feedback data
    
        try {
            const feedbackId = feedback.id;
            console.log('Fetching feedback by ID:', feedbackId); // Log the feedback ID
            if (feedbackId) {
                const fetchedFeedback = await getFeedbackById(feedbackId);
                if (fetchedFeedback) {
                    console.log('Fetched feedback by ID:', fetchedFeedback); // Log the fetched feedback data
                    setSelectedFeedback(fetchedFeedback); // Set this data to display in the UI
                } else {
                    console.error('No feedback data returned');
                }
            } else {
                console.error('No valid feedback ID found');
            }
        } catch (error) {
            console.error('Error fetching feedback:', error);
        }
    };
    
    
    
    


    const handleAddFeedbackClick = () => {
        if (isMobile) {
            setShowMobileFeedbackForm(true);
        } else {
            setShowForm(true);
        }
    };

    const handleFeedbackChange = (event) => {
        setFeedbackText(event.target.value);
    };

    const handleSubmitFeedback = async () => {
        if (!uid) {
            console.error('No uid found');
            return;
        }
        try {
            const response = await uploadFeedback(uid, feedbackText);
            if (response.status === 201) {
                const feedbackData = response.data.data[0];
                const newFeedback = {
                    id: feedbackData.id,
                    profilePicUrl: feedbackData.feedbacker_profile_image, // Display feedbacker_profile_image
                    name: feedbackData.feedbacker_company_name,
                    description: feedbackData.description,
                    date: feedbackData.created_on ? new Date(feedbackData.created_on).toLocaleDateString() : "Invalid Date",
                    feedback_by: feedbackData.feedback_by,
                };

                // Add newly created feedback to a separate list
                setNewlyAddedFeedback((prevList) => [...prevList, newFeedback]);

                setShowConfirmation(true);
                setShowForm(false);
                setFeedbackText('');

                if (isMobile) {
                    setSelectedFeedback(newFeedback);
                    setShowMobileAlert(true);
                }
            } else {
                console.error("Failed to create feedback. Status code:", response.status);
            }
        } catch (error) {
            console.error("Failed to submit feedback", error);
        }
    };

    const handleDeleteClick = () => {
        setFeedbackToDelete(selectedFeedback.id); // Use the ID from selectedFeedback
        setShowDeletePopup(true); // Show the confirmation popup
    };
    
    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting feedback with ID:', feedbackToDelete); // Log the ID being deleted
    
            const response = await deleteFeedback(feedbackToDelete);
            if (response.status === 202) {
                console.log('Feedback deleted successfully');
                setFeedbackList(feedbackList.filter(f => f.id !== feedbackToDelete));
                setShowDeletePopup(false); // Hide delete confirmation popup
                setFeedbackToDelete(null); // Reset feedbackToDelete
            } else {
                console.error('Failed to delete feedback, status code:', response.status);
            }
        } catch (error) {
            console.error(`Error deleting feedback with id ${feedbackToDelete}:`, error);
        }
    };
    
    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    const handleCloseDeletePopup = () => {
        setShowDeletePopup(false);
        setFeedbackToDelete(null);
    };

    const handleLoadMore = () => {
        setSkip(prev => prev + limit);
    };

    const handleExpand = (feedbackId) => {
        setExpandedFeedbackId(feedbackId === expandedFeedbackId ? null : feedbackId);
    };

    const tabLabels = {
        received: 'Feedbacks Received',
        submitted: 'Feedback Submitted',
    };

    const handleBackClick = () => {
        navigate('/my_subscription');
    };

    const closeMobileAlert = () => {
        setShowMobileAlert(false);
    };

    const closeMobileFeedbackForm = () => {
        setShowMobileFeedbackForm(false);
        setFeedbackText('');
    };

    return (
        <div className="flex flex-col md:flex-row w-full h-full bg-[#F2F3F3] font-techna overflow-y-auto">
            <Sidebar />
            <div className="flex flex-col w-full">
                <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
                    <div className="w-full flex justify-start items-center space-x-2">
                        <h1 className="text-black font-semibold font-poppins text-xl ">Feedback</h1>
                    </div>
                    <ReportContainer />
                </div>
                <div className="border-b border-black mt-4 md:mt-[25px]"></div>
                <div className="flex flex-col md:flex-row h-full">
                    {/* Owner view section */}
                    <div className={`w-full md:w-1/3 md:border-r md:border-gray-400 overflow-auto`}>
                        {isOwnerView ? (
                            <>
                                <Tab activeTab={activeTab} setActiveTab={setActiveTab} labels={tabLabels} />
                                {activeTab === 'received' && (
                                    <div className="flex flex-col items-center p-5 md:p-9">
                                        {feedbackList.length > 0 ? (
                                            feedbackList.map((feedback, index) => (
                                                <FeedbackCard
                                                    key={index}
                                                    feedback={feedback}
                                                    onView={handleViewFeedback}
                                                    onExpand={handleExpand}
                                                    feedbackerProfileImage={feedback.feedbacker_profile_image}
                                                    isExpanded={expandedFeedbackId === feedback.id}
                                                    onDeleteClick={handleDeleteClick} // Pass delete click handler
                                                />
                                            ))
                                        ) : (
                                            <div className="text-center text-black font-gotham mt-4">No Feedbacks Received</div>
                                        )}
                                        {/* Show the new feedback instantly */}
                                        {newlyAddedFeedback.length > 0 && (
                                            <div className="text-center text-black font-gotham mt-4">
                                                {newlyAddedFeedback.map((feedback, index) => (
                                                    <FeedbackCard
                                                        key={index}
                                                        feedback={feedback}
                                                        onView={handleViewFeedback}
                                                        feedbackerProfileImage={feedback.feedbacker_profile_image} // Display feedbacker_profile_image
                                                        showDelete={false}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {activeTab === 'submitted' && (
                                    <div className="flex flex-col items-center p-5 md:p-9">
                                        {submittedFeedbackList.length > 0 ? (
                                            submittedFeedbackList.map((feedback, index) => (
                                                <FeedbackCard 
                                                    key={index} 
                                                    feedback={feedback} 
                                                    onView={handleViewFeedback} 
                                                    onExpand={handleExpand}
                                                    feedbackerProfileImage={feedback.feedbacker_profile_image} // Display feedbacker_profile_image
                                                    isExpanded={expandedFeedbackId === feedback.id}
                                                    showDelete={true} // Show delete only for submitted feedback
                                                />
                                            ))
                                        ) : (
                                            <div className="text-center text-black font-gotham mt-4">No Submitted Feedbacks</div>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            // Add feedback form if uid is present
                            <div className="flex flex-col items-center p-5 mt-5">
                                <div className="w-11/12 mx-auto">
                                    <Button
                                        text="Add your Feedback"
                                        borderColor="border-[#DB0000]"
                                        textColor="text-[#DB0000]"
                                        fontWeight="bold"
                                        onClick={handleAddFeedbackClick}
                                    />
                                </div>
                                {feedbackList.length > 0 ? (
                                    feedbackList.map((feedback, index) => (
                                        <FeedbackCard
                                            key={index}
                                            feedback={{
                                                profilePicUrl: feedback.feedbacker_profile_image,
                                                name: feedback.feedbacker_company_name,
                                                description: feedback.description,
                                                date: new Date(feedback.created_on).toLocaleDateString(),
                                                feedback_by: feedback.feedback_by,
                                            }}
                                            onView={handleViewFeedback}
                                            feedbackerProfileImage={feedback.feedbacker_profile_image} // Display feedbacker_profile_image
                                            onExpand={handleExpand}
                                            isExpanded={expandedFeedbackId === feedback.id}
                                            showDelete={false} // No delete for received feedback
                                        />
                                    ))
                                ) : (
                                    <div className="text-center text-black font-gotham mt-4">No Feedbacks Reported</div>
                                )}
                                {/* Show the new feedback instantly */}
                                {newlyAddedFeedback.length > 0 && (
                                    <div className="text-center text-black font-gotham mt-4">
                                        {newlyAddedFeedback.map((feedback, index) => (
                                            <FeedbackCard
                                                key={index}
                                                feedback={feedback}
                                                feedbackerProfileImage={feedback.feedbacker_profile_image} // Display feedbacker_profile_image
                                                onView={handleViewFeedback}
                                                showDelete={false} 
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {/* Detailed view section */}
                    <div className="w-full md:w-2/3 lg:w-1/3 p-7 h-full">
                        {selectedFeedback && (
                            <div className="border p-4 rounded-md">
                                <div className="flex items-center mb-4">
                                    <img
                                        src={selectedFeedback.profilePicUrl || '/path_to_default_image'}
                                        alt="Profile"
                                        className="w-12 h-12 rounded-full mr-4"
                                    />
                                    <div>
                                        <h2 className="font-bold text-lg">{selectedFeedback.name || 'No Name'}</h2>
                                        <p className="text-sm text-gray-500">{selectedFeedback.date || 'No Date'}</p>
                                    </div>
                                </div>

                                {/* Feedback Description */}
                                <p className="text-sm break-words mb-4">{selectedFeedback.description}</p>

                                {activeTab === 'submitted' && (
                                    <Button
                                        text="Delete"
                                        borderColor="border-[#DB0000]"
                                        textColor="text-[#FFFFFF]"
                                        backgroundColor="bg-[#DB0000]"
                                        onClick={() => handleDeleteClick(selectedFeedback)}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showConfirmation && <FeedbackSubmitted onClose={handleCloseConfirmation} />}
            {showDeletePopup && (
                <DeletePopup 
                    message="Are you sure you want to delete this feedback?" 
                    onConfirm={handleConfirmDelete} 
                    onClose={handleCloseDeletePopup} 
                />
            )}
            {isMobile && showMobileAlert && (
                <MobileAlertWindow feedback={selectedFeedback} onClose={closeMobileAlert} />
            )}
            {isMobile && showMobileFeedbackForm && (
                <MobileFeedbackForm
                    feedbackText={feedbackText}
                    handleFeedbackChange={handleFeedbackChange}
                    handleSubmitFeedback={handleSubmitFeedback}
                    onClose={closeMobileFeedbackForm}
                />
            )}
        </div>
    );
};

const FeedbackForm = ({ feedbackText, handleFeedbackChange, handleSubmitFeedback }) => (
    <div className="rounded-lg p-6 w-full mb-4 h-auto mt-7">
        <p className="text-[#232323]">"We value your feedback! Please take a moment to share your experience with [Your Company Name] and help us improve our services to better meet your needs."</p>
        <div className='mt-6'>
            <TextArea
                value={feedbackText}
                onChange={handleFeedbackChange}
                placeholder="Description"
                className="h-64"
                style={{ height: '150px' }}
            />
        </div>
        <div className='mt-32 '>
            <Button
                text="Submit"
                borderColor="border-[#DB0000]"
                textColor="text-[#FFFFFF]"
                backgroundColor="bg-[#DB0000]"
                onClick={handleSubmitFeedback}
            />
        </div>
    </div>
);

const MobileAlertWindow = ({ feedback, onClose }) => {
    if (!feedback) return null;

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
            <div className="fixed inset-0 flex items-center justify-center overlay p-4">
                <div className="bg-white rounded-lg p-4 w-full max-w-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div className="flex items-center">
                            <img
                                src={feedback.profilePicUrl}
                                alt="Profile"
                                className="rounded-full w-12 h-12 mr-4"
                            />
                            <div>
                                <div className="font-bold text-lg">{feedback.name}</div>
                            </div>
                        </div>
                        <div className="text-gray-500 text-sm">{feedback.date}</div>
                    </div>
                    <div className="text-sm mt-2">
                        {feedback.description}
                    </div>
                    <Button
                        text="Close"
                        borderColor="border-[#DB0000]"
                        textColor="text-[#FFFFFF]"
                        backgroundColor="bg-[#DB0000]"
                        onClick={onClose}
                    />
                </div>
            </div>
        </>
    );
};

const MobileFeedbackForm = ({ feedbackText, handleFeedbackChange, handleSubmitFeedback, onClose }) => (
    <>
        <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
        <div className="fixed inset-0 flex items-center justify-center overlay p-4">
            <div className="bg-white rounded-lg p-4 w-full max-w-sm">
                <h2 className="text-lg font-bold mb-4">Add Your Feedback</h2>
                <p className="text-sm mb-4">"We value your feedback! Please take a moment to share your experience with [Your Company Name] and help us improve our services to better meet your needs."</p>
                <TextArea
                    value={feedbackText}
                    onChange={handleFeedbackChange}
                    placeholder="Description"
                    className="w-full mb-4"
                    style={{ height: '150px' }}
                />
                <div className="flex justify-end space-x-2 px-4 py-2">
                    <Button
                        text="Cancel"
                        borderColor="border-[#DB0000]"
                        textColor="text-[#DB0000]"
                        onClick={onClose}
                    />
                    <Button
                        text="Submit"
                        borderColor="border-[#DB0000]"
                        textColor="text-[#FFFFFF]"
                        backgroundColor="bg-[#DB0000]"
                        onClick={handleSubmitFeedback}
                    />
                </div>
            </div>
        </div>
    </>
);

export default FeedbackView;
