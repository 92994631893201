import { GET_SUBCATEGORIES_SEARCH } from './urls';
import { api } from './api';
const get_sub_categories_api = async (category = '', skip = 0, limit = 10, lat = 0, lon = 0, search_key = '') => {
  try {
    const token = localStorage.getItem('token'); // Get the token from localStorage
    const url = `${GET_SUBCATEGORIES_SEARCH}?category=${category}&skip=${skip}&limit=${limit}${
      lat ? `&lat=${lat}` : ''
    }${lon ? `&lon=${lon}` : ''}${search_key ? `&search_key=${search_key}` : ''}`;
    
    // Log the constructed URL to the console
    console.log('Fetching URL:', url);
    
    const res = await api.get(url, {
      // If needed, you can pass headers or other configurations here
    });
    
    return res.data;
  } catch (error) {
    console.error("Error fetching companies:", error);
    throw error;
  }
};



export { get_sub_categories_api };
