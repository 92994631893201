// import React from 'react';

// const ConfirmationPopup = ({ message, onConfirm, onClose }) => {
//     return (
        
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//             <div className="bg-white rounded-lg p-6 w-80 text-center">
//                 <div className="flex justify-center mb-4">
//                     <img src="/pop-up.svg" alt="Info" className="w-12 h-12" />
//                 </div>
//                 <p className="text-black mb-4">{message}</p>
//                 <div className="flex justify-around">
//                     <button 
//                         className="bg-red-600 text-white py-2 px-4 rounded mb-4"
//                         onClick={onConfirm}
//                     >
//                         Yes
//                     </button>
//                     <button 
//                         className="bg-gray-600 text-white py-2 px-4 rounded mb-4"
//                         onClick={onClose}
//                     >
//                         No
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ConfirmationPopup;
import React, { useEffect, useState } from 'react';
import Button from './button';
import { useNavigate } from 'react-router-dom';

const ConfirmationPopup = ({ onClose, show }) => {
    const [message, setMessage] = useState('');
    const [icon, setIcon] = useState('/pop-up.svg'); // Default icon
    const [showResubmitButton, setShowResubmitButton] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        const status = localStorage.getItem('verificationStatus');
        console.log('Retrieved verification status:', status); // Debug log

        switch (status) {
            case 'VERIFICATION_COMPLETED':
                setMessage('Your profile is verified.');
                setIcon('./tick.svg'); // Change icon when verified
                setShowResubmitButton(false); // Hide resubmit button
                break;
            case 'VERIFICATION_PENDING':
                setMessage('Your profile is under verification. Please wait.');
                setIcon('./pop-up.svg'); // Optional: Change icon for pending
                setShowResubmitButton(false); // Hide resubmit button
                break;
            case 'VERIFICATION_REJECTED':
                setMessage('Your profile verification was rejected. Please update your information.');
                setIcon('./pop-up.svg'); // Optional: Change icon for rejected
                setShowResubmitButton(true); // Show resubmit button when rejected
                break;
            default:
                setMessage('Please complete your profile to get verified.');
                setIcon('/pop-up.svg'); // Default icon
                setShowResubmitButton(false); // Hide resubmit button
        }
    }, [show]); // Add 'show' as a dependency to re-run effect when popup is opened

    const handleResubmit = () => {
        console.log('Resubmit clicked');
        // Navigate to the update-form page
        navigate('/update-form');
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-80 text-center relative">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    &#x2715; {/* Unicode for close symbol (X) */}
                </button>
                <div className="flex justify-center mb-4">
                    <img src={icon} alt="Info" className="w-12 h-12" />
                </div>
                <p className="text-black mb-4">{message}</p>
                
                {showResubmitButton && ( // Conditionally render Resubmit button
                    <Button
                        text="Resubmit"
                        borderColor="border-red-600"
                        backgroundColor="bg-[#DB0000]"
                        textColor="text-white"
                        onClick={handleResubmit}
                        className="mt-4"
                    />
                )}
            </div>
        </div>
    );
};

export default ConfirmationPopup;
