// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import ReportContainer from '../../Components/report';

// const ViewManagement = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [profiles, setProfiles] = useState([
//     {
//       id: 'dummy-id',
//       logo: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2',
//       fullName: 'Zheng Yufen',
//       position: 'Founder',
//     },
//   ]);
//   const [showRemove, setShowRemove] = useState(false);

 

//   const handleEdit = (profile) => {
//     setShowRemove(true);
//     navigate('/add_management', { state: { profile } });
//   };

//   const handleAddNew = () => {
//     setShowRemove(false);
//     navigate('/add_management');
//   };

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-techna">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Management</h1>
//         </div>
//         <ReportContainer showRemove={showRemove} />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//         {profiles.map((profile) => (
//           <div key={profile.id} className="flex flex-col items-center relative">
//             <LogoUploader imageUrl={profile.logo} isEditable={false} />
//             <p className="text-center text-black mt-2">{profile.fullName}<br />{profile.position}</p>
//             <img
//               src="Assets/edit.svg"
//               alt="Edit Icon"
//               className="w-6 h-6 mt-2 cursor-pointer"
//               onClick={() => handleEdit(profile)}
//             />
//           </div>
//         ))}
//         <div className="flex flex-col items-center ml-10 cursor-pointer" onClick={handleAddNew}>
//           <LogoUploader />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ViewManagement;
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import ReportContainer from '../../Components/report';

// const ViewManagement = ({ isOwner }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [profiles, setProfiles] = useState([
//     {
//       id: 'dummy-id-1',
//       logo: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2',
//       fullName: 'Zheng Yufen',
//       position: 'Founder',
//     },
//     {
//       id: 'dummy-id-2',
//       logo: 'https://images.unsplash.com/photo-1517841905240-472988babdf9',
//       fullName: 'Liu Ming',
//       position: 'Co-Founder',
//     },
//     {
//       id: 'dummy-id-3',
//       logo: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
//       fullName: 'Akiro Sato',
//       position: 'Director',
//     },
//     {
//       id: 'dummy-id-4',
//       logo: 'https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e',
//       fullName: 'Zhang Yufen',
//       position: 'President',
//     },
//     {
//       id: 'dummy-id-5',
//       logo: 'https://images.unsplash.com/photo-1502767089025-6572583495d8',
//       fullName: 'Liu Ming',
//       position: 'General Manager',
//     },
//     {
//       id: 'dummy-id-6',
//       logo: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb',
//       fullName: 'Akiro Sato',
//       position: 'CEO',
//     },
//   ]);
//   const [showRemove, setShowRemove] = useState(false);

//   const handleEdit = (profile) => {
//     setShowRemove(true);
//     navigate('/add_management', { state: { profile } });
//   };

//   const handleAddNew = () => {
//     setShowRemove(false);
//     navigate('/add_management');
//   };

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Management</h1>
//         </div>
//         <ReportContainer showRemove={showRemove} />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex flex-wrap justify-start ml-4 md:ml-[70px] mt-4 md:mt-[40px] space-x-8 space-y-4">
//         {isOwner ? (
//           <>
//             {profiles.slice(0, 1).map((profile) => (
//               <div key={profile.id} className="flex flex-col items-center relative mt-4">
//                 <LogoUploader imageUrl={profile.logo} isEditable={false} />
//                 <p className="text-center text-black mt-2">{profile.fullName}<br />{profile.position}</p>
//                 <img
//                   src="Assets/edit.svg"
//                   alt="Edit Icon"
//                   className="w-6 h-6 mt-2 cursor-pointer"
//                   onClick={() => handleEdit(profile)}
//                 />
//               </div>
//             ))}
//             <div className="flex flex-col items-center relative cursor-pointer" onClick={handleAddNew}>
//               <LogoUploader />
//             </div>
//           </>
//         ) : (
//           profiles.map((profile) => (
//             <div key={profile.id} className="flex flex-col items-center relative mt-4">
//               <LogoUploader imageUrl={profile.logo} isEditable={false} />
//               <p className="text-center text-black mt-2">{profile.fullName}<br />{profile.position}</p>
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default ViewManagement;

// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import ReportContainer from '../../Components/report';

// const ViewManagement = ({ isOwner }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [profiles, setProfiles] = useState([]);
//   const [showRemove, setShowRemove] = useState(false);
//   const [refreshProfiles, setRefreshProfiles] = useState(0);

//   useEffect(() => {
//     if (location.state?.newProfileAdded) {
//       setRefreshProfiles(prev => prev + 1);
//     }
//   }, [location]);

//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const storedProfiles = JSON.parse(localStorage.getItem('managementProfiles')) || [];
//         setProfiles(storedProfiles);
//         console.log('Fetched profiles from local storage:', storedProfiles);
//       } catch (error) {
//         console.error('Error fetching members:', error);
//       }
//     };

//     fetchMembers();
//   }, [refreshProfiles]);

//   const handleEdit = (profile) => {
//     setShowRemove(true);
//     navigate('/add_management', { state: { profile } });
//   };

//   const handleAddNew = () => {
//     setShowRemove(false);
//     navigate('/add_management');
//   };

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna overflow-auto">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Management</h1>
//         </div>
//         <ReportContainer showRemove={showRemove} />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="grid grid-cols-2  md:grid-cols-6 gap-2  mt-7 w-4/5 sm:mx-2 ml-7">
//         {isOwner ? (
//           <>
//             {profiles.map((profile) => (
//               <div key={profile.id} className="flex flex-col items-center ">
//                 <LogoUploader imageUrl={profile.logo} isEditable={false} />
//                 <p className="text-center text-black mt-2">{profile.fullName}<br />{profile.position}</p>
//                 <img
//                   src="/Assets/edit.svg"
//                   alt="Edit Icon"
//                   className="w-6 h-6 mt-2 cursor-pointer"
//                   onClick={() => handleEdit(profile)}
//                 />
//               </div>
//             ))}
//             <div className="flex flex-col items-center relative cursor-pointer" onClick={handleAddNew}>
//               <LogoUploader />
//             </div>
//           </>
//         ) : (
//           profiles.map((profile) => (
//             <div key={profile.id} className="flex flex-col items-center relative mt-4">
//               <LogoUploader imageUrl={profile.logo} isEditable={false} />
//               <p className="text-center text-black mt-2">{profile.fullName}<br />{profile.position}</p>
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default ViewManagement;

// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import ReportContainer from '../../Components/report';
// import { getCompanyMembers } from '../../../Controller/management';

// const ViewManagement = ({ isOwner }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [profiles, setProfiles] = useState([]);
//   const [showRemove, setShowRemove] = useState(false);
//   const [refreshProfiles, setRefreshProfiles] = useState(0);

//   useEffect(() => {
//     if (location.state?.newProfileAdded) {
//       setRefreshProfiles((prev) => prev + 1);
//     }
//   }, [location]);

//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const fetchedProfiles = await getCompanyMembers();
//         setProfiles(fetchedProfiles);
//         console.log('Fetched profiles from server:', fetchedProfiles);
//       } catch (error) {
//         console.error('Error fetching members:', error);
//       }
//     };

//     fetchMembers();
//   }, [refreshProfiles, location.state?.newProfileAdded]);

//   const handleEdit = (profile) => {
//     setShowRemove(true);
//     navigate('/add_management', { state: { profile } });
//   };

//   const handleAddNew = () => {
//     setShowRemove(false);
//     navigate('/add_management');
//   };

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna overflow-auto">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Management</h1>
//         </div>
//         <ReportContainer showRemove={showRemove} />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="grid grid-cols-2 md:grid-cols-6 gap-2 mt-7 w-4/5 mx-auto lg:ml-7">
//         {isOwner ? (
//           <>
//             {profiles.map((profile) => (
//               <div key={profile.id} className="flex flex-col items-center ">
//                 <LogoUploader 
//                   logo={profile.logo || profile.image_url} // Ensure this prop name matches
//                   isEditable={false} 
//                   showEditIcon={true}
//                 />
//                 <p className="text-center text-black mt-2">{profile.name}<br />{profile.position}</p>
//                 <img
//                   src="/Assets/edit.svg"
//                   alt="Edit Icon"
//                   className="w-4 h-4 lg:w-5 lg:h-5 mt-1 cursor-pointer"
//                   onClick={() => handleEdit(profile)}
//                 />
//               </div>
//             ))}
//             <div className="flex flex-col items-center relative cursor-pointer" onClick={handleAddNew}>
//               <LogoUploader showEditIcon={false} />
//             </div>
//           </>
//         ) : (
//           profiles.map((profile) => (
//             <div key={profile.id} className="flex flex-col items-center relative mt-4">
//               <LogoUploader 
//                 logo={profile.logo || profile.image_url} // Ensure this prop name matches
//                 isEditable={false} 
//                 showEditIcon={false} 
//               />
//               <p className="text-center text-black mt-2">{profile.name}<br />{profile.position}</p>
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default ViewManagement;
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import ReportContainer from '../../Components/report';
// import { getCompanyMembers, deleteManagementMember } from '../../../Controller/management';
// import { AiOutlineDelete } from 'react-icons/ai';
// import { FaEdit, FaPlus } from 'react-icons/fa';
// import DeletePopup from '../../Components/delete_pop_up';

// const ViewManagement = ({ isOwner }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [profiles, setProfiles] = useState([]);
//   const [showRemove, setShowRemove] = useState(false);
//   const [refreshProfiles, setRefreshProfiles] = useState(0);
//   const [showDeletePopup, setShowDeletePopup] = useState(false);
//   const [profileToDelete, setProfileToDelete] = useState(null);

//   useEffect(() => {
//     if (location.state?.newProfileAdded) {
//       setRefreshProfiles((prev) => prev + 1);
//     }
//   }, [location]);

//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const response = await getCompanyMembers();
//         const fetchedProfiles = response.data;
        
//         if (Array.isArray(fetchedProfiles)) {
//           setProfiles(fetchedProfiles);
//         } else {
//           console.error('Unexpected response format:', fetchedProfiles);
//           setProfiles([]);
//         }
//       } catch (error) {
//         console.error('Error fetching members:', error);
//       }
//     };

//     fetchMembers();
//   }, [refreshProfiles, location.state?.newProfileAdded]);

//   const handleEdit = (profile) => {
//     setShowRemove(true);
//     navigate('/add_management', { state: { profile } });
//   };

//   const handleDeleteClick = (profileId) => {
//     setProfileToDelete(profileId);
//     setShowDeletePopup(true);
//   };

//   const handleDeleteConfirm = async () => {
//     try {
//       await deleteManagementMember(profileToDelete);
//       setProfiles(profiles.filter(profile => profile.id !== profileToDelete));
//       setShowDeletePopup(false);
//       setProfileToDelete(null);
//     } catch (error) {
//       console.error('Error deleting member:', error);
//     }
//   };

//   const handleDeleteCancel = () => {
//     setShowDeletePopup(false);
//     setProfileToDelete(null);
//   };

//   const handleAddNew = () => {
//     setShowRemove(false);
//     navigate('/add_management');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-techna overflow-auto">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-black font-bold text-xl">Management</h1>
//         </div>
//         <ReportContainer showRemove={showRemove} />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="grid grid-cols-2 md:grid-cols-6 gap-2 mt-7 w-4/5 mx-auto lg:ml-7">
//         {isOwner ? (
//           <>
//             {profiles.map((profile) => (
//               <div key={profile.id} className="flex flex-col items-center ">
//                 <LogoUploader 
//                   logo={profile.logo || profile.image_url}
//                   isEditable={false} 
//                   showEditIcon={true}
//                 />
//                 <p className="text-center text-black mt-2">{profile.name}<br />{profile.position}</p>
//                 <div className="flex space-x-2">
//                   <FaEdit
//                     className="w-5 h-5 lg:w-7 lg:h-7 mt-1 cursor-pointer"
//                     onClick={() => handleEdit(profile)}
//                   />
//                   <AiOutlineDelete
//                     className="w-5 h-5 lg:w-7 lg:h-7 mt-1 cursor-pointer"
//                     onClick={() => handleDeleteClick(profile.id)}
//                   />
//                 </div>
//               </div>
//             ))}
//             <div className="flex flex-col items-center justify-center cursor-pointer" onClick={handleAddNew}>
//               <div className="w-28 h-28 rounded-full bg-gray-200 flex items-center justify-center mb-20">
//                 <FaPlus className="text-gray-500 w-4 h-4" />
//               </div>
//               {/* <p className="text-center text-black mt-2">Add New</p> */}
//             </div>
//           </>
//         ) : (
//           profiles.map((profile) => (
//             <div key={profile.id} className="flex flex-col items-center relative mt-4">
//               <LogoUploader 
//                 logo={profile.logo || profile.image_url}
//                 isEditable={false} 
//                 showEditIcon={false} 
//               />
//               <p className="text-center text-black mt-2">{profile.name}<br />{profile.position}</p>
//             </div>
//           ))
//         )}
//       </div>

//       {showDeletePopup && (
//         <DeletePopup 
//           message="Are you sure you want to delete this management member?" 
//           onConfirm={handleDeleteConfirm} 
//           onClose={handleDeleteCancel} 
//         />
//       )}
//     </div>
//   );
// };

// export default ViewManagement;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import LogoUploader from '../../Components/circle_avatar';
import ReportContainer from '../../Components/report';
import { getmembersbyuid, deleteManagementMember } from '../../../Controller/management';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaEdit, FaPlus } from 'react-icons/fa';
import DeletePopup from '../../Components/delete_pop_up';

const ViewManagement = () => {
  const { uid } = useParams(); // Get the uid from the URL params if available
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [isMyProfile, setIsMyProfile] = useState(false); // Determine if viewing own profile
  const [skip, setSkip] = useState(0);
  const limit = 10;

  // Fetch members based on uid or company_uid
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const company_uid = localStorage.getItem('company_uid'); // Get company_uid from local storage
        
        // Determine if viewing own profile or another's
        if (uid) {
          setIsMyProfile(uid === company_uid); // If uid in URL matches company_uid, it's the user's own profile
        } else {
          setIsMyProfile(true); // No uid in URL, viewing own profile
        }

        const targetUid = uid || company_uid; // Use uid from URL or fallback to company_uid
        const response = await getmembersbyuid(targetUid, skip, limit); // Fetch members by the determined uid
        console.log('API Response:', response);
        
        if (response && response.data && Array.isArray(response.data)) {
          setProfiles(response.data); // Update profiles
        } else {
          console.error('Unexpected response format:', response);
          setProfiles([]);
        }
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, [skip, limit, uid]);

  const handleEdit = (profile) => {
    navigate('/add_management', { state: { profile } });
  };

  const handleDeleteClick = (profileId) => {
    setProfileToDelete(profileId);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteManagementMember(profileToDelete);
      setProfiles(profiles.filter(profile => profile.id !== profileToDelete)); // Remove deleted profile from list
      setShowDeletePopup(false);
      setProfileToDelete(null);
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
    setProfileToDelete(null);
  };

  const handleAddNew = () => {
    navigate('/add_management');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold text-xl">Management</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="grid grid-cols-2 md:grid-cols-6 gap-2 mt-7 w-4/5 mx-auto lg:ml-7">
        {profiles.map((profile) => (
          <div key={profile.id} className="flex flex-col items-center relative mt-4">
            {/* Always show the LogoUploader for all profiles */}
            <LogoUploader 
              logo={profile.logo || profile.image_url}
              isEditable={false} // Allow editing only if it's the user's own profile
              showEditIcon={isMyProfile} // Show edit icon only for own profile
            />
            <p className="text-center text-black mt-2">{profile.name}<br />{profile.position}</p>
            {isMyProfile && (
              <div className="flex space-x-2 mt-2">
                <FaEdit
                  className="w-5 h-5 lg:w-7 lg:h-7 cursor-pointer"
                  onClick={() => handleEdit(profile)}
                />
                <AiOutlineDelete
                  className="w-5 h-5 lg:w-7 lg:h-7 cursor-pointer"
                  onClick={() => handleDeleteClick(profile.id)}
                />
              </div>
            )}
          </div>
        ))}
        {isMyProfile && (
          <div className="flex flex-col items-center justify-center cursor-pointer" onClick={handleAddNew}>
            <div className="w-28 h-28 rounded-full bg-gray-200 flex items-center justify-center mb-20">
              <FaPlus className="text-gray-500 w-4 h-4" />
            </div>
          </div>
        )}
      </div>

      {showDeletePopup && (
        <DeletePopup 
          message="Are you sure you want to delete this management member?" 
          onConfirm={handleDeleteConfirm} 
          onClose={handleDeleteCancel} 
        />
      )}
    </div>
  );
};

export default ViewManagement;