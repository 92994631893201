import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordInputField from "../../Components/password-input-field";
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import { FaCheck } from "react-icons/fa";
import { MdError } from 'react-icons/md';

const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [validationMessages, setValidationMessages] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const lowercase = /[a-z]/.test(password);
    const uppercase = /[A-Z]/.test(password);
    const number = /\d/.test(password);
    const special = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setValidationMessages({
      length,
      lowercase,
      uppercase,
      number,
      special,
    });
  };

  const handleBackClick = () => {
    navigate('forgot_password_page');
  };

  return (
    <div className="flex flex-col w-screen h-screen bg-[#F2F3F3]">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-black font-bold text-xl">New Password </h1>
        </div>
      </div>

      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-1 justify-center w-4/5 ml-7">
        <div className="sm:w-2/3 md:w-1/3 lg:w-1/3 mt-10">
          <p className="font-bold text-lg">Password</p>
          <div className="mt-4">
            <PasswordInputField
              placeholder="New Password"
              name="password"
              onChange={handlePasswordChange}
              backgroundColor="#F2F3F3"
              border="1px solid #ccc"
            />
            <PasswordInputField
              placeholder="Retype New Password"
              name="password"
              onChange={handlePasswordChange}
              backgroundColor="#F2F3F3"
              border="1px solid #ccc"
              inputClassName="placeholder-red-600 font-helvetica"
            />
            {/* Password validation messages */}
            <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2 text-sm text-[#545454] font-poppins font-normal">
              <div className="flex items-center space-x-2">
                {validationMessages.length ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>At least 8 characters</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.lowercase ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Lowercase letter</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.uppercase ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Uppercase letter</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.number ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Number</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.special ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Special character</span>
              </div>
            </div>

            <div className="mt-10">
              <Button
                text="Save"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                navigateTo=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
