// companyManagerController.js
import { post_manager } from './company_details';
import { showToast } from '../View/Components/toast';
import UploadDocuments from './firebase/firebase_documents';

export const uploadAuthorizationFile = async (file, setAuthorizationFile, setLoading) => {
  if (file.size > 25 * 1024 * 1024) {
    showToast('File exceeds the 25MB size limit.', 'error');
    return;
  }

  try {
    setLoading(true);
    const downloadURL = await UploadDocuments(file);
    setAuthorizationFile(downloadURL);
    showToast('File uploaded successfully!', 'success');
  } catch (error) {
    console.error('Error uploading file:', error);
    showToast(error.response?.data?.message || 'Error uploading file.', 'error');
  } finally {
    setLoading(false);
  }
};

export const saveManagerDetails = async (manager, authorizationFile, navigate, setLoading) => {
  if (!manager || !authorizationFile) {
    showToast('Please provide Manager name and Authorization file.', 'error');
    return;
  }

  setLoading(true);

  try {
    const response = await post_manager(manager, authorizationFile);
    const message = response.data.message;

    if (response.status === 202) {
      navigate('/company_docs');
    } else if (response.status === 404) {
      // showToast('Error saving company details', 'error');
    }
  } catch (error) {
    console.error('Error saving company details:', error);
    // showToast('Error saving company details', 'error');
  } finally {
    setLoading(false);
  }
};
