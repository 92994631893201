import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import InputBox from '../../Components/input_box';
import TextArea from "../../Components/text_area"
import VideoUpload from '../../Components/video_upload'; // Import the VideoUpload component
import { post_video } from '../../../Controller/upload_videos';

const UploadVideo = () => {
  const [title, setTitle] = useState(''); // State for title
  const [description, setDescription] = useState(''); // State for description
  const [videos, setVideos] = useState([]); // State for video uploads (array of strings)
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value); // Bind title input correctly
  };

  const handleNextClick = async () => {
    if (videos.length > 0 && videos[0]) {
      const video_url = videos[0]; // The first video URL
  
      try {
        // Call the API to upload the video
        const res = await post_video(title, description, video_url);
        console.log(res)
        // Check if the response is 200 and navigate
        if (res.status === 201) {
          navigate('/uploaded_video');
        } else {
          alert('Failed to upload video. Please try again.');
        }
      } catch (error) {
        alert('An error occurred while uploading the video. Please try again.');
      }
    } else {
      alert('Please upload a video before submitting.');
    }
  };

  return (
    <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-poppins">
      <Sidebar />
      <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
      <div className="flex items-center space-x-2">
        
        <h1 className="text-black font-semibold text-xl">Upload Video</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="w-4/5 ml-7">
        <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-techna" style={{ height: '600px', width: '450px' }}>
          <div className="mt-5">
            <VideoUpload
              files={videos}
              setFiles={setVideos} // Ensure setVideos is passed correctly here
              svgImagePath="Assets/grey_plus.svg"
              bgColor="bg-[#E6E6E6]"
              borderRadius="8px"
              width="80px"
              height="120px"
            />
          </div>
          <div className="mt-8">
            <p className="font-bold">Title</p>
            <InputBox
              placeholder="Title"
              borderRadius="2px"
              value={title} // Bind title input to state
              onChange={handleTitleChange}
            />
          </div>
          <div className="mt-9">
            <p className="font-bold">Description</p>
            <TextArea
              placeholder="Write something about your company"
              value={description}
              onChange={handleDescriptionChange}
              maxLength={500}
            />
          </div>
          <div className="mt-20">
            <Button
              text="Next"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleNextClick} // Handle button click
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;
