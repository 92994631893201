import React, { useState, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; 
import CompanyCard from '../../Components/comapny_card';
import CompanyHeader from '../../Components/company_header';
import Sidebar from '../../Components/main_navbar';
import { get_sub_categories_api } from '../../../Controller/sub_categories_home';

// Libraries required for Google Maps API
const libraries = ['places']; // Include 'places' library for autocomplete

const CategoryPage = ({ category = "Category", hideSidebar = false }) => {
  const [search, setSearch] = useState(''); // Search key (search_key)
  const [locationSearch, setLocationSearch] = useState(''); // Location search input
  const [lat, setLat] = useState(''); // Latitude
  const [lon, setLon] = useState(''); // Longitude
  const [companies, setCompanies] = useState([]); 
  const [loading, setLoading] = useState(false); 
  const [currentPage, setCurrentPage] = useState(0); // Pagination
  const navigate = useNavigate(); 

  // Load Google Maps JavaScript API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDyAclZ_3BhDcT1i3wxTiye4TpPOLycoKA', // API key in your .env file
    libraries, // Load the places library
  });

  // Handle input change for location search
  const handleLocationSelect = (latitude, longitude) => {
    console.log("Location selected, updating lat and lon:", latitude, longitude);
    setLat(latitude);
    setLon(longitude);
  };
  

  // Fetch companies based on lat, lon
  const fetchCompanies = async (page) => {
    setLoading(true);
    const lowerCaseCategory = category.toLowerCase();
    const skip = page * 5;
    try {
      const response = await get_sub_categories_api(lowerCaseCategory, skip, 5, lat, lon, search);
      setCompanies(prevCompanies => [...prevCompanies, ...response.data]);
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (lat && lon) {  // Only fetch if both latitude and longitude are not null
      console.log(`Fetching companies with lat: ${lat}, lon: ${lon}`);
      fetchCompanies(0);
    }
  }, [lat, lon, search]); // Make sure this effect listens for changes in lat and lon
  
  
  
  // Trigger company fetching when category, lat/lon, or search key changes
  useEffect(() => {
    setCompanies([]);
    setCurrentPage(0);
    fetchCompanies(0); // Fetch companies on initial load or changes
  }, [category, lat, lon, search]);

  // Check if Google Maps API has loaded
  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div className="h-screen w-full flex flex-col bg-[rgba(242,242,242,1)] ">
       <h1 className="text-2xl font-semibold leading-tight" style={{ color: '#171717', marginTop: '2rem', marginLeft: '2rem' }}>
        {category}
      </h1>
      {/* <CompanyHeader setSearch={setSearch} toggleMobileMenu={() => {}} hideLogo hideFilterIcon onLocationSelect={handleLocationSelect} /> */}

      {/* Location input and suggestions */}
     
      {/* <div className="flex flex-col md:flex-row w-full mt-4 md:mt-0 overflow-y-auto">
        <div className="flex flex-col w-full p-4 space-y-6 mr-16 overflow-y-auto">
          <div className="space-y-6 mt-4 px-4 md:px-8 flex-1 overflow-auto">
            <div className="flex flex-col items-center md:items-center md:w-1/2 md:mx-auto overflow-y-auto">
              {companies.length === 0 && !loading ? (
                <p>No companies found</p>
              ) : (
                companies.map(company => (
                  <div
                    key={company.id}
                    className="w-full md:w-full p-2"
                    onClick={() => navigate(`/profile2/${company.id}`)}
                  >
                    <CompanyCard company={company} />
                  </div>
                ))
              )}
              {loading && (
                <div className="flex justify-center my-4">
                  <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!hideSidebar && (
        <div className="md:block w-64 p-4"> */} 
    {/* chnaged part for hosting */}
         <div className="h-screen w-full lg:w-4/5 flex items-center justify-center bg-[rgba(242,242,242,1)]">
      <h1 className="text-xl font-semibold text-black" style={{ fontFamily: 'Poppins' }}>
        No companies found
      </h1>
    </div>
          <Sidebar />
        {/* </div>
      )} */}
    </div>
  );
};

export default CategoryPage;


