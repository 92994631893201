import React from 'react';
// import { Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatMessages from './View/Components/chatmsg_ex.jsx';
import MainPageSearch from './View/Pages/Home_module/main_search_page.jsx';
import TradeRolePage from './View/Pages/Onboarding_module/trade_role_page.ui.jsx';
import SpashScreen from './View/Pages/Onboarding_module/spash_screen_ui';
import MainContentLogin from './View/Pages/Onboarding_module/login_page.jsx';
import EmailVerificationPage from './View/Pages/Onboarding_module/email_otp_validation_page.jsx';
import MainEmailVerified from './View/Pages/Onboarding_module/confirm_email_verified.jsx';
import MainAreaOfOperation from './View/Pages/Onboarding_module/select_area_of_operation.jsx';
import './App.css'; 
import HexGrid from './View/Components/trade.jsx';
import AddReviews from "./View/Pages/Reviews_module/add_review_page.jsx";
import Reviews from "./View/Pages/Reviews_module/reviews_page.jsx";
import CompanyPhotosPage from './View/Pages/compnany_photos_module/company_photos_page.jsx';
import AccountPage from "./View/Pages/Setting_module/account_details";
import Subscription from "./View/Pages/Setting_module/my_subscription";
import SubscriptonPlans from "./View/Pages/Setting_module/subscription_plans";
import SubscriptionDetails from "./View/Pages/Setting_module/subscription_details";
import Paymentmethod from "./View/Pages/Setting_module/payment_method";
import PaymentSuccessful from "./View/Pages/Setting_module/payment_successful";
import Security from "./View/Pages/Setting_module/security_page";
import PrivacyPolicy from "./View/Pages/Static_page_module/privacy_policy";
import Rate from "./View/Components/star_rating.jsx";
import ForgotPassword from "./View/Pages/Setting_module/forgot_password.jsx";
import NewPassword from "./View/Pages/Setting_module/new_password";
import ReviewAdd from './View/Pages/Reviews_module/add_review_page.jsx';
import Adding from './View/Pages/Reviews_module/reviews_page.jsx';
import CompanyDetails from './View/Pages/Company_details_module/details_page.jsx';
import CompanyLogo from './View/Pages/Company_details_module/company_logo.jsx';
import CompanyAccount_Managed from './View/Pages/Company_details_module/account_manager.jsx';
import CompanyAddress from './View/Pages/Company_details_module/address.jsx';
import CompanyDocs from './View/Pages/Company_details_module/company_docs.jsx';
import CompanyDetailsPhone from './View/Pages/Company_details_module/phone_details.jsx';
import CompanyPhoneVerified from './View/Pages/Company_details_module/phone_verification.jsx';
import Linkedin from './View/Pages/Company_details_module/linkedin_page.jsx';
import LinkedinVerify from './View/Pages/Company_details_module/linkedin_verification.jsx';
import AddCatalog from './View/Pages/Company_details_module/catalog.jsx';
import ProductService from './View/Pages/Company_details_module/product_service.jsx';
import SampleFormat from './View/Pages/Company_details_module/view_sample_format.jsx';
import ViewManagement from './View/Pages/management_module/view_management.jsx';
import ProductList from './View/Pages/Company_details_module/product_service_list.jsx';
import Complaints from './View/Pages/Complaints/complaints.jsx';
import FeedbackView from './View/Pages/feedback.jsx';
import ImageGallery from './View/Pages/Photos_module/view_photos.jsx';
import ViewPhotos from './View/Pages/Photos_module/view_photos.jsx';
import ShowcaseDetail from './View/Pages/Photos_module/detailed_view.jsx';
import DetailedView from './View/Pages/Photos_module/detailed_view.jsx';
import PhotoSlider from './View/Pages/Photos_module/company_detailed.jsx';
import CompanyProduct from './View/Pages/Photos_module/add_product.jsx';
import SwiperGallery from './View/Pages/Photos_module/company_detailed.jsx';
import SelectDropDownRegion from './View/Pages/Onboarding_module/select_drop_down_region.jsx';
import CategorySelection from './View/Pages/Onboarding_module/category_selection.jsx';
import VideoPlayer from './View/Pages/Discover-explore/discover_video_player.jsx';
import VideoCard from './View/Components/discover_screen.jsx';
import DiscoverExplore from './View/Pages/Discover-explore/discover_explore_page.jsx';
import ProfilePage from './View/Pages/Profile_module/profile_visitors_view.jsx';
import Selection from './View/Pages/Onboarding_module/select_drop_down_region.jsx';
import SignUp from './View/Pages/Onboarding_module/sign_up_page.jsx';
import MainHome from './View/Pages/Onboarding_module/home_page.jsx';
import CategoryPage from './View/Pages/Home_module/category_page.jsx';
import Feedback from './View/Pages/feedback.jsx';
import TradeRolePageSelection from './View/Pages/Onboarding_module/trade_role_selection.jsx';
import UploadVideo from './View/Pages/Video_module/upload_video.jsx';
import UploadedVideo from './View/Pages/Video_module/uploaded_video.jsx';
import AddManagement from './View/Pages/management_module/add_management.jsx';
import CompanyPhotoManagement from './View/Pages/compnany_photos_module/company_photos_management.jsx';
import PostRequest from './View/Pages/Discover-explore/post_request_page.jsx';
import ConfirmPassword from './View/Pages/Onboarding_module/confirm_password_page.jsx';
import VerificationOfProfile from './View/Pages/profile_verification_module/verified_profile_page.jsx';
import VerifiedSubscriptionPlans from './View/Pages/profile_verification_module/verified_profile_subscription.jsx';
import VerificationWithOTP from './View/Pages/profile_verification_module/verication_with_otp.jsx';
import VerificationSuccess from './View/Pages/profile_verification_module/verification_successful.jsx';
import ProductCatalog from './View/Pages/Company_details_module/view_product_catalog.jsx';
import Verified from './View/Pages/Company_details_module/verified.jsx';
import EditProfile from './View/Pages/edit_profile_page.jsx';
import ReportPage from './View/Components/report_page.jsx';
import ProtectedRoute from './View/Components/protected_route.jsx';
import { Toast } from './View/Components/toast.jsx';
import ForgotPasswordPage from './View/Pages/Onboarding_module/forgot_password_page.jsx';
import ResetPassword from './View/Pages/Onboarding_module/reset_password_page.jsx';
import ResetOtpPassword from './View/Pages/Onboarding_module/reset_otp_page.jsx';
import Inbox from './View/Pages/Chat_module/inbox_page.jsx';
import DirectMessage from './View/Pages/Chat_module/direct_message.jsx';
import SubscriptionPayment from './View/Pages/Setting_module/subscription_payment_method.jsx';
import VerificationPage from './View/Pages/verification_page.jsx';
import ChatWindow from './View/Components/chat_window.jsx';
import ImportantMessages from './View/Pages/Chat_module/important_message.jsx';
import UpdateForm from './View/Pages/update_documents_page.jsx';
import ProfilePage2 from './View/Pages/Profile_module/other_profile.jsx';
import ComplaintSubmission from './View/Pages/Complaints/add_complaints.jsx';
import HelpSupport from './View/Pages/Static_page_module/help_support.jsx';
import TermsAndConditions from './View/Pages/Static_page_module/terms_policies.jsx';
import EditForm from './View/Pages/edit_documents.jsx';
import ProfileVisitorsView from './View/Pages/Profile_module/profile_visitors_view.jsx';
import FollowUp from './View/Pages/Chat_module/follow_up.jsx';
import CompanyDocsView from './View/Pages/Company_details_module/company_docs_view.jsx';
import AboutUs from './View/Pages/Static_page_module/about_us.jsx';
import AddComplaints from './View/Pages/Complaints/add_complaints.jsx';
import OtherProfileVisitorsView from './View/Pages/Profile_module/other_profile.jsx';
import PostList from './View/Pages/Post_Request/post_request_list.jsx';
import Video from './View/Pages/Discover-explore/explore-all-video.jsx';
const stripePromise = loadStripe('pk_test_51Oqj9IJI3AyIcueOZlXsVob324A19wJMInhlkcEnYclAQfiHoLHDcpgQvV3cr4Be7KAHSjcHCKvXnM1SD05vu5Ks00xArfrvJ3');

const categories = [
  'Logistics', 
  'Hand Craft', 
  'Financial Institutions', 
  'Start-Up', 
  'Precious-Stones-Metals', 
  'Seller', 
  'Buyer', 
  'Investor', 
  'Projects', 
  'MarketPlace',
  'Legal'
];
function App() {
  return (
    <>
      <Toast />
      <Elements stripe={stripePromise}>
        
        <Routes>
          <Route path="/home" element={<MainHome />} />
          {/* <Route path= "/login" element={<MainContentLogin />} /> */}
          <Route path="/email-verification" element={<EmailVerificationPage />} />
          <Route path="/area-of-operation" element={<MainAreaOfOperation />} />
      
    <Route path="/category-listings" element={< CategorySelection/>} />
          <Route path="/selection" element={< Selection/>} />
          <Route path="/email-verified" element={<MainEmailVerified />} />
          <Route path="/" element={<MainContentLogin/>} />
          <Route path="/trade" element={<HexGrid />} />
          <Route path="/trade-selection" element={<TradeRolePageSelection />} />
          <Route path="/location-selection" element={<SelectDropDownRegion />} />
          <Route path="/video" element={<VideoCard />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password-page" element={<ForgotPasswordPage />} />
          <Route path="/reset-password-page" element={<ResetPassword />} />
          <Route path="/reset-password-otp-page" element={<ResetOtpPassword />} />
          <Route path="/search-page" element={<MainPageSearch />} />
          <Route path="/discover-page" element={<DiscoverExplore />} />
          <Route path="/video_player" element={<VideoPlayer />} />
          <Route path="/explore-all-video" element={<Video />} />

          {categories.map(category => (
        <Route 
          key={category} 
          path={`/${category.replace(/\s+/g, '_').replace(/-/g, '_').toLowerCase()}`} 
          element={<CategoryPage category={category} />} 
        />
      ))}
          <Route path="/inbox_page" element={<Inbox />} />
          <Route path="/direct_message" element={<DirectMessage />} />
          <Route path="/direct_message/chat/:chatId" element={<DirectMessage />} />
          <Route path="/important_message" element={<ImportantMessages />} />
          <Route path="/account_details" element={<AccountPage />} />
          <Route path="/my_subscription" element={<Subscription />} />
          <Route path="/subscription_plans" element={<SubscriptonPlans />} />
          <Route path="/subscription_details" element={<SubscriptionDetails />} />
          <Route path="/payment_method" element={<Paymentmethod />} />
          <Route path="/payment_successful" element={<PaymentSuccessful />} />
          <Route path="/security_page" element={<Security />} />
          <Route path="/forgot_password" element={< ForgotPassword />} />
          <Route path="/new_password" element={< NewPassword />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/add_review_page" element={<ReviewAdd />} />
          <Route path="/reviews_page" element={<Adding />} />
          <Route path="/details_page" element={<CompanyDetails />} />
          <Route path="/company_logo" element={<CompanyLogo />} />
          <Route path="/account_manager" element={<CompanyAccount_Managed />} />
          <Route path="/address" element={<CompanyAddress />} />
          <Route path="/company_docs" element={<CompanyDocs />} />
          <Route path="/company_docs_view" element={<CompanyDocsView />} />

          <Route path="/phone_details" element={<CompanyDetailsPhone />} />
          <Route path="/phone_verification" element={<CompanyPhoneVerified />} />
          {/* <Route path="/linkedin_page" element={<Linkedin />} />
          <Route path="/linkedin_verification" element={<LinkedinVerify />} /> */}
          <Route path="/add_management" element={<AddManagement />} />
          <Route path="/catalog" element={<AddCatalog />} />
          <Route path="/product_service" element={<ProductService />} />
          <Route path="/view_sample_format" element={<SampleFormat />} />
          {/* <Route path="/view-management-owner" element={<ViewManagement isOwner={true} />} />
          <Route path="/view-management-user" element={<ViewManagement isOwner={false} />} /> */}
          <Route path="/product_service_list" element={<ProductList />} />
          <Route path="/complaints-received" element={<Complaints owner={true} />} />
          <Route path="/complaints-submitted" element={<Complaints owner={false} />} />
          <Route path="/add_complaints" element={<ComplaintSubmission/>} />
          <Route path="/view_photos" element={<ViewPhotos />} />
          <Route path="/detailed_view/:id" element={<DetailedView />} />
          <Route path="/company_detailed" element={<SwiperGallery />} />
          <Route path="/add_product" element={<CompanyProduct />} />
          <Route path="/verification_page" element={<VerificationPage />} />
          <Route path="/view-management/:uid?" element={<ViewManagement />} />

          {/* <Route
            path="/feedback/owner"
            element={<FeedbackView owner={true} />}
          />
          <Route
            path="/feedback/user"
            element={<FeedbackView owner={false} />}
          /> */}
        <Route path="/feedback/owner/:uid?" element={<FeedbackView />} />

          <Route path="/upload_video" element={<UploadVideo />} />
          <Route path="/uploaded_video" element={<UploadedVideo />} />
          <Route path="/company_photos" element={<CompanyPhotosPage />} />
          <Route path="/photo-signboard" element={
  <CompanyPhotoManagement
    headingofcontent="Photo of the Company Signboard from Outside"
    paragraph="Could you please provide a photo of the company board as seen from the outside? It would be greatly appreciated."
    nextPath="/photo-premises"
    verificationType="outside"
  />
} />
<Route path="/photo-premises" element={
  <CompanyPhotoManagement
    headingofcontent="Interior Photo of the Company Premises"
    paragraph="Would it be possible to obtain a photo of the inside of the company premises? Your assistance with this is compulsory. Thank you."
    nextPath="/photo-crew"
    verificationType="inside"
  />
} />
<Route path="/photo-crew" element={
  <CompanyPhotoManagement
    headingofcontent="Group Photo of the Company Crew"
    paragraph="I kindly request a photo of the company team. This is compulsory for our records. Thank you for your cooperation."
    nextPath="/photo-ceo"
    verificationType="crew"
  />
} />
<Route path="/photo-ceo" element={
  <CompanyPhotoManagement
    headingofcontent="Photo of the CEO in the Office"
    paragraph="Could you please share a photo of the CEO in their office? This is a compulsory requirement. Thank you for your understanding."
    nextPath="/view_photos"
    verificationType="ceo"
  />
} />

          <Route path="/post_request" element={<PostRequest />} />
          <Route path="/profile1" element={<ProfileVisitorsView editable={true} />} />

          <Route path="/profile2/:company_id" element={<OtherProfileVisitorsView />} />

          <Route path="/confirm-password" element={<ConfirmPassword />} />
          <Route path="/profile-verification" element={<VerificationOfProfile />} />
          <Route path="/verified-profile" element={<VerificationOfProfile />} />
          <Route path="/verified-subscription" element={<VerifiedSubscriptionPlans />} />
          <Route path="/otp-verification" element={<VerificationWithOTP />} />
          <Route path="/verification-success" element={<VerificationSuccess />} />
          <Route path="/view_product_catalog" element={<ProductCatalog />} />
          <Route path="/report_page" element={<ReportPage />} />
          <Route path="/view_management" element={<ViewManagement />} />
          <Route path="/verified" element={<Verified />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/subscription-payment" element={<SubscriptionPayment />} />
          <Route path="/chat_ex" element={<ChatMessages />} />
          <Route path="/update-form" element={<UpdateForm/>} />
          <Route path="/other_profile" element={<ProfilePage2/>} />
          <Route path="/follow_up" element={<FollowUp/>} />
          <Route path="/help_support" element={<HelpSupport />} />
          <Route path="/terms_policies" element={<TermsAndConditions />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/edit_form" element={<EditForm />} />
          <Route path="/add-complaints" element={<AddComplaints />} />
          <Route path="/posts/:company_uid?" element={<PostList />} />

        </Routes>
      </Elements>
    </>
  );
}

export default App;
