import React, { useEffect, useState, useRef } from 'react';
import VideoCard from './video_display_card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { get_all_User_video } from '../../Controller/upload_videos';
import { FaSpinner } from 'react-icons/fa'; // Importing spinner from react-icons

const DEFAULT_THUMBNAIL = './explore2.svg'; // Path to your default image

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 w-10 h-10 ml-5"
    style={{ top: '50%', transform: 'translateY(80%)', left: '-2.8rem' }}
  >
    <img src={'/left_arrow.svg'} alt="Previous" className="w-6 h-6 cursor-pointer" />
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 w-10 h-10 mr-2"
    style={{ top: '50%', transform: 'translateY(80%)', right: '-2.8rem' }}
  >
    <img src={'/right_arrow.svg'} alt="Next" className="w-6 h-6 cursor-pointer" />
  </button>
);

const Discover = () => {
  const [videos, setVideos] = useState([]);
  const carouselRef = useRef();
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false); // State to handle spinner
  const limit = 6;
  const navigate = useNavigate(); // Initialize navigate

  const fetchVideos = async () => {
    try {
      const response = await get_all_User_video(skip, limit);
      console.log('API Response:', response.data);

      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          const videoData = response.data.data.map(video => ({
            ...video,
            thumbnail: video.thumbnail || DEFAULT_THUMBNAIL // Assign default thumbnail if missing
          }));
          setVideos(videoData);
        } else {
          console.error('Error: Expected an array but got something else:', response.data.data);
        }
      } else {
        console.error('Error: API returned a non-200 status code', response.status);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [skip]);

  // Handle click to navigate to VideoPlayer
  const handleThumbnailClick = (videoId) => {
    setLoading(true); // Show spinner when video is clicked
    navigate('/video_player', { state: { videoId } });
  };

  return (
    <div className="relative lg:w-full h-full mt-7 mx-6 sm:w-full h-full">
      {loading && ( // Conditionally render the spinner
        <div className="absolute inset-0 flex justify-center items-center z-50 bg-opacity-50 bg-black">
          <FaSpinner className="animate-spin text-white" size="3em" />
        </div>
      )}
      
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        infinite={true}
        arrows={false}
      >
        {videos.map((video, index) => (
          <div key={index} onClick={() => handleThumbnailClick(video.id)}>
            <VideoCard videoUrl={video.video_url} thumbnail={video.thumbnail} />
          </div>
        ))}
      </Carousel>

      <CustomLeftArrow onClick={() => carouselRef.current.previous()} />
      <CustomRightArrow onClick={() => carouselRef.current.next()} />
    </div>
  );
};

export default Discover;
