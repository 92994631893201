import React from 'react';
import { useNavigate } from 'react-router-dom';

const HelpSupport = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8]">
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
         
          <h1 className="text-[#171717] font-normal sm:text-lg lg:text-xl font-techna">Help & Support</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full overflow-y-auto">
        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Welcome to eSwaF Support!</h2>
          <p className="text-[#171717] mt-2">
            We're here to help you. Find answers to common issues and get assistance as needed.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Getting Started</h2>
          <h3 className="text-black font-semibold text-md mt-2">Download and Installation</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>iOS: Open the App Store, search for "eSwaF," and tap "Download."</li>
            <li>Android: Open Google Play Store, search for "eSwaF," and tap "Install."</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Account Setup</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Create Account: Open the app, tap "Sign Up," and follow the instructions.</li>
            <li>Verify Email: Check your email and verify your account using the provided link.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Troubleshooting</h2>
          <h3 className="text-black font-semibold text-md mt-2">App Crashes or Not Opening</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Restart Device: Restart your device to clear temporary issues.</li>
            <li>Update App: Check for updates in the App Store or Google Play Store.</li>
            <li>Reinstall: Uninstall and reinstall the app if problems persist.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Login Issues</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Forgot Password: Tap "Forgot Password" and follow the reset instructions.</li>
            <li>Locked Account: Contact support for help with a locked account.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Payment Issues</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Transaction Problems: Verify payment details and try again. Contact support for unresolved issues.</li>
            <li>Refunds: Review our <a href="#refund-policy" className="text-blue-500">Refund Policy</a> and request a refund if eligible.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Features and Usage</h2>
          <h3 className="text-black font-semibold text-md mt-2">Navigating the App</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Overview: Use the home screen to access main features. Refer to in-app guides for details.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Settings</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Account Settings: Update personal information and preferences under "Settings."</li>
            <li>App Preferences: Customize themes, language, and other settings.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Contact Support</h2>
          <h3 className="text-black font-semibold text-md mt-2">Reach Us</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Email: <a href="mailto:support@emartapp.com" className="text-blue-500">support@emartapp.com</a> or <a href="mailto:email@eswaf.com" className="text-blue-500">email@eswaf.com</a></li>
            <li>Live Chat: Available in the app <span className="text-[#171717]">10am to 5am PDT</span></li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Response Times</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Email: Typically, within 24-48 hours.</li>
            <li>Live Chat: <span className="text-[#171717]">Live Chat Hours</span>.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Privacy and Security</h2>
          <h3 className="text-black font-semibold text-md mt-2">Data Protection</h3>
          <p className="text-[#171717] mt-2">
            Privacy: We safeguard your data. See our <a href="#privacy-policy" className="text-blue-500">Privacy Policy</a> for details.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Security Tips</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Strong Passwords: Use unique, strong passwords.</li>
            <li>Two-Factor Authentication: Enable this for added security.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Legal Information</h2>
          <h3 className="text-black font-semibold text-md mt-2">Terms of Service</h3>
          <p className="text-[#171717] mt-2">
            Agreement: By using the app, you agree to our <a href="#terms-of-service" className="text-blue-500">Terms of Service</a>.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Privacy Policy</h3>
          <p className="text-[#171717] mt-2">
            Details: Read our <a href="#privacy-policy" className="text-blue-500">Privacy Policy</a> to understand how we handle your data.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Liability</h3>
          <p className="text-[#171717] mt-2">
            Disclaimer: We are not liable for indirect or consequential damages. The app is provided "as is."
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Dispute Resolution</h3>
          <p className="text-[#171717] mt-2">
            Arbitration: Disputes are resolved through arbitration as per our <a href="#arbitration-policy" className="text-blue-500">Arbitration Policy</a> in terms and conditions.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">FAQ</h2>
          <h3 className="text-black font-semibold text-md mt-2">General</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>What is eSwaF?: "Everything. Electronically." "eSwaF by eMartApp is a business-focused social media platform powered by advanced research and analytics, designed to enhance user experiences and streamline business operations within complex online ecosystems."</li>
            <li>Updating the App: Updates are available in the App Store or Google Play Store.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Technical</h3>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>App Not Responding: Refer to troubleshooting steps in section 2.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Feedback and Suggestions</h2>
          <p className="text-[#171717] mt-2">
            Submit Feedback: Share your feedback at <a href="mailto:support@emartapp.com" className="text-blue-500">support@emartapp.com</a> or <a href="mailto:email@eswaf.com" className="text-blue-500">email@eswaf.com</a>.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px]">
          <h2 className="text-black font-bold text-lg">Additional Support</h2>
          <p className="text-[#171717] mt-2">
            For more information or help, visit our website or contact support. We value your feedback and strive to improve your experience with eSwaF.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpSupport;
