// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { db } from '../../../firebase_service';
// import Sidebar from '../../Components/main_navbar';
// import ChatWindow from '../../Components/chatmsg_ex'; // Import the ChatWindow component

// const ImportantMessages = () => {
//   const [importantMessages, setImportantMessages] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [selectedMessage, setSelectedMessage] = useState(null); // State for selected message
//   const navigate = useNavigate();

//   useEffect(() => {
//     const userId = localStorage.getItem('userId');
//     if (userId) {
//       setCurrentUser({ id: userId });
//     } else {
//       navigate('/login');
//     }
//   }, [navigate]);

//   useEffect(() => {
//     if (!currentUser) return;

//     const q = query(
//       collection(db, 'chats'),
//       where('participants', 'array-contains', currentUser.id),
//       where(`isImportantFor${currentUser.id === 'senderUid' ? 'Sender' : 'Receiver'}`, '==', true)
//     );

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setImportantMessages(messages);
//     });

//     return () => unsubscribe();
//   }, [currentUser]);

//   const handleSelectMessage = (messageId) => {
//     const selectedMessage = importantMessages.find(msg => msg.id === messageId);
//     setSelectedMessage(selectedMessage); // Set the selected message
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
//     <Sidebar />
//     <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//       <div className="w-full flex justify-start items-center space-x-2">
//         {/* <img
//           src="/Assets/arrow_back.svg"
//           alt="Back"
//           className="cursor-pointer mr-2 h-6 w-6"
//           onClick={() => navigate(-1)}
//         /> */}
//         <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Important Message</h1>
//       </div>
//     </div>
//     <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex w-full h-full">
//         <div className="w-full lg:w-1/4 h-full bg-[#F9F9F9] border-r">
//           {importantMessages.map((message) => (
//             <div
//               key={message.id}
//               className="p-4 hover:bg-gray-100 cursor-pointer rounded-lg shadow-sm bg-[#E7E7E7]"
//               onClick={() => handleSelectMessage(message.id)}
//             >
//               <div className="flex items-center p-4">
//               <img 
//   src={currentUser.id === message.senderUid 
//     ? message.receiverImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60' // Fallback URL
//     : message.senderImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'} 
//   alt={currentUser.id === message.senderUid ? message.receiverName : message.senderName} 
//   className="h-12 w-12 rounded-full mr-4" 
// />

//                 <div className="flex-grow">
//                   <h2 className="font-semibold">
//                     {currentUser.id === message.senderUid ? message.receiverName : message.senderName}
//                   </h2>
//                   <p className="text-sm text-gray-600">{message.lastMessage}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Chat window on the right */}
//         <div className= "w-full hidden lg:flex lg:w-2/4 h-full">
//           {selectedMessage && (
//             <ChatWindow
//               message={selectedMessage}
//               selectedUser={currentUser.id === selectedMessage.senderUid ? { userName: selectedMessage.receiverName, userImage: selectedMessage.receiverImage } : { userName: selectedMessage.senderName, userImage: selectedMessage.senderImage }}
//               currentUser={currentUser}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ImportantMessages;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase_service';
import Sidebar from '../../Components/main_navbar';
import ChatWindow from '../../Components/chatmsg_ex'; // Import the ChatWindow component
import ReportContainer from '../../Components/report';

const ImportantMessages = () => {
  const [importantMessages, setImportantMessages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null); // State for selected message
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      setCurrentUser({ id: userId });
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', currentUser.id),
      where(`isImportantFor${currentUser.id === 'senderUid' ? 'Sender' : 'Receiver'}`, '==', true)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setImportantMessages(messages);
    });

    return () => unsubscribe();
  }, [currentUser]);

  const handleSelectMessage = (messageId) => {
    const selectedMessage = importantMessages.find(msg => msg.id === messageId);
    setSelectedMessage(selectedMessage); // Set the selected message
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Important Messages</h1>
        </div>
        <div className='mr-7'>
          <ReportContainer />
          </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex w-full h-full">
        <div className="w-full lg:w-1/4 h-full bg-[#F9F9F9] border-r">
          {importantMessages.length === 0 ? (
            <div className="flex items-center justify-center h-full text-gray-500">
              No important chats available
            </div>
          ) : (
            importantMessages.map((message) => (
              <div
                key={message.id}
                className="p-4 hover:bg-gray-100 cursor-pointer rounded-lg shadow-sm bg-[#E7E7E7]"
                onClick={() => handleSelectMessage(message.id)}
              >
                <div className="flex items-center p-4">
                  <img 
                    src={currentUser.id === message.senderUid 
                      ? message.receiverImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60' // Fallback URL
                      : message.senderImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'} 
                    alt={currentUser.id === message.senderUid ? message.receiverName : message.senderName} 
                    className="h-12 w-12 rounded-full mr-4" 
                  />
                  <div className="flex-grow">
                    <h2 className="font-semibold">
                      {currentUser.id === message.senderUid ? message.receiverName : message.senderName}
                    </h2>
                    <p className="text-sm text-gray-600">{message.lastMessage}</p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Chat window on the right */}
        <div className="w-full hidden lg:flex lg:w-2/4 h-full">
          {selectedMessage && (
            <ChatWindow
              message={selectedMessage}
              selectedUser={currentUser.id === selectedMessage.senderUid ? { userName: selectedMessage.receiverName, userImage: selectedMessage.receiverImage } : { userName: selectedMessage.senderName, userImage: selectedMessage.senderImage }}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportantMessages;

