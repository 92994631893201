import React, { useState, useEffect } from "react";
import Button from "../../Components/button";
import { useNavigate, useLocation } from "react-router-dom";
import OTPInputComponent from "../../Components/otp_input";
import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { auth } from "../../../firebase_service";
import { showToast, Toast } from "../../Components/toast";
import { verify_phone } from "../../../Controller/auth";
import ResendLink from "../../Components/resend-link";
import { sendOTP } from "../../../Controller/firebase/firebase_phone_verification";
import CenteredImageContainer from "../../Components/black_container";

const CompanyPhoneVerification = () => {
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const phone = location.state?.phone;

  useEffect(() => {
    const storedVerificationId = sessionStorage.getItem("verificationId");
    if (storedVerificationId) {
      setVerificationId(storedVerificationId);
    }
  }, []);

  const handleBackClick = () => {
    navigate("/phone_details")
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      const userCredential = await signInWithCredential(auth, credential);
      const user = userCredential.user;

      localStorage.setItem("firebase_uid", user.uid);
      const secret = localStorage.getItem("secret");
      const email = localStorage.getItem("email");

      const payload = {
        email: email,
        firebase_uid: user.uid,
        secret: secret,
        phone: phone
      };
      console.log(payload);
      const response = await verify_phone(payload);
      console.log(response);
      if (response['status_code'] === 200) {
        const secret_new = response['data']['secret'];
        localStorage.setItem('secret', secret_new);
        // Navigate to /confirm-password instead of showing the popup
        navigate('/confirm-password');
      } else if (response['status_code'] === 400) {
        showToast("Phone verification failed. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("Incorrect OTP. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    try {
      const confirmationResult = await sendOTP(phone);
      sessionStorage.setItem("verificationId", confirmationResult.verificationId);
      setVerificationId(confirmationResult.verificationId);
      showToast("OTP resent successfully.", "success");
      setOtp(""); // Clear the OTP input
  
      // Immediately reload the page after successfully resending OTP
      window.location.reload(); // Refresh the page immediately
  
      return true; // Indicate OTP was successfully sent
    } catch (error) {
      console.error("Error resending OTP:", error);
      showToast("Error resending OTP. Please try again.", "error");
      return false; // Indicate OTP sending failed
    }
  };
  
  
  
  


  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-7 font-poppins">
        <div className="p-6 w-full max-w-2/5 space-y-5">
          <div className="flex items-center mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <h2 className=" text-xl lg:text-3xl font-semibold text-black mb-2 font-poppins">
            Phone Number Verification
          </h2>
          <p className="text-sm lg:text-lg text-custom-gray mb-6 font-poppins">
            Please enter your OTP sent to your phone
          </p>
          <div id="recaptcha-container"></div> {/* Add this line for reCAPTCHA */}

          <div className="w-full flex justify-start items-start mb-2">
            <OTPInputComponent otp={otp} handleChange={handleChange} />
          </div>

          <div className="flex justify-center text-[#DB0000] text-[10px] mb-1 lg:ml-4 ">
            <ResendLink linkText="Send a new code" onResend={handleResendOTP} />
          </div>

          <Button
            text="Verify"
            borderColor="border-red-600"
            backgroundColor="bg-[#DB0000]"
            textColor="text-white"
            onClick={handleVerify}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyPhoneVerification;
// import React, { useState, useEffect } from "react";
// import Button from "../../Components/button";
// import { useNavigate, useLocation } from "react-router-dom";
// import OTPInputComponent from "../../Components/otp_input";
// import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";
// import { auth } from "../../../firebase_service";
// import { showToast, Toast } from "../../Components/toast";
// import { verify_phone } from "../../../Controller/auth";
// import ResendLink from "../../Components/resend-link";
// import { sendOTP } from "../../../Controller/firebase/firebase_phone_verification";
// import CenteredImageContainer from "../../Components/black_container";

// const CompanyPhoneVerification = () => {
//   const [otp, setOtp] = useState(""); // Set the OTP state here
//   const [verificationId, setVerificationId] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [confirmationResult, setConfirmationResult] = useState(null);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const phone = location.state?.phone;

//   useEffect(() => {
//     const storedVerificationId = sessionStorage.getItem("verificationId");
//     if (storedVerificationId) {
//       setVerificationId(storedVerificationId);
//     }
//   }, []);

//   useEffect(() => {
//     // Automatically fill the OTP input field
//     if (confirmationResult) {
//       confirmationResult.confirm(otp).then((result) => {
//         // User is now signed in
//         console.log("User is signed in:", result.user);
//         handleVerify();
//       });
//     }
//   }, [otp, confirmationResult]);

//   const handleBackClick = () => {
//     navigate("/phone_details");
//   };

//   const handleChange = (otp) => {
//     setOtp(otp); // Set OTP value on change
//   };

//   const handleVerify = async () => {
//     setLoading(true);
//     try {
//       const credential = PhoneAuthProvider.credential(verificationId, otp);
//       const userCredential = await signInWithCredential(auth, credential);
//       const user = userCredential.user;

//       localStorage.setItem("firebase_uid", user.uid);
//       const secret = localStorage.getItem("secret");
//       const email = localStorage.getItem("email");

//       const payload = {
//         email: email,
//         firebase_uid: user.uid,
//         secret: secret,
//         phone: phone,
//       };
//       console.log(payload);
//       const response = await verify_phone(payload);
//       console.log(response);
//       if (response["status_code"] === 200) {
//         const secret_new = response["data"]["secret"];
//         localStorage.setItem("secret", secret_new);
//         // Navigate to /confirm-password instead of showing the popup
//         navigate("/confirm-password");
//       } else if (response["status_code"] === 400) {
//         showToast("Phone verification failed. Please try again.", "error");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       showToast("Incorrect OTP. Please try again.", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleResendOTP = async () => {
//     try {
//       const confirmationResultTemp = await sendOTP(phone);
//       sessionStorage.setItem("verificationId", confirmationResultTemp.verificationId);
//       setVerificationId(confirmationResultTemp.verificationId);
//       setConfirmationResult(confirmationResultTemp);
//       showToast("OTP resent successfully.", "success");
//       setOtp(""); // Clear the OTP input
//     } catch (error) {
//       console.error("Error resending OTP:", error);
//       showToast("Error resending OTP. Please try again.", "error");
//     }
//   };

//   return (
//     <div className="h-full w-full flex bg-[#FBFCF8]">
//       <Toast />
//       <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
//         <div className="w-4/5 h-full">
//           <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
//         </div>
//       </div>
//       <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-7 font-poppins">
//         <div className="p-6 w-full max-w-2/5 space-y-5">
//           <div className="flex items-center mb-6">
//             <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
//           </div>
//           <h2 className="text-3xl font-semibold text-black mb-2 font-poppins">
//             Phone Number Verification
//           </h2>
//           <p className="text-lg text-custom-gray mb-6 font-poppins">
//             Please enter your OTP sent to your phone
//           </p>
//           <div id="recaptcha-container"></div> {/* Add this line for reCAPTCHA */}

//           <div className="w-full flex justify-start items-start mb-2">
//             <OTPInputComponent otp={otp} handleChange={handleChange} setOtp={setOtp} />
//           </div>

//           <div className="flex justify-end text-[#DB0000] text-sm mb-1 lg:mr-36">
//             <ResendLink linkText="Resend OTP" onResend={handleResendOTP} />
//           </div>

//           <Button
//             text="Verify"
//             borderColor="border-red-600"
//             backgroundColor="bg-[#DB0000]"
//             textColor="text-white"
//             onClick={handleVerify}
//             loading={loading}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyPhoneVerification;