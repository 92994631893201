import React, { useState, useEffect } from 'react';
import Button from './button';
import uploadImage from '../../Controller/firebase/firebase_upload_image'; // Import the updated Firebase upload function

const CoverImagePopup = ({ onClose, onSave, isEditing, initialImage, title, dimensionNote, isLogo }) => {
  const [newImage, setNewImage] = useState(null); // For previewing the selected image
  const [selectedFile, setSelectedFile] = useState(null); // To hold the file to upload
  const [uploading, setUploading] = useState(false); // To show loading while uploading

  useEffect(() => {
    if (isEditing && initialImage) {
      setNewImage(initialImage);
    }
  }, [isEditing, initialImage]);

  // Handle file selection and preview
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file); // Store the file for later upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result); // Preview the image
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle saving the image
  const handleSave = async () => {
    if (!selectedFile) {
      console.error("No file selected for upload");
      return;
    }

    try {
      setUploading(true);
      const downloadURL = await uploadImage(selectedFile); // Upload the image
      setUploading(false);
      console.log("Image uploaded successfully:", downloadURL);

      // Pass the download URL to the parent
      onSave(downloadURL);

      // Close the popup
      onClose();
    } catch (error) {
      setUploading(false);
      console.error("Error uploading image:", error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60"></div>

      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-2xl w-[350px] h-[400px] md:w-[400px] lg:w-[450px]">
          <div className="flex justify-between items-center mb-2 ">
            <h2 className="text-xl font-bold">{title}</h2>
            <button onClick={onClose} className="text-black">✕</button>
          </div>
          {/* <div className="border-b border-gray-300 mb-4 w-full"></div> */}

          <div className="flex flex-col items-center justify-center mt-10">
            <div 
              className={`${isLogo ? 'bg-gray-300 h-28 w-28 rounded-full' : 'bg-gray-300 h-40 w-60 rounded-lg'} flex items-center justify-center mt-4`}
              style={{ backgroundImage: `url(${newImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
            >
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
              <img
                src={newImage ? "/Assets/edit.svg" : "/Assets/grey_plus.svg"} 
                alt={newImage ? "Edit" : "Add"}
                className="absolute h-8 w-8 cursor-pointer"
                onClick={() => document.getElementById('fileInput').click()}
              />
            </div>
            <div className="text-gray-500 text-sm mt-2">{dimensionNote}</div>

            <div className="mt-4 w-3/4">
              <Button 
                text={uploading ? "Uploading..." : "Save"}
                onClick={handleSave}
                backgroundColor="bg-custom-button-color" 
                textColor="text-white" 
                className="w-full" 
                disabled={uploading} 
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverImagePopup;
