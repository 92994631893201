import React, { useState, useEffect } from 'react';
import ResendLink from '../../Components/resend-link';
import Button from '../../Components/button';
import OTPInputComponent from '../../Components/otp_input';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import { Toast } from '../../Components/toast';
import { verifyOtp } from '../../../Controller/email_validation_controller';
import { check_email } from '../../../Controller/auth';
import CenteredImageContainer from '../../Components/black_container.jsx';

const EmailVerificationPage = ({ email: initialEmail }) => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState(initialEmail || localStorage.getItem('email') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false); // Error state
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      showToast('Email address is missing. Please try again.', 'error');
    }
  }, [email]);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const clearOtp = () => {
    setOtp('');
  };

  const handleResend = async () => {
    if (!email) {
      showToast('Email address is missing. Please try again.', 'error');
      setHasError(true); // Set error if email is missing
      return;
    }

    setIsLoading(true);
    try {
      await check_email(email);
      clearOtp();
      setHasError(false); // Clear error on successful resend
    } catch (error) {
      console.error('Error resending OTP:', error);
      setHasError(true); // Set error if resend fails
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (email) {
      const response = await verifyOtp(email, otp, navigate, setIsLoading);
      if (response && response.status === 201) {
        navigate('/phone_details');
        setHasError(false); // Clear error on successful verification
      } else {
        showToast('Verification failed. Please try again.', 'error');
        setHasError(true); // Set error if verification fails
      }
    }
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8] font-poppins">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
        <div className="p-6 w-full max-w-2/5">
          <div className="flex items-center mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <h2 className="text-[28px] lg:text-3xl font-semibold text-[#0D0907] mb-6">Email verification</h2>
          <p className=" text-sm text-[#6B6B6B] lg:text-sm text-[#9F9F9F] mb-6">Please enter your otp sent to your mail</p>
          <div className="flex justify-center items-center mb-4">
            <OTPInputComponent otp={otp} handleChange={handleChange} />
          </div>
          <div className="flex justify-center text-[#DB0000] text-sm mb-4">
            <ResendLink linkText="Send a new code" onResend={handleResend} hasError={hasError} /> {/* Pass hasError */}
          </div>
          <Button
            text="Verify"
            onClick={handleSubmit}
            disabled={isLoading || otp.length !== 6 || !email}
            backgroundColor="bg-[#DB0000]"
            textColor="text-white"
            fontWeight="font-bold"
            className="w-full"
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
