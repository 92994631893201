import React from 'react';

// StarRating component
const StarRating = ({ review }) => {
  const maxStars = 5;
  const filledStars = Math.round(review);
  const emptyStars = maxStars - filledStars;

  return (
    <div className="flex">
      {[...Array(filledStars)].map((_, index) => (
        <img key={index} src="/Assets/filled_star.svg" alt="Filled Star" className="lg:w-5 lg:h-5 w-4 h-4" />
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <img key={index} src="/Assets/unfilled_star.svg" alt="Empty Star" className="lg:w-5 lg:h-5 w-4 h-4" />
      ))}
    </div>
  );
};

export default StarRating;
