import React from 'react';

const TextArea = ({ value, onChange, placeholder, className, style, fontSize = "14px",maxLength }) => {
  return (
    <>
      <style>
        {`
          .textarea-placeholder::placeholder {
            color: #8D8D8D;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: ${fontSize};
          }
          .textarea-placeholder {
            color: #8D8D8D;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: ${fontSize};
          }
        `}
      </style>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full p-4 h-32 mb-4 bg-[#FFFFFF] outline-none resize-none textarea-placeholder ${className}`}
        style={{ 
          ...style, 
          borderRadius: '4px',      // Add border-radius 4px
          border: '1px solid #E8E8E8', // Add 1px solid border with the color #E8E8E8
          fontFamily: 'Poppins, sans-serif',
          fontWeight: '400',
          fontSize
        }}
        maxLength={maxLength} // Adding character limit
        />
        <div className="text-right text-sm text-gray-500">
          {value.length}/{maxLength} characters
        </div>
      
      
    </>
  );
};

export default TextArea;
