import { login } from './auth.js';
import { ACCESS_TOKEN, REFRESH_TOKEN } from './Constants/constants.js';
import { showToast } from '../View/Components/toast.jsx';

export const handleLogin = async (password, email, navigate, setLoading) => {
  if (!email || !password) {
    showToast('Please provide both email and password', 'error');
    setLoading(false);
    return;
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    showToast('Please provide a valid email address', 'error');
    setLoading(false);
    return;
  }

  setLoading(true);
  try {
    const loginResponse = await login(password, email);

    console.log('loginResponse:', loginResponse);

    if (loginResponse.status === 200) {
      console.log(loginResponse)
      const userId = loginResponse.data.data.user_id; // Adjust according to your API response structure
      const company_id=loginResponse.data.data.company_id
      console.log('User ID from login response:', userId);
      const company_uid = loginResponse.data.data.company_uid; // Check the value

      console.log('Company UID from login response:', company_uid); // Debug
      // Parse as an integer
      localStorage.setItem('company_uid', company_uid);
      localStorage.setItem('company_id', company_id);

      // Store userId in localStorage
      localStorage.setItem('userId', userId);
      
      console.log('User ID stored in localStorage:', userId);
      localStorage.setItem(ACCESS_TOKEN, loginResponse.data.data.access);
      localStorage.setItem(REFRESH_TOKEN, loginResponse.data.data.refresh);
     

      const { verification_status, is_completed, completed } = loginResponse.data.data;

      console.log('completed:', completed);

      const navigateWithState = (path) => {
        console.log(`Navigating to ${path} with userId:`, userId);
        navigate(path, { state: { completedStepsFromLogin: completed, userId } });
      };

      if (is_completed) {
        switch (verification_status) {
          case 'VERIFICATION_PENDING':
          case 'VERIFICATION_COMPLETED':
            navigateWithState('/home');
            break;
          case 'VERIFICATION_REJECTED':
            navigateWithState('/home');
            break;
          default:
            // Handle other cases if needed
            break;
        }
      } else {
        if (completed.includes('CONTINENT_COMPLETED')) {
          if (completed.includes('CATEGORY_COMPLETED')) {
            navigateWithState('/details_page');
          } else {
            navigateWithState('/trade-selection');
          }
        } else {
          navigateWithState('/location-selection');
        }
      }
    } else {
      showToast('Login failed. Please check your credentials.', 'error');
      setLoading(false);
    }
  } catch (error) {
    console.error('Error during login:', error);
    setLoading(false);
  }
};