import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileImageContainer from '../../Components/profile_image_container.jsx';
import Button from '../../Components/button.jsx';
import Sidebar from '../../Components/main_navbar.jsx';
import { getOtherProfileById } from '../../../Controller/other_profile.js';
import StarRating from '../../Components/review_star.jsx';
import TabButton from '../../Components/profile_tab.jsx';
import { WebLinkModal, BottomSheet } from '../../Components/web_link.jsx';
import ConfirmationPopup from '../../Components/pop_up';
import { showToast } from '../../Components/toast.jsx';
import ImageButton from '../../Components/image_text_button.jsx';

const OtherProfileVisitorsView = ({ editable }) => {
  const { company_id } = useParams(); // Extract company_id from the URL
  const navigate = useNavigate();
  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [category, setCategory] = useState('');
  const [locationText, setLocationText] = useState('');
  const [bio, setBio] = useState('');
  const [webLinks, setWebLinks] = useState([]);
  const [review, setReview] = useState(0);
  const [catalogue, setCatalogue] = useState(null);
  const [website, setWebsite] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  // Fetch profile data based on company_id
  useEffect(() => {
    if (company_id) {
      const fetchData = async () => {
        try {
          const response = await getOtherProfileById(company_id); // Fetch profile data by company_id
          console.log(response)
          if (response.status === 200) {
            const data = response.data.data;
            setStoreName(data.name);
            setCategory(data.category);
            setLocationText(data.address);
            setSelectedProfileImage(data.profile_image_url);
            setReview(data.review);
            setBio(data.bio);
            setWebLinks(data.web_links || []);
            setCatalogue(data.catalogue);
            setWebsite(data.website);
  
            // Store the uid in localStorage
            if (data.uid) {
              localStorage.setItem('uid', data.uid); // Store uid to pass it when navigating
            }
          } else {
            console.error("Failed to fetch company data:", response.status);
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };
  
      fetchData();
    } else {
      console.error("company_id is undefined.");
    }
  }, [company_id]);

  // General function to navigate and pass uid or company_id based on the destination
  const navigateWithParam = (route, useCompanyId = false) => {
    if (useCompanyId) {
      // Pass company_id for the Documents route
      navigate(`${route}/${company_id}`);
    } else {
      const uid = localStorage.getItem('uid'); // Retrieve the uid from localStorage
      if (uid) {
        navigate(`${route}/${uid}`); // Pass the uid as a URL parameter for other cases
      } else {
        console.error('UID not found in local storage');
      }
    }
  };

  const handleViewCatalogue = () => {
    if (catalogue) {
      window.open(catalogue, '_blank');
    }
  };

  const handleAddCatalogue = () => {
    navigateWithParam('/catalog'); // Navigate to catalog page with uid
  };

  const handleWebLinkClick = () => {
    if (window.innerWidth >= 1024) {
      setIsModalOpen(true);
    } else {
      setIsBottomSheetOpen(true);
    }
  };

  const handleEditProfileClick = () => {
    const verificationStatus = localStorage.getItem('verificationStatus');
    if (verificationStatus === 'VERIFICATION_PENDING') {
      setShowPopup(true); // Show the popup if verification is pending
    } else {
      navigateWithParam('/edit_form'); // Navigate to edit form with uid
    }
  };

  const handleShareProfileClick = () => {
    const verificationStatus = localStorage.getItem('verificationStatus');
    if (verificationStatus === 'VERIFICATION_PENDING') {
      setPopupMessage('Your profile is under verification. Please wait.');
      setShowPopup(true); // Show the popup if verification is pending
    } else {
      showToast('This feature will be unlocked in the next update.', 'default');
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-poppins">
      <Sidebar />
      <div className="flex flex-col w-full h-full">
        <div
          className="relative w-full lg:h-52 h-1/4"
          style={{
            backgroundImage: `url(/Assets/cover_image.svg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <h1 className="absolute text-[#263238] font-bold font-gotham lg:text-2xl text-xl p-4 z-10">
            Profile
          </h1>

          {/* Profile Image Container */}
          <div className="absolute inset-x-0 lg:mt-32 mt-28 flex justify-center lg:w-4/5">
            <ProfileImageContainer
              editable={editable}
              profileImage={selectedProfileImage}
              onSaveProfileImage={setSelectedProfileImage}
            />
          </div>
          <div className="flex flex-col items-center lg:w-4/5 lg:mt-64 mt-56 lg:mx-0 mx-5">
            <h2 className="text-lg font-normal font-techna">{storeName}</h2>
            <p className="lg:text-sm font-normal font-gotham text-xs">{locationText}</p>
            <StarRating review={review} />
          </div>
        </div>

        <div className="lg:mt-24 mt-36 text-left lg:ml-10 ml-5">
          <h2 className="text-sm lg:text-lg font-semibold mb-1 font-gotham text-[#263238]">
            About
          </h2>
          <p className="text-sm mb-1 text-[#6B6B6B]">{bio}</p>
          <h3
            className="flex items-center lg:text-xs text-xs font-normal mb-1 cursor-pointer text-[#DB0000] font-gotham"
            onClick={handleWebLinkClick}
          >
            <img
              src="/Assets/web_link.svg"
              alt="Link Icon"
              className="lg:w-5 lg:h-5 mr-2 w-4 h-4"
            />
            Web Links
          </h3>
          <ul className="list-disc ml-4 text-sm">
            {webLinks.map((link, index) => (
              <li key={index}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Row of follow-up, message, and share buttons */}
        <div className="flex justify-center lg:space-x-4 space-x-2 mt-1 lg:mx-0 mx-4">
          <ImageButton
            onClick={() => console.log('Follow-up clicked')}
            imageSrc="/Assets/follow-up.svg"
            buttonText="Follow up"
          />
          <ImageButton
            onClick={() => console.log('Message clicked')}
            imageSrc="/Assets/message-new.svg"
            buttonText="Message"
          />
          <ImageButton
            onClick={handleShareProfileClick}
            imageSrc="/Assets/share-new.svg"
            buttonText="Share"
          />
        </div>

        {/* Product Catalogue Section */}
        <div className="w-full lg:w-4/5 flex justify-between items-center lg:mt-1 mt-2">
          <div className="w-1/2 text-black font-semibold lg:ml-8 ml-2 text-sm lg:text-lg">
            Product Catalogue
          </div>
          <div className="w-1/2 flex justify-end">
            <div className='lg:w-1/3 w-1/2 h-1/5 mb-2 shadow-lg mr-4'>
              {catalogue ? (
                <Button
                  text="View"
                  backgroundColor="bg-[#ffffff]"
                  textColor="text-[#263238]"
                  onClick={handleViewCatalogue}
                />
              ) : (
                <Button
                  text="Add"
                  backgroundColor="bg-[#FFFFFF]"
                  textColor="text-[#263238]"
                  onClick={handleAddCatalogue}
                />
              )}
            </div>
          </div>
        </div>

        <div className="w-full lg:w-4/5 relative p-2 lg:ml-4 lg:mt-3">
          <div className="grid grid-cols-2 lg:grid-cols-2 lg:gap-4 gap-5">
            <TabButton
              icon="/Assets/profile_management.svg"
              label="Management"
              path={() => navigateWithParam('/view-management')}
            />
            <TabButton
              icon="/Assets/profile_docs.svg"
              label="Documents"
              path={() => navigateWithParam('/company_docs_view', true)}  // Pass company_id for Documents
            />
            <TabButton
              icon="/Assets/profile_complaints.svg"
              label="Complaints"
              path={() => navigateWithParam('/complaints-received')}
            />
            <TabButton
              icon="/Assets/profile_feedback.svg"
              label="Feedback"
              path={() => navigateWithParam('/feedback/owner')}
            />
          </div>

          {/* Centered white circle */}
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="relative lg:w-40 lg:h-40 h-25 w-25 bg-white rounded-full flex items-center justify-center">
              <div className="grid grid-cols-2 lg:gap-3 gap-2 lg:p-3 p-1">
                <img
                  src="./photos.svg"
                  alt="Photos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/view_photos')}
                />
                <img
                  src="./videos.svg"
                  alt="Videos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/uploaded_video')}
                />
                <img
                  src="./product.png"
                  alt="Products"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/product_service_list')}
                />
                <img
                  src="./verified-tick.svg"
                  alt="Verification"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/profile-verification')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for large screens */}
      <WebLinkModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} link={website} />

      {/* Bottom Sheet for small screens */}
      <BottomSheet isOpen={isBottomSheetOpen} onClose={() => setIsBottomSheetOpen(false)} link={website} />

      {/* Confirmation Popup */}
      <ConfirmationPopup onClose={() => setShowPopup(false)} show={showPopup} />
    </div>
  );
};

export default OtherProfileVisitorsView;