// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import Button from '../../Components/button';
// import { useNavigate } from 'react-router-dom';
// import ReportContainer from '../../Components/report';
// import { useEffect } from 'react';
// import { useState } from 'react';
// const VerificationOfProfile = () => {
//   const location = useLocation();
//   const isVerified = location.pathname.includes('verified');
//   const navigate = useNavigate();




//   const profileData = {
//     storeName: 'Electronics Store',
//     category: 'Electronics',
//     location: 'Kerala, India',
//     establishedOn: '12 Feb 2004',
//     userSince: '15 March 2024',
//     verifiedOn: '17 March 2024',
//     validityDate: '17 March 2029',
//     logoUrl: './electronics.svg',
//   };

//   const handleBackClick = () => {
//     navigate('/profile1');

//   };



//   return (
//     <>
//       <div className="h-full w-full flex flex-col overflow-y-auto">
//       <Sidebar />
//       <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
//             <div className="w-full flex justify-start items-center space-x-2">
                
//               <img
//                 src="/Assets/arrow_back.svg"
//                 alt="Back"
//                 className="cursor-pointer mr-2 h-6 w-6"
//                 onClick={handleBackClick}
//               />
//               <h1 className="text-black font-bold text-xl"> {isVerified ? 'Verified Profile' : 'Verification'}</h1>   </div>
//               <ReportContainer/>
//           </div>
     
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>

//         <main className="flex-grow p-4 w-full flex justify-center overflow-y-auto">
//           <div className="p-4 bg-white rounded-lg max-w-lg w-full">
//             <div className="flex flex-col mb-4">
//               <img src={profileData.logoUrl} alt="logo" className="w-28 h-28 mb-4" />
//               <div className="mb-5">
//                 <div className="flex justify-between items-center">
//                   <h2 className="text-xl font-bold">{profileData.storeName}</h2>
//                   {isVerified && (
//                     <div className="flex items-center">
//                       <img
//                         src="./tick (2).svg"
//                         alt="Verified"
//                         className="h-6 w-6 mr-1"
//                       />
//                       <span className="text-xs text-red-600">Verified</span>
//                     </div>
//                   )}
//                 </div>
//                 <p className="text-sm font-bold">{profileData.category}</p>
//                 <p className="text-base font-bold">{profileData.location}</p>
//               </div>
//             </div>
//             <div className="bg-gray-100 p-7 rounded-md mb-4 text-left">
//               <p className="font-bold font-techna mb-2">Established On: {profileData.establishedOn}</p>
//               <p className="font-bold font-techna mb-2">ESWAF user since: {profileData.userSince}</p>
//               {isVerified && (
//                 <>
//                   <p className="font-bold font-techna mb-2">Verified On: {profileData.verifiedOn}</p>
//                   <p className="font-bold font-techna mb-2">Validity Date: {profileData.validityDate}</p>
//                 </>
//               )}
//             </div>
//             <div className="flex justify-center pt-12">
//               <Button 
//                 text={isVerified ? 'Verified' : 'Request Verification'} 
//                 navigateTo="/verified-subscription"
//                 backgroundColor="bg-custom-button-color" 
//                 textColor="text-white" 
//                 className="w-full md:w-3/4 md:ml-4"
//               />
//             </div>
//           </div>
//         </main>
//       </div>
//     </>
//   );
// };

// export default VerificationOfProfile;




// const VerificationOfProfile = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [verificationStatus, setVerificationStatus] = useState(null); // State to store verification status

//   useEffect(() => {
//     // Retrieve the verification status from localStorage
//     const status = localStorage.getItem('verificationStatus');
//     setVerificationStatus(status); // Set the verificationStatus state
//   }, []);

//   const isVerified = verificationStatus === 'VERIFICATION_COMPLETED';

//   const handleBackClick = () => {
//     navigate('/profile1');
//   };

//   // This will determine the content based on verificationStatus
//   const renderContent = () => {
//     switch (verificationStatus) {
//       case 'VERIFICATION_PENDING':
//         return (
//           <>
//           <div className='ml-12'>
//             <h1 className="text-2xl font-bold text-gray-900 text-center">
//               Thank you for completing your profile. Your profile is under verification.
//             </h1>
//             <p className="mt-4 text-gray-600 text-center">
//               We will let you know once the verification process is completed.
//             </p>
//             </div>
//           </>
//         );
//       case 'VERIFICATION_REJECTED':
//         return (
//           <>
//             <h1 className="text-2xl font-bold text-gray-900 text-center">
//               Your profile is rejected
//             </h1>
//             <p className="mt-4 text-gray-600 text-center">
//               Click the below button to resubmit your profile.
//             </p>
//             <div className="flex justify-center pt-12">
//               <Button
//                 text="Resubmit"
//                 borderColor="border-red-600"
//                 backgroundColor="bg-[#DB0000]"
//                 textColor="text-white"
//                 onClick={() => navigate('/update-form')}
//                 className="w-full md:w-3/4"
//               />
//             </div>
//           </>
//         );
//       case 'VERIFICATION_COMPLETED':
//         return (
//           <>
//             <h1 className="text-2xl font-bold text-gray-900 text-center">
//               Your profile is completed
//             </h1>
//             <p className="mt-4 text-gray-600 text-center">
//               More features will be available in future updates.
//             </p>
//           </>
//         );
//       default:
//         return null; // Return nothing if the status is not recognized
//     }
//   };

//   return (
//     <div className="h-full w-full flex flex-col overflow-y-auto">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-7">
          
//           <h1 className="text-black font-bold text-xl">
//             {isVerified ? 'Verified Profile' : 'Verification'}
//           </h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>

//       {/* Conditional rendering based on verificationStatus */}
//       <main className="flex-grow flex justify-center items-center p-4">
//         <div className="p-8 bg-white rounded-lg max-w-lg w-full text-center">
//           {renderContent()} {/* Call the function to render the content based on the status */}
//         </div>
//       </main>
//     </div>
//   );
// };

// export default VerificationOfProfile;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import { get_company_about } from '../../../Controller/company_details'; // Import the API function

const VerificationOfProfile = () => {
  const location = useLocation();
  const { uid } = useParams(); // Get uid from the URL params if it's another profile
  const navigate = useNavigate();
  
  const [isMyProfile, setIsMyProfile] = useState(false); // State to check if it is my profile
  const [profileData, setProfileData] = useState({
    storeName: '',
    category: '',
    location: '',
    logoUrl: './default_logo.svg', // Fallback to a default logo
    verifiedOn: '',
    validityDate: ''
  });

  const formatDateTime = (dateTime) => {
    if (!dateTime) return 'Not available';
    const date = new Date(dateTime + 'Z'); // Ensure it's treated as UTC by appending 'Z'
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        let companyUid;

        // Check if uid is in URL params, if so, it's another profile
        if (uid) {
          setIsMyProfile(false); // This is another user's profile
          companyUid = uid;
        } else {
          // No uid in the URL, so it's the logged-in user's own profile
          setIsMyProfile(true); // This is the user's own profile
          companyUid = localStorage.getItem('company_uid');
        }

        if (!companyUid) {
          throw new Error("Company UID is not available.");
        }

        const response = await get_company_about(companyUid);
        if (response.status === 200) {
          const company = response.data.data;
          setProfileData({
            storeName: company.name,
            category: company.category,
            location: company.location,
            logoUrl: company.logo || './default_logo.svg', // Fallback if no logo
            verifiedOn: company.verified_on,
            validityDate: company.valid_till
          });
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [uid]);

  const handleBackClick = () => {
    navigate('/profile1');
  };

  return (
    <>
      <div className="h-full w-full flex flex-col overflow-y-auto">
        <Sidebar />
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
          <div className="w-full flex justify-start items-center space-x-2">
            <h1 className="text-black font-semibold text-xl">
              {isMyProfile ? 'Verification' : 'Verification'}
            </h1>
          </div>
        </div>

        <div className="border-b border-black mt-4 md:mt-[25px]"></div>

        <main className="flex-grow p-4 lg:w-4/5 w-full  flex justify-center overflow-y-auto font-poppins mt-10 ">
          <div className=" bg-white rounded-lg max-w-lg w-full text-center">
            {/* Profile Section */}
            <div className="flex flex-col items-center mb-4">
              <img
                src={profileData.logoUrl}
                alt="logo"
                className="w-28 h-28 mb-4 rounded-full object-cover" // Rounded image
              />
              <h2 className="lg:text-xl sm:text-lg font-poppins font-semibold mb-2">
                {profileData.storeName}
              </h2>
              <p className="lg:text-sm text-xs font-medium text-gray-600">
                {profileData.category}
              </p>
              <p className="lg:text-sm text-xs font-medium text-gray-600">
                {profileData.location}
              </p>
            </div>

            {/* Verification Details */}
            <div className="bg-gray-100 p-4 rounded-md mb-4 text-left mt-12">
              <div className="flex justify-between mb-2">
                <span className="lg:text-sm text-xs ">eSwaF user since:</span>
                <span className="lg:text-sm text-xs">{profileData.verifiedOn ? formatDateTime(profileData.verifiedOn) : 'Not Verified'}</span>
              </div>
              <div className="flex justify-between">
                <span className="lg:text-sm text-xs ">Verification validity:</span>
                <span className="lg:text-sm text-xs">{profileData.validityDate ? formatDateTime(profileData.validityDate) : 'Not applicable'}</span>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerificationOfProfile;