import React from 'react';

const InputBox = ({
  value,
  onChange,
  onClick,  // Add onClick as a prop
  placeholder,
  type = "text",
  borderRadius = "4px",
  backgroundColor = "#FFFFFF",
  padding = "12px",
  marginBottom = "auto",
  fontSize = "12px",
  borderColor = "#E8E8E8",
  borderWidth = "1px",
  disabled = false, // Added disabled prop with default value false
}) => {
  return (
    <>
      <style>
        {`
          .input-placeholder::placeholder {
            color: #8D8D8D;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: ${fontSize};
          }
          .input-placeholder {
            color: #8D8D8D;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: ${fontSize};
          }
        `}
      </style>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className={`w-full input-placeholder`}
        onChange={onChange}
        onClick={onClick} // Trigger the onClick event
        style={{
          borderRadius,
          backgroundColor,
          padding,
          marginBottom,
          outline: 'none',
          width: '100%',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: '400',
          fontSize,
          border: `${borderWidth} solid ${borderColor}`, // Added border style
        }}
        disabled={disabled} // Apply disabled state to input
      />
    </>
  );
};

export default InputBox;
