import React, { useState, useEffect, useRef } from 'react';
import InputBox from '../../Components/input_box';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import '../../Components/multi_select.css'; 
import { PostRequestUpload } from '../../../Controller/post_request';
import UploadDocuments from '../../../Controller/firebase/firebase_documents';
import LoaderSpinner from '../../Components/loader_spinner'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const PostRequest = () => {
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [tradeRollType, setTradeRollType] = useState('');
  const [detailedRequirement, setDetailedRequirement] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState(null); 
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false); 
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const storedCategory = localStorage.getItem('category'); 
    setTradeRollType(storedCategory); 
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDocumentUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await UploadDocuments(file); 
        console.log('Document upload response:', response); // Log the response from document upload
        setUploadedFileName(file.name); 
        console.log("Document uploaded successfully:", file.name);
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    setLoading(true);

    try {
      const response = await PostRequestUpload(
        productName,
        quantity,
        tradeRollType, 
        tradeRollType,
        detailedRequirement,
        uploadedFileName, 
        isChecked 
      );

      // Log the response to the console
      console.log('Post request response:', response);

      if (response.status === 201) {
        console.log('Item submitted successfully:', response);
        const newPostData = response.data; // Get the new post data from the response
        
        // Navigate to the posts page and pass the new post data
        navigate('/posts', { state: { newPostData } });
      } else {
        console.error('Failed to submit item. Response:', response);
      }
    } catch (error) {
      console.error('Error submitting item:', error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna overflow-y-auto">
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-bold text-xl">Post Request</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <Sidebar />
      <div className="lg:w-4/5 mx-5 lg:ml-5 justify-center">
        <div className="flex-1 p-8 max-w-2xl mx-auto mt-5 overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-helvetica">Product Name/Direct Company/Service Requested</label>
              <InputBox 
                borderRadius='1px'
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-helvetica">Required Quantity / More details requested service</label>
              <InputBox 
                borderRadius='1px'
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-helvetica">Select Type of Trade Roll</label>
              <InputBox 
                borderRadius='1px'
                value={tradeRollType}
                readOnly 
              />
            </div>
            <div className="mb-1 relative">
              <label className="block mb-2 text-[#4A4A4A] font-helvetica">Detailed Requirement</label>
              <div className="relative">
                <TextArea
                  value={detailedRequirement}
                  onChange={(e) => setDetailedRequirement(e.target.value)}
                  placeholder="Describe your detailed requirements here..."
                  maxLength={500}
                  className="w-full p-3 border border-[#A2A2A2] rounded-lg h-40 relative"
                />

                <input
                  type="file"
                  accept="application/pdf"
                  ref={fileInputRef} 
                  className="hidden" 
                  onChange={handleDocumentUpload}
                />

                <div className="absolute top-0 right-0 m-2" onClick={() => fileInputRef.current.click()}>
                  <img src="./Assets/doc.svg" alt="doc" className="cursor-pointer" />
                </div>

                {uploadedFileName && (
                  <p className="mt-2 text-sm text-gray-600">Uploaded File: {uploadedFileName}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap items-start mt-12">
              <input 
                type="checkbox" 
                value="International" 
                onChange={handleCheckboxChange}
                className="form-checkbox text-red-500"
                style={{
                  minWidth: '1.5rem',
                  minHeight: '1.5rem',
                  width: '1.5rem',
                  height: '1.5rem',
                  flexShrink: 0,
                  marginTop: '0.25rem'
                }}
              />
              <label className="ml-2 text-sm sm:text-base text-[#4A4A4A] flex-1">
                Agree to receive notifications and messages against this request.
              </label>
            </div>

            <div className="text-center w-full mt-7">
              <button
                type="submit"
                className="bg-[#DB0000] text-white px-6 py-4 rounded-lg w-full flex justify-center items-center"
                disabled={loading}
              >
                {loading ? <LoaderSpinner visible={true} /> : "Post"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PostRequest;
