import { api } from "./api";
import { GET_VERIFICATION_IMAGE_URL,CREATE_VERIFICATION_IMAGE_URL ,DELETE_VERIFICATION_IMAGE_URL} from "./urls";

const getVerificationImage = async () => {
  try {
    const res = await api.get(GET_VERIFICATION_IMAGE_URL);
    console.log('get response',res)
    return res.data; 
  } catch (error) {
    console.error("Error fetching verification images:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};


  const createVerificationImage = async (image_url,verification_type) => {
    const res = await api.post(CREATE_VERIFICATION_IMAGE_URL, {
      image_url: image_url,
    
      verification_type:verification_type,
     
    });
    return res; // Assuming the data is in res.data
  };

  const deleteVerificationImage = async (id) => {
    try {
      const res = await api.delete(DELETE_VERIFICATION_IMAGE_URL(id));
      return res.data;
    } catch (error) {
      console.error("Error deleting verification image:", error);
      throw error;
    }
  };
  export { getVerificationImage, createVerificationImage, deleteVerificationImage};
// import { api } from "./api";
// import { GET_VERIFICATION_IMAGE_URL, CREATE_VERIFICATION_IMAGE_URL } from "./urls";

// const getVerificationImage = async () => {
//   const res = await api.get(GET_VERIFICATION_IMAGE_URL);
//   return res.data; // Assuming the data is in res.data
// };

// const createVerificationImage = async (image_url, title, description, verification_type) => {
//   const res = await api.post(CREATE_VERIFICATION_IMAGE_URL, {
//     image_url,
//     title,
//     description,
//     verification_type,
//   });
//   return res.data; // Assuming the data is in res.data
// };

// export { getVerificationImage, createVerificationImage };
