// onboardingController.js
import { postOnBoardingDetails } from './auth';
import { showToast } from '../View/Components/toast';
export const saveOnboardingDetails = async (selectedOptions, navigate, completed, setLoading, setError) => {
  if (selectedOptions.length === 0) {
    showToast('Please select at least one area of operation.', 'error');
    return;
  }

  setLoading(true);
  setError(null);
  try {
    const category = localStorage.getItem('category');
    const fetchedCompanies = JSON.parse(localStorage.getItem('fetchedCompanies'));
    const sub_category = fetchedCompanies[0]?.id || null;
    const area_of_operation = JSON.parse(localStorage.getItem('area_of_operation'));

    const payload = {
      category,
      sub_category,
      area_of_operation
    };
    console.log(payload);
    const response = await postOnBoardingDetails(payload);

    if (response.status === 200) {
      console.log('Onboarding details saved successfully:', response.data);
      navigate('/details_page', { state: { completed } });
    } else {
      // showToast('Failed to save onboarding details.', 'error');
    }
  } catch (error) {
    console.error('Error during onboarding:', error);
  } finally {
    setLoading(false);
  }
};
