import { send_otp } from './auth';
import { showToast } from '../View/Components/toast';

export const verifyOtp = async (email, otp, navigate, setIsLoading) => {
  if (!email) {
    return;
  }

  setIsLoading(true);

  try {
    const response = await send_otp(email, otp);
    console.log(response.status);
    if (response.status === 201) {
      const secret = response.data.data.secret;
      localStorage.setItem('secret', secret);
      return response;  // Return the response
    }
  } catch (err) {
    console.error('Error verifying OTP:', err);
  } finally {
    setIsLoading(false);
  }
};
