
import React, { useState } from 'react';
import CompanyHeader from '../../Components/company_header';
import FilterTable from '../../Components/trade_selection_filter_table';
import CompanyCard from "../../Components/comapny_card"
import { mockCompanies } from '../../Components/mockcompanies';
import Sidebar from '../../Components/main_navbar';
;

const MainPageSearch = ({ category = "Companies", filterCondition = () => true, hideLogo = false, hideFilterTable = false, hideSidebar = false }) => {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [isFilterTableOpen, setIsFilterTableOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const filteredCompanies = () => {
    const filtered = mockCompanies.filter(filterCondition);
    const startsWithQuery = filtered.filter(company => company.name.toLowerCase().startsWith(search.toLowerCase()));
    const includesQuery = filtered.filter(company => company.name.toLowerCase().includes(search.toLowerCase()) && !company.name.toLowerCase().startsWith(search.toLowerCase()));
    return [...startsWithQuery, ...includesQuery].filter(company => filter === '' || company.industry === filter);
  };

  const toggleFilterTable = () => {
    setIsFilterTableOpen(!isFilterTableOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const companies = filteredCompanies();

  return (
    <div className="h-full flex flex-col bg-[rgba(242,242,242,1)]">
      <CompanyHeader setSearch={setSearch} hideLogo={hideLogo} />
      <button className="md:hidden fixed top-4 left-4 z-50" onClick={toggleFilterTable}>
        <img src="./hamburger_icon.svg" alt="Filter Icon" className="h-8 w-8" />
      </button>
      <div className="flex flex-col md:flex-row w-full mt-4 md:mt-0">
        <div className="flex flex-col md:w-1/2 p-4 space-y-6 h-screen ">
          <div className="flex items-center justify-between mt-4 md:mt-0">
            <h1 className="text-left font-techna text-2xl font-semibold leading-tight text-custom-red mt-4 md:mt-16 md:ml-8">
              {category}
            </h1>
          </div>
          <div className="space-y-6 mt-4 px-4 md:px-8 flex-1 overflow-auto">
            <div className="h-full overflow-y-auto">
              {companies.length === 0 ? (
                <p>No companies found</p>
              ) : (
                companies.map(company => (
                  <CompanyCard key={company.id} company={company} />
                ))
              )}
            </div>
          </div>
        </div>
        {!hideFilterTable && (
          <div className="hidden md:block md:w-1/2 p-4">
            <FilterTable filter={filter} setFilter={setFilter} />
          </div>
        )}
      </div>
      {!hideSidebar && (
        <div className="fixed md:relative md:block w-64 p-4">
          <Sidebar />
        </div>
      )}
      {isFilterTableOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 md:hidden">
          <div className="bg-white w-64 p-4 absolute left-0 h-full overflow-auto">
            <button className="text-right mb-4" onClick={toggleFilterTable}>
              <img src="./close_icon.svg" alt="Close Icon" className="h-6 w-6" />
            </button>
            <FilterTable filter={filter} setFilter={setFilter} isMobile={true} />
          </div>
        </div>
      )}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 md:hidden">
          <div className="bg-white w-64 p-4 absolute left-0 h-full overflow-auto">
            <button className="text-right mb-4" onClick={toggleMobileMenu}>
              <img src="./close_icon.svg" alt="Close Icon" className="h-6 w-6" />
            </button>
            <Sidebar />
          </div>
        </div>
      )}
    </div>
  );
}

export default MainPageSearch;

