import React, { useState } from 'react';
import InputBox from '../../Components/input_box';
import ReportContainer from '../../Components/report';
import Sidebar from '../../Components/main_navbar';
import MapButton from '../../Components/map_button';
import Button from '../../Components/button';
import TextArea from '../../Components/text_area';  // TextArea component
import { useNavigate } from 'react-router-dom';
import { Toast, showToast } from '../../Components/toast';
import { saveCompanyDetails } from '../../../Controller/company_address';
import MapDialog from '../../Components/map';

const CompanyAddress = () => {
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false); // Track if address is selected
  const [websiteError, setWebsiteError] = useState('');
  const [custom_address, setCustomAddress] = useState('');  // State for custom address
  const [showMap, setShowMap] = useState(false);  // State to track if map dialog is open
  const navigate = useNavigate();

  // Handle when a location is selected from the map
  const handleSelectLocation = (locationDetails) => {
    const { address, latitude, longitude } = locationDetails;
    setAddress(address);
    setLatitude(latitude);
    setLongitude(longitude);
    setIsAddressSelected(true); // Enable editing for the address field
    setShowMap(false);  // Close the map dialog
  };

  // Handle manual address changes after picking from map
  const handleAddressChange = (e) => {
    setAddress(e.target.value); // Allow user to edit the address
  };

  // Handle website URL input and validation
  const handleWebsiteChange = (e) => {
    const url = e.target.value;
    setWebsite(url);

    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + 
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + 
      '((\\d{1,3}\\.){3}\\d{1,3}))' + 
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + 
      '(\\?[;&a-z\\d%_.~+=-]*)?' + 
      '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    const error = url && !urlPattern.test(url) ? 'Invalid website URL' : '';
    setWebsiteError(error);
  };

  // Handle description input changes
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // Handle custom address change
  const handleCustomAddressChange = (e) => {
    setCustomAddress(e.target.value);
  };

  // Handle saving the form
  const handleSave = async () => {
    console.log("Description:", description);
    console.log("Address:", address);
    console.log("Latitude:", latitude);
    console.log("Longitude:", longitude);
    console.log("Website:", website);
    console.log("Custom Address:", custom_address); // Logging custom address
    console.log("Website Error:", websiteError);
  
    // Validate all the fields
    if (!description || !address || !latitude || !longitude || !website || websiteError) {
      showToast('Please fill all the fields', 'error');
      return;
    }
  
    setLoading(true);
    try {
      await saveCompanyDetails(description, website, address, latitude, longitude, custom_address, navigate, setLoading); // Pass customAddress here
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] overflow-auto">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Details</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex items-center mt-1">
            <img src="Assets/filedock.svg" alt="company icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold font-poppins">Company Description</p>
          </div>
          <div className="mt-2">
            <TextArea
              placeholder="Write something about your company"
              value={description}
              onChange={handleDescriptionChange}
              maxLength={200}
            />
          </div>

          <div className="flex items-center mt-1">
            <img src="Assets/office_address.svg" alt="company icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold font-poppins">Office Location</p>
          </div>

          <div className="mt-2">
            {/* Initially, the input is disabled until the user selects a location */}
            <InputBox
    placeholder="Office Address"
    borderRadius="4px"
    value={address}
    onClick={() => setShowMap(true)}  // Open the map dialog on input box click
    readOnly={true}  // Prevent manual editing, but allow selecting from the map
  />
          </div>

          <div className="flex mt-1">
            <MapButton onAddressSelect={handleSelectLocation} padding="14px" />
          </div>

          {/* Show the map dialog for location selection */}
          {showMap && (
            <MapDialog 
              open={showMap} 
              onClose={() => setShowMap(false)} 
              onSelectLocation={handleSelectLocation} 
            />
          )}

          {/* Custom Address TextArea below "Find on Maps" */}
          <div className="mt-2 h-15">
            <TextArea
              placeholder="Enter custom address"
              value={custom_address}
              onChange={handleCustomAddressChange}
              maxLength={200}
            />
          </div>

          <div className="flex items-center mt-2">
            <img src="Assets/website.svg" alt="company icon" className="w-6 h-6 mr-2 mb-1" />
            <p className="font-semibold font-poppins">Website</p>
          </div>

          <InputBox
            placeholder="www.example.com"
            borderRadius="4px"
            value={website}
            onChange={handleWebsiteChange}
          />
          {websiteError && <p className="text-[#DB0000] text-sm mt-1">{websiteError}</p>}

          <div className="flex justify-center items-center lg:mt-8 mt-5">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAddress;
