import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { FaSpinner, FaPlus, FaEllipsisV } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import PostCard from '../../Components/post_card';
import PostDetails from '../../Components/post_details';
import { getAllPosts, getPostByCompanyUID, getPostById, deletePostById } from '../../../Controller/post_request';
import { Toast } from '../../Components/toast';

const PostList = () => {
  const [posts, setPosts] = useState([]); // Combined state for all posts
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false); // State for toggling menu visibility
  const [isMyPosts, setIsMyPosts] = useState(false); // State to track if we are viewing My Posts

  const popupRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const company_id = localStorage.getItem('company_id'); // Fetch company_uid from localStorage
  const newPostData = location.state?.newPostData || null;

  useEffect(() => {
    if (newPostData) {
      setPosts((prevPosts) => [newPostData, ...prevPosts]);
    }

    fetchPosts();
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentPage, newPostData, isMyPosts]);  // Added `isMyPosts` as dependency

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const skip = (currentPage - 1) * 5;
      
      let res;
      if (isMyPosts && company_id) {
        console.log("Fetching my posts for company ID:", company_id);
        res = await getPostByCompanyUID(company_id, skip);
      } else {
        console.log("Fetching all posts.");
        res = await getAllPosts(skip);
      }
  
      if (Array.isArray(res)) {
        console.log("Posts fetched successfully:", res);
        setPosts((prevPosts) => [...prevPosts, ...res]); // Ensure you are appending posts correctly
        setTotalPosts(res.length); // Or use res.total if your API returns total posts count
      } else {
        console.error("Unexpected API response:", res);
        setPosts([]); // Set empty posts if something goes wrong
        setTotalPosts(0);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      showToast('Failed to load posts.', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  const handleViewPost = (postId) => {
    getPostById(postId)
      .then((res) => {
        setSelectedPost(res);
        if (isMobile) {
          setShowPopup(true);
        }
      })
      .catch((error) => {
        showToast('Failed to fetch post details.', 'error');
      });
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleAddPost = () => {
    navigate('/post_request');
  };


  const handleAllPosts = () => {
    setIsMyPosts(false); // Switch back to viewing "All Posts"
    setPosts([]);        // Reset the posts to clear old data
    setCurrentPage(1);   // Reset page number to start fresh
  };
  

  const handleMyPosts = () => {
    setIsMyPosts(true);  // Indicate we are viewing "My Posts"
    setPosts([]);        // Reset the posts to clear old data
    setCurrentPage(1);   // Reset page number to start fresh
    navigate(`/posts/${company_id}`);
  };
  
  

  const toggleMenu = () => {
    setShowMenu(!showMenu); // Toggle the three-dot menu visibility
  };

  // DELETE POST FUNCTIONALITY
  const handleDeletePost = async (postId) => {
    try {
      await deletePostById(postId); // Call the delete API
      // After deletion, filter out the deleted post from the posts array
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
      setSelectedPost(null); // Clear the selected post after deletion
      showToast('Post deleted successfully!', 'success');
    } catch (error) {
      console.error('Failed to delete post:', error);
      showToast('Failed to delete post.', 'error');
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup && isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup, isMobile]);

  return (
    <div className="flex flex-col md:flex-row w-full h-full bg-[#F2F3F3] font-techna overflow-y-auto">
      <Toast />
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5 items-center justify-between">
          <h1 className="text-black font-bold text-xl">Posts</h1>

          {/* Conditionally show the three-dot menu only if not on the profile page and not viewing My Posts */}
          {!(location.pathname.includes('/profile') || isMyPosts) && (
            <div className="relative">
              <FaEllipsisV className="cursor-pointer text-black" onClick={toggleMenu} />
              {showMenu && (
                <div className="absolute right-0 mt-2 w-32 bg-white shadow-lg rounded-lg z-10">
                  <div className="cursor-pointer text-sm p-2 hover:bg-gray-200" onClick={handleMyPosts}>
                    My Posts
                  </div>
                </div>
              )}
            </div>
          )}
        </div>


        <div className="border-b border-black mt-4 md:mt-[25px] grow"></div>

        <div className="flex flex-col md:flex-row h-full">
          <div className="md:w-1/3 border-r border-gray-400">
            <div className="flex flex-col items-center p-3 md:p-9">
              {/* Hide the add button when viewing My Posts */}
              {!isMyPosts && (
                <button
                  className="bg-red-600 rounded-full w-16 h-16 flex items-center justify-center text-white shadow-lg hover:bg-red-500 transition duration-300 mb-4"
                  onClick={handleAddPost}
                >
                  <FaPlus size={24} />
                </button>
              )}

              {loading ? (
                <div className="my-4">
                  <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                </div>
              ) : (
                <>
                  {posts.length > 0 ? (
                    posts.map((post, index) => (
                      <PostCard
                        key={index}
                        post={post}
                        onView={handleViewPost}
                      />
                    ))
                  ) : (
                    <div className="text-center text-black mt-4">No Posts Available</div>
                  )}
                  {loadingMore && (
                    <div className="my-4">
                      <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                    </div>
                  )}
                  {posts.length < totalPosts && (
                    <Button
                      text="Load More"
                      backgroundColor="bg-blue-600"
                      textColor="text-white"
                      borderColor="border-blue-600"
                      onClick={handleLoadMore}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {selectedPost && (
            <div className="md:w-2/3 lg:w-1/3 flex justify-center items-center ">
              <div className="p-7 w-full max-w-md text-left">
                <PostDetails post={selectedPost} isMyPosts={isMyPosts} onPostDeleted={handleDeletePost} />
              </div>
            </div>
          )}
        </div>
      </div>

      {showPopup && isMobile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={popupRef} className="bg-white p-5 rounded-lg w-11/12 max-w-md">
            <PostDetails post={selectedPost} isMyPosts={isMyPosts} onPostDeleted={handleDeletePost} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PostList;
