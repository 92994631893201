import React from 'react';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8]">
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          
          <h1 className="text-[#171717] font-normal sm:text-lg lg:text-xl font-techna">Privacy Policy</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full overflow-y-auto">
        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Introduction</h2>
          <p className="text-[#171717] mt-2">
            At eSwaF by eMartApp, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our services. We aim to be transparent in our data practices while adhering to all applicable legal standards.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Who We Are</h2>
          <p className="text-[#171717] mt-2">
            eSwaF by eMartApp is a digital platform that provides enhanced shopping and financial services to its users. Our operations include managing digital storefronts, offering personalized recommendations, facilitating financial transactions, and delivering value-added services.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Scope of this Policy</h2>
          <p className="text-[#171717] mt-2">
            This Privacy Policy applies to all users who interact with eSwaF by eMartApp, whether through our website, mobile applications, or other platforms. By using our services, you consent to the practices described in this policy.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">What Personal Information We Collect</h2>
          <p className="text-[#171717] mt-2">
            We collect personal information that is necessary to deliver our products and services to you. The types of personal information we collect include:
          </p>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Information You Provide to Us: This includes details such as your name, email address, phone number, payment information, and preferences that you provide while setting up your account or making purchases.</li>
            <li>Automatically Collected Information: We automatically collect information regarding your interactions with our services, including IP addresses, browsing history, device information, and usage patterns. We may use cookies, pixels, tracer tags, and web beacons to gather this data.</li>
            <li>Information from Third Parties: We may also collect information from third parties, such as advertising and social media partners, security service providers, and other service providers. This could include details about your interests and behaviors that help us improve our services.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">How We Use Your Information</h2>
          <p className="text-[#171717] mt-2">
            We use the personal information we collect for the following purposes:
          </p>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Service Delivery and Account Management: To process your transactions, manage your account, deliver products and services, and provide customer support.</li>
            <li>Marketing and Personalization: To offer relevant promotions, personalized content, and targeted advertisements based on your interests and preferences.</li>
            <li>Security and Fraud Prevention: To protect against unauthorized access, monitor for fraud, and manage security risks.</li>
            <li>Compliance and Legal Requirements: To comply with legal obligations, respond to regulatory requirements, and enforce our terms and policies.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Sharing Your Information</h2>
          <p className="text-[#171717] mt-2">
            We do not sell or rent your personal information to third parties. However, we may share your data in the following scenarios:
          </p>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Within eSwaF by eMartApp: To deliver a seamless experience, your data may be shared across our internal departments and associated entities.</li>
            <li>With Service Providers: We share your information with trusted service providers who assist in delivering our services, such as payment processors, IT providers, and marketing platforms. These providers are required to protect your data and use it only for the specified purposes.</li>
            <li>For Legal and Regulatory Purposes: We may disclose your information if required by law or if we believe such action is necessary to protect our rights or comply with a legal obligation.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Data Transfers</h2>
          <p className="text-[#171717] mt-2">
            Some of our service providers may be located outside your country of residence. When transferring your data across borders, we ensure that it is handled with the same level of protection required by applicable laws.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Your Choices and Rights</h2>
          <p className="text-[#171717] mt-2">
            You have control over the personal information you share with us. You can:
          </p>
          <ul className="text-[#171717] mt-2 list-disc list-inside">
            <li>Opt-Out of Marketing Communications: You can unsubscribe from marketing communications at any time by following the instructions provided in the communication or contacting us directly.</li>
            <li>Access and Update Your Information: You may access and update your personal information by logging into your account or contacting our customer service team.</li>
            <li>Withdraw Consent: If you no longer wish to allow us to collect or process your information, you may withdraw your consent at any time. However, this may limit the services we can provide.</li>
          </ul>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Data Retention</h2>
          <p className="text-[#171717] mt-2">
            We retain your personal information only as long as necessary for the purposes outlined in this policy, or as required by law. When your data is no longer needed, we securely delete or anonymize it.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Protecting Your Information</h2>
          <p className="text-[#171717] mt-2">
            We use appropriate security measures, such as data encryption and access controls, to safeguard your personal information. However, no system is entirely secure, and we cannot guarantee the absolute security of your data.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px] h-full">
          <h2 className="text-black font-bold text-lg">Changes to This Policy</h2>
          <p className="text-[#171717] mt-2">
            We may update this Privacy Policy from time to time. Any changes will be posted on our website, and we encourage you to review the policy periodically. Your continued use of our services following any changes indicates your acceptance of the updated policy.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[600px]">
          <h2 className="text-black font-bold text-lg">Contact Us</h2>
          <p className="text-[#171717] mt-2">
            If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise your privacy rights, please contact us at: Communication & Legal Department, at <a href="mailto:support@emartapp.com" className="text-blue-500">support@emartapp.com</a> or <a href="mailto:Email@eSwaF.com" className="text-blue-500">Email@eSwaF.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
