import React from 'react';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8]">
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-full max-w-[800px] mx-auto">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-normal sm:text-lg lg:text-xl font-techna">About Us</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full overflow-y-auto">
        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[800px]">
          <h2 className="text-black font-bold text-lg">About eSwaF by eMartApp</h2>
          <p className="text-[#171717] mt-2">
            "eSwaF by eMartApp is a business-focused social media platform powered by advanced research and analytics, designed to enhance user experiences and streamline business operations within complex online ecosystems. Supporting businesses across diverse industries, it seamlessly integrates networking, commerce, and service facilitation into one unified solution—Business Connect, Limitless."
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[800px]">
          <h2 className="text-black font-bold text-lg">Platform Core Features</h2>
          <p className="text-[#171717] mt-2">
            <strong>1. Ecosystem Support: Holistic Business Environment</strong><br />
            eSwaF by eMartApp is more than just a platform—it’s a full ecosystem tailored to understand and support the unique challenges of diverse businesses. Whether you're in manufacturing, logistics, startups, or finance, our ecosystem adapts to your operational needs, helping you position your brand optimally in the market.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>2. Disruption-Free Engagement: No Irrelevant Ads</strong><br />
            In an age where attention spans are shorter than ever, traditional ads are often skipped and ignored, rendering them ineffective. eSwaF by eMartApp takes a unique approach by focusing on organic content placement and exposure. We avoid irrelevant ads, allowing businesses to reach their audience more authentically and maintain user engagement without the clutter of intrusive advertisements.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>3. Comprehensive Industry Coverage: Seamless Integration Across Sectors</strong><br />
            Our platform connects the dots across the entire business spectrum. From procurement and manufacturing to logistics, distribution, and even specialized sectors like construction projects and financial institutions, eSwaF brings comprehensive support under one roof.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>4. Local and Global Expansion: Unlock New Markets</strong><br />
            eSwaF empowers businesses to expand locally and globally by offering the resources and connections necessary to thrive in new regions.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>5. Limitless Business Connections: Network Effect with Purpose</strong><br />
            Business Connect, Limitless—this isn’t just a tagline; it’s at the heart of what we do. We make it easier for businesses to connect, collaborate, and grow together, enabling limitless possibilities for partnerships, joint ventures, and shared success.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>6. Everything. Electronically.</strong><br />
            Our philosophy and guiding principle is simple: Everything Electronically. Whether it’s communication, collaboration, transactions, or service delivery, eSwaF digitizes every aspect of your business process to maximize efficiency and minimize manual tasks.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>7. Algorithm-Driven Optimization: Insights that Matter</strong><br />
            At the core of eSwaF lies a robust algorithm powered by advanced research and analytics. Our algorithm ensures your business content reaches the most relevant audiences, prioritizes high-quality engagements, and optimizes your operations in real-time.
          </p>
        </div>

        <div className="bg-[#D6D6D6] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[800px]">
          <h2 className="text-black font-bold text-lg">Our Approach</h2>
          <p className="text-[#171717] mt-2">
            <strong>Strict Verification:</strong> We prioritize accuracy and reliability by rigorously verifying companies in our ecosystem. Through custom algorithms, we organically track and rate company performance over time.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Organic Rankings:</strong> Unlike platforms where rankings can be influenced by paid promotions, eSwaF maintains integrity by ranking companies solely based on their verified performance.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Customer Trust:</strong> eSwaF empowers businesses to earn trust organically. While we provide a trusted seal, the real work of maintaining and improving ratings lies with the business itself.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
