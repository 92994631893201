import React, { useState, useEffect } from 'react';
import { getAllMedia } from '../../Controller/company_products'; // Assuming you have this API function
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const Explore = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false); // For loading spinner
  const [hasMore, setHasMore] = useState(true); // To know if there are more items to load
  const limit = 10; // Limit to 10 items per load
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Function to fetch media data from the API
  const fetchMediaItems = async (skip, limit) => {
    try {
      setLoading(true);
      const res = await getAllMedia(skip, limit);
      console.log('get explore res', res);
      if (res.status === 200 && res.data.data) {
        const newItems = res.data.data.map((media) => {
          // Check if the image_url is an array and use the first element if available
          const imageUrl = Array.isArray(media.image_url) && media.image_url.length > 0 ? media.image_url[0] : 'placeholder-image-url';
          
          return {
            id: media.id, // Add ID for navigation
            imageUrl: imageUrl, // Accessing the first image in the array dynamically
            description: media.description ? media.description.substring(0, 20) + (media.description.length > 20 ? '...' : '') : 'No description available', // Fallback if no description
          };
        });

        // Append new items to the current list of items
        setItems((prevItems) => [...prevItems, ...newItems]);

        // If fewer than limit items are returned or the array is empty, no more items to load
        if (newItems.length < limit || newItems.length === 0) {
          setHasMore(false);
        }
      } else {
        // In case of error or empty response
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching media items:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the data on initial render
  useEffect(() => {
    fetchMediaItems(0, limit); // Initial load
  }, []);

  // Load more items when the button is clicked
  const loadMore = () => {
    const newSkip = items.length; // Use the length of current items as the new skip value
    fetchMediaItems(newSkip, limit);
  };

  // Function to handle image click and navigate to the item's detail page
  const handleImageClick = (id) => {
    navigate(`/detailed_view/${id}`); // Navigate to the detail page for the media item with the given ID
  };

  return (
    <div className="w-full lg:pr-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {/* Left Column */}
        <div className="flex flex-col gap-4">
          {/* First Row */}
          <div className="flex gap-4">
            {items.slice(0, 2).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '180px' }}
                onClick={() => handleImageClick(item.id)} // Navigate to detail page on click
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
          {/* Second Row */}
          <div className="flex gap-4">
            {items.slice(2, 5).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '240px' }}
                onClick={() => handleImageClick(item.id)} // Navigate to detail page on click
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Right Column */}
        <div className="flex flex-col gap-4">
          {/* First Row */}
          <div className="flex gap-4">
            {items.slice(5, 8).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '240px' }}
                onClick={() => handleImageClick(item.id)} // Navigate to detail page on click
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
          {/* Second Row */}
          <div className="flex gap-4">
            {items.slice(8, 10).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '180px' }}
                onClick={() => handleImageClick(item.id)} // Navigate to detail page on click
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Load More Button */}
      {hasMore && (
        <div className="flex justify-center mt-6">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={loadMore}
            >
              Load More
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Explore;
