import React from 'react';

const VideoCard = ({ videoId, thumbnail, onClick }) => {
  return (
    <div className="rounded-lg w-full h-full p-2 cursor-pointer" onClick={onClick}>
      <img
        src={thumbnail}
        alt="Thumbnail"
        className="w-full h-full max-h-64 object-cover rounded-lg"
      />
      <div className="absolute bottom-2 left-2 flex items-center space-x-4">
        <img src="./play.svg" alt="Play" className="h-3 w-3" />
        <div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
