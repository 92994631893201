// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import FilePicker from '../../Components/file_picker';
// import { useNavigate } from 'react-router-dom';
// import Button from '../../Components/button';
// import ReportContainer from '../../Components/report';
// import { Toast } from '../../Components/toast';
// import { saveCompanyDocuments } from '../../../Controller/company_document';
// import { useEffect } from 'react';

// const CompanyDocsView = () => {
//   const navigate = useNavigate();
//   const [documents, setDocuments] = useState({
//     'Trading License': '',
//     'Trading Register': '',
//     'Tenancy Contract': '',
//   });
//   const [loading, setLoading] = useState(false);
//   const [documentUrls, setDocumentUrls] = useState({
//     'Trading License': '',
//     'Trading Register': '',
//     'Tenancy Contract': '',
//   });

//   // Retrieve document URLs from localStorage
//   useEffect(() => {
//     setDocumentUrls({
//       'Trading License': localStorage.getItem('trading_license') || '',
//       'Trading Register': localStorage.getItem('trading_register') || '',
//       'Tenancy Contract': localStorage.getItem('tenancy_contract') || '',
//     });
//   }, []);

//   const handleFileChange = (label, url) => {
//     setDocuments(prevState => ({
//       ...prevState,
//       [label]: url
//     }));
//   };

//   const handleSave = async () => {
//     setLoading(true);
//     await saveCompanyDocuments(documents, navigate, setLoading);
//     setLoading(false);
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
//       <Sidebar />
//       <Toast />
//       <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Documents</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <div className="flex flex-col font-semibold font-poppins">
//             {Object.keys(documents).map((label, index) => (
//               <div key={index} className="mb-4">
//                 <p className="mb-2 text-black font-semibold">{label}</p>
//                 <div className="flex justify-between items-center p-10 bg-gray-200 rounded-md">
//                   <div className="flex items-center">
//                     <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-2" />
//                     <span className="text-black font-semibold">Document</span>
//                   </div>
//                   {documentUrls[label] && (
//                     <a href={documentUrls[label]} target="_blank" rel="noopener noreferrer" className="text-red-600 underline">
//                       View
//                     </a>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="flex justify-center items-center mt-10">
//             {/* <Button
//               text="Save"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSave}
//               loading={loading}
//             /> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDocsView;
import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main_navbar';
import FilePicker from '../../Components/file_picker';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/button';
import ReportContainer from '../../Components/report';
import { Toast } from '../../Components/toast';
import { get_documents_api } from '../../../Controller/company_details';
// Import the get_documents_api function


const CompanyDocsView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [documentUrls, setDocumentUrls] = useState({
    'Trading License': '',
    'Trading Register': '',
    'Tenancy Contract': '',
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const companyId = localStorage.getItem('company_id');
        if (!companyId) {
          console.error("No company ID found in localStorage.");
          setLoading(false);
          return;
        }

        const res = await get_documents_api(companyId);
        console.log("Documents API Response:", res.data); // Log API response

        const fetchedDocuments = res.data.data || {};

        // Map the API response keys to the UI keys
        setDocumentUrls({
          'Trading License': fetchedDocuments.trading_license || '',
          'Trading Register': fetchedDocuments.trading_register || '',
          'Tenancy Contract': fetchedDocuments.tenancy_contract || '',
        });

        // console.log("Mapped Document URLs:", {
        //   'Trading License': fetchedDocuments.trading_license,
        //   'Trading Register': fetchedDocuments.trading_register,
        //   'Tenancy Contract': fetchedDocuments.tenancy_contract,
        // });

      } catch (error) {
        console.error("Error fetching document details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Documents</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
      <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex flex-col font-semibold font-poppins">
            {Object.keys(documentUrls).map((label, index) => (
              <div key={index} className="mb-4">
                <p className="mb-2 text-black font-semibold">{label}</p>
                <div className="flex justify-between items-center p-8 bg-gray-200 rounded-md">
                  <div className="flex items-center">
                    <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-2" />
                    <span className="text-black font-semibold">Document</span>
                  </div>
                  {documentUrls[label] ? (
                    <a href={documentUrls[label]} target="_blank" rel="noopener noreferrer" className="text-red-600 underline">
                      View
                    </a>
                  ) : (
                    <span className="text-gray-500"></span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDocsView;