import React from 'react';

const CompanyCard = ({ company }) => {
  const starImage = './star1.svg'; // Update this with the correct path to your star image

  return (
    <div className="flex flex-row w-full bg-white shadow-lg mt-7 rounded-lg ">
      <div className="flex items-center justify-center p-0 md:p-6 bg-red-700 w-1/3">
        {company.logo ? (
          <img src={company.logo} alt={company.name} className="h-20 w-20 rounded-full" />
        ) : (
          <div className="h-20 w-20 bg-gray-300 rounded-full flex items-center justify-center text-white">
            {company.name.charAt(0)}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center p-4 md:p-6 bg-white w-2/3">
        <h3 className="mb-2 text-left text-lg font-normal leading-tight text-gray-800">
          {company.name}
        </h3>
        <p className="text-sm mb-2 font-bold text-gray-800">{company.location || 'Location not provided'}</p>
        <div className="flex mt-2">
          {[...Array(5)].map((_, index) => (
            <img
              key={index}
              src={starImage}
              alt="star"
              className={`h-5 w-5 ${index < company.rating ? '' : 'opacity-50'}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompanyCard;
