import React, { useState, useEffect } from 'react';
import CenteredImageContainer from '../../Components/black_container';
import MultiSelect from '../../Components/multi_select';
import Button from '../../Components/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from '../../Components/toast';
import ReportContainer from '../../Components/report';
import { saveOnboardingDetails } from '../../../Controller/area_of_operation_controller';
const MainAreaOfOperation = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { completed } = location.state || { completed: [] };

  useEffect(() => {
    console.log('completed:', completed);
  }, [completed]);

  const handleOptionChange = (options) => {
    setSelectedOptions(options);
    localStorage.setItem('area_of_operation', JSON.stringify(options));
    console.log('Updated area of operation:', options);
    console.log('Saved area of operation to localStorage:', JSON.stringify(options));
  };

  const handleButtonClick = async () => {
    saveOnboardingDetails(selectedOptions, navigate, completed, setLoading, setError); // Use the controller function
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/area_of_op.svg" />
        </div>
      </div>
      
      <div className="absolute top-5 right-6">
        <ReportContainer />
      </div>
      
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
        <div className="p-6 w-full max-w-2/5">
          <h1 className="text-2xl font-techna text-[#171717] mb-4">Area of Operation</h1>
          <p className="text-gray-500 mb-8">You can select both or one</p>
          <MultiSelect onChange={handleOptionChange} selectedOptions={selectedOptions} />
          <div className="w-full mt-8 flex justify-center">
            <Button 
              text="Save" 
              onClick={handleButtonClick} 
              backgroundColor="bg-[#DB0000]" 
              textColor="text-white" 
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAreaOfOperation;
