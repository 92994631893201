// import React, { useState } from 'react';
// import CoverImagePopup from './cover_image_upload_popup';
// import uploadImage from '../../Controller/firebase/firebase_upload_image';

// const ProfileImageContainer = ({ editable, profileImage, onSaveProfileImage }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [uploading, setUploading] = useState(false); // To track upload status

//   const handleSaveProfileImage = async (newImageFile) => {
//     try {
//       setUploading(true);
//       const downloadURL = await uploadImage(newImageFile); 
//       setUploading(false);
//       console.log("Uploaded image, download URL:", downloadURL);
//       onSaveProfileImage(downloadURL); 
//     } catch (error) {
//       setUploading(false);
//       console.error("Error uploading image:", error);
//     }
//   };

//   return (
//     <div className="relative mb-16 w-28 h-28 md:w-38 md:h-38 bg-gray-200 rounded-full flex items-center justify-center -mt-12 md:-mt-20">
//       {profileImage ? (
//         <img src={profileImage} alt="Profile" className="w-24 h-24 md:w-28 md:h-28 rounded-full object-cover" />
//       ) : null}
//       {editable && (
//         <div
//           className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-white p-1 rounded-full cursor-pointer"
//           onClick={() => setShowPopup(true)}
//         >
//           <img src="./pen.svg" alt="Edit" className="w-8 h-8" />
//         </div>
//       )}
//       {uploading && <p>Uploading...</p>} {/* Show uploading status */}
//       {showPopup && (
//         <CoverImagePopup
//           onClose={() => setShowPopup(false)}
//           onSave={handleSaveProfileImage} // Pass the new image file to the save handler
//           isEditing={!!profileImage}
//           initialImage={profileImage}
//           title="Logo"
//           dimensionNote="Image size: 100 * 100"
//           isLogo={true}
//         />
//       )}
//     </div>
//   );
// };

// export default ProfileImageContainer;
import React, { useState } from 'react';
import CoverImagePopup from './cover_image_upload_popup'; 

const ProfileImageContainer = ({ editable, profileImage, onSaveProfileImage }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleSaveNewImage = async (newImage) => {
    onSaveProfileImage(newImage); 
    setShowPopup(false); 
  };

  return (
    <div className="relative  w-25 h-25 md:w-34 md:h-34 rounded-full flex items-center justify-center mb-3 ">
    {profileImage ? (
      <img
        src={profileImage}
        alt="Profile"
        className="w-24 h-24 md:w-28 md:h-28 rounded-full object-cover"
      />
    ) : null}
  
    {editable && (
      <div
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 p-1 rounded-full cursor-pointer"
        onClick={() => setShowPopup(true)}
      >
        <img src="/Assets/edit_icon.svg" alt="Edit" className="w-8 h-8" />
      </div>
    )}
  
    {showPopup && (
      <CoverImagePopup
        onClose={() => setShowPopup(false)}
        onSave={handleSaveNewImage}
        title="Logo"
        dimensionNote="Optimal dimensions: 150x150px"
        isLogo
      />
    )}
  </div>
  
  );
};

export default ProfileImageContainer;