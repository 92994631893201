import { GET_SUBSCRIPTIONS } from "./urls";
import { api } from "./api";
import { POST_USER_PAYMENTS } from "./urls";


const get_subscriptions_api = async () => {
    try {
      const category = localStorage.getItem('category'); 
      const res = await api.get(GET_SUBSCRIPTIONS);
      return res;
    } catch (error) {
      console.error("Error fetching SUBSCRIPTIONS:", error);
      throw error;  
    }
  };



  
const post_user_payments_api = async (payload) => {
  try {
    const res = await api.post(POST_USER_PAYMENTS,payload);
    return res;
  } catch (error) {
    console.error("Error fetching SUBSCRIPTIONS:", error);
    throw error;  
  }
};

export { get_subscriptions_api,post_user_payments_api}