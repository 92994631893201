// src/mockCompanies.js

export const mockCompanies = [
    { id: 1, name: 'Roland Garros', industry: 'Logistics', location: 'Kerala, India', rating: 4, logo: 'ronald.svg' },
    { id: 2, name: 'Good Wood', industry: 'Hand Craft', location: 'Kerala, India', rating: 5, logo: 'good_wood.svg' },
    { id: 3, name: 'Handicraft', industry: 'Hand Craft', location: 'Rajasthan, India', rating: 3, logo: 'ronald.svg' },
    { id: 4, name: 'Wood & Woods', industry: 'Logistics', location: 'Kerala, India', rating: 4, logo: 'ronald.svg' },
    { id: 5, name: 'Gem Traders', industry: 'PreciousStonesMetals', location: 'Mumbai, India', rating: 5, logo: 'ronald.svg' },
    { id: 6, name: 'Fin Corp', industry: 'Financial Institutions', location: 'Delhi, India', rating: 4, logo: 'ronald.svg' },
    { id: 7, name: 'StartUp Hub', industry: 'Start-Up', location: 'Bangalore, India', rating: 4, logo: 'ronald.svg' },
    { id: 8, name: 'Gold Diggers', industry: 'Precious Stones Metals', location: 'Goa, India', rating: 5, logo: 'ronald.svg' },
    { id: 9, name: 'Market Experts', industry: 'Marketplace', location: 'Chennai, India', rating: 3, logo: 'ronald.svg' },
    { id: 10, name: 'Project Planners', industry: 'Projects', location: 'Pune, India', rating: 4, logo: 'ronald.svg' },
    { id: 11, name: 'Investment Bankers', industry: 'Investor', location: 'Mumbai, India', rating: 4, logo: 'ronald.svg' },
    { id: 1, name: 'Roland Garros', industry: 'Legal', location: 'Kerala, India', rating: 4, logo: 'ronald.svg' },

    // Add more companies for each category as needed
  ];
  