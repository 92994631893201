import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main_navbar';
import ComplaintCard from '../../Components/complaint_card';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import Tab from '../../Components/tab';
import InputBox from '../../Components/input_box';
import AddDocument from '../../Components/add_doc';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import { getComplaintsAddedMyself, postReply, deleteReply, deleteComplaintById, getComplaintById } from '../../../Controller/complaints';
import { FaSpinner, FaEdit, FaTrash, FaTimes } from 'react-icons/fa';
import { Toast } from '../../Components/toast';
import { showToast } from '../../Components/toast';
import DeletePopup from '../../Components/delete_pop_up';
import EditReplyPopup from '../../Components/edit_reply_popup';
import ReviewImageUpload from '../../Components/review_image_add';
import LoaderSpinner from '../../Components/loader_spinner';
import { getComplaintsAgainstCompany } from '../../../Controller/complaints';

const Complaints = ({ owner }) => {
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [reply, setReply] = useState("");
    const [replies, setReplies] = useState([]);
    const [activeTab, setActiveTab] = useState('submitted');
    const [showForm, setShowForm] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileDetail, setShowMobileDetail] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [receivedComplaints, setReceivedComplaints] = useState([]);
    const [submittedComplaints, setSubmittedComplaints] = useState([]);
    const [canReply, setCanReply] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEditingReply, setIsEditingReply] = useState(false);
    const [editingReplyIndex, setEditingReplyIndex] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editReplyPopupVisible, setEditReplyPopupVisible] = useState(false);
    const [replyToEdit, setReplyToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [productName, setProductName] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [reviewImages, setReviewImages] = useState([]);
    const [existingReply, setExistingReply] = useState(null);

    const navigate = useNavigate();
    const company_uid = localStorage.getItem('company_uid');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchComplaints = async () => {
            setLoading(true);
            try {
                if (activeTab === 'submitted') {
                    const response = await getComplaintsAddedMyself(0, 5);
                    if (response && response.data && response.data.data) {
                        const fetchedComplaints = response.data.data.map(complaint => ({
                            ...complaint,
                            id: complaint.id,
                            complaint_by_name: complaint.complaint_to_name,
                            complaint_by_profile_image: complaint.complaint_to_profile_image || '/profile.webp',
                            created_on: new Date(complaint.created_on).toLocaleDateString()
                        }));
                        setSubmittedComplaints(fetchedComplaints);
                        showToast('Submitted complaints loaded successfully!', 'success');
                    } else {
                        setSubmittedComplaints([]);
                        showToast('No submitted complaints found.', 'default');
                    }
                } else if (activeTab === 'received') {
                    const response = await getComplaintsAgainstCompany(company_uid, 0, 5);
                    if (response && response.data && response.data.data) {
                        const fetchedComplaints = response.data.data.map(complaint => ({
                            ...complaint,
                            id: complaint.id,
                            complaint_by_name: complaint.complaint_by_name,
                            complaint_by_profile_image: complaint.complaint_by_profile_image || '/profile.webp',
                            created_on: new Date(complaint.created_on).toLocaleDateString()
                        }));
                        setReceivedComplaints(fetchedComplaints);
                        showToast('Received complaints loaded successfully!', 'success');
                    } else {
                        setReceivedComplaints([]);
                        showToast('No received complaints found.', 'default');
                    }
                }
            } catch (error) {
                console.error("Error fetching complaints:", error);
                showToast('Failed to load complaints.', 'error');
                if (activeTab === 'submitted') setSubmittedComplaints([]);
                if (activeTab === 'received') setReceivedComplaints([]);
            } finally {
                setLoading(false);
            }
        };

        fetchComplaints();
    }, [activeTab, company_uid]);

    const handleViewComplaint = async (complaint) => {
        if (selectedComplaint && selectedComplaint.id === complaint.id) {
            return;
        }

        try {
            const response = await getComplaintById(complaint.id);
            if (response.data && response.data.data.length > 0) {
                const complaintData = response.data.data[0];
                setSelectedComplaint({
                    ...complaintData,
                    id: complaintData.id,
                    complaint_by_name: complaintData.complaint_by_name || 'Name',
                    complaint_by_profile_image: complaintData.complaint_by_profile_image || '/profile.webp',
                    created_on: new Date(complaintData.created_on).toLocaleDateString(),
                    reply: complaintData.reply || null,
                    reply_name: complaintData.reply_name || null,
                    reply_profile_image: complaintData.reply_profile_image || null,
                    replied_on: complaintData.replied_on || null
                });

                setCanReply(!complaintData.reply);
                if (isMobile) {
                    setShowPopup(true);
                }
            }
        } catch (error) {
            console.error("Error fetching complaint by ID:", error);
        }
    };

    const handleSendReply = async () => {
        if (reply.trim() !== "" && selectedComplaint) {
            try {
                const newReply = {
                    text: reply,
                    time: new Date().toLocaleTimeString(),
                    date: new Date().toLocaleDateString(),
                };

                const response = await postReply(selectedComplaint.id, newReply.text);
                if (response && response.status === 201) {
                    setExistingReply(newReply);
                    setReply("");
                    setCanReply(false);
                    showToast('Reply sent successfully!', 'success');

                    setSelectedComplaint(prev => ({
                        ...prev,
                        reply: newReply.text,
                        replied_on: new Date().toLocaleString(),
                        reply_name: 'Your Name',
                        reply_profile_image: '/profile.webp'
                    }));
                } else {
                    showToast('Failed to send reply.', 'error');
                }
            } catch (error) {
                console.error("Error posting reply:", error);
                showToast('Failed to send reply.', 'error');
            }
        }
    };

    const handleDeleteComplaint = async (complaint) => {
        try {
            const response = await deleteComplaintById(complaint.id);
            if (response.status === 202) {
                setSubmittedComplaints(submittedComplaints.filter(c => c.id !== complaint.id));
                showToast('Complaint deleted successfully!', 'success');
            }
        } catch (error) {
            console.error("Error deleting complaint:", error);
            showToast('Failed to delete complaint.', 'error');
        }
    };

    const handleEditComplaint = (complaint) => {
        setSelectedComplaint(complaint);
        setProductName(complaint.product_name || '');
        setTitle(complaint.title || '');
        setDescription(complaint.description || '');
        setReviewImages(complaint.image_url || []);
        setShowForm(true);
    };

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const confirmDeleteReply = async () => {
        try {
            const response = await deleteReply(selectedComplaint.id, replies[deleteIndex].text);
            if (response && response.status === 201) {
                const updatedReplies = replies.filter((_, i) => i !== deleteIndex);
                setReplies(updatedReplies);
                setShowDeletePopup(false);
                setCanReply(true);
                showToast('Reply deleted successfully!', 'success');
            } else {
                showToast('Failed to delete reply.', 'error');
            }
        } catch (error) {
            console.error("Error deleting reply:", error);
            showToast('Failed to delete reply.', 'error');
        }
    };

    const cancelDelete = () => {
        setShowDeletePopup(false);
    };

    const handleEditReplySubmit = (updatedReplyText) => {
        replies[editingReplyIndex].text = updatedReplyText;
        setReplies([...replies]);
        setEditReplyPopupVisible(false);
        showToast('Reply updated successfully!', 'success');
    };

    const tabLabels = {
        received: 'Complaints Received',
        submitted: 'Complaints Submitted'
    };

    const displayedComplaints = activeTab === 'received' ? receivedComplaints : submittedComplaints;

    return (
        <div className="flex flex-col md:flex-row w-full h-full bg-[#FFFFFF] font-techna overflow-y-auto">
            <Sidebar />
            <div className="flex flex-col w-full">
                <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
                    <div className="w-full flex justify-start items-center space-x-2">
                        <h1 className="text-black font-semibold font-poppins text-xl">Complaints</h1>
                    </div>
                    <ReportContainer />
                </div>
                <div className="border-b border-black mt-4 md:mt-[25px]"></div>
                <div className="flex flex-col md:flex-row h-full">
                    <div className={`${isMobile ? 'w-full' : 'md:w-1/3'} border-r border-gray-400`}>
                        <Tab activeTab={activeTab} setActiveTab={setActiveTab} labels={tabLabels} />
                        <div className="flex flex-col items-center p-3 md:p-9">
                            {loading ? (
                                <FaSpinner className="animate-spin text-red-600" size={24} />
                            ) : (
                                <>
                                    {displayedComplaints.length > 0 ? (
                                        displayedComplaints.map((complaint, index) => {
                                            return (
                                                <ComplaintCard
                                                    key={index}
                                                    complaint={complaint}
                                                    onView={handleViewComplaint}
                                                    onDelete={() => handleDeleteComplaint(complaint)}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div className="text-center text-black mt-4">No Complaints Found</div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {(!isMobile || (isMobile && showMobileDetail)) && (
                        <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-7`}>
                            {isMobile && (
                                <button
                                    onClick={() => setShowMobileDetail(false)}
                                    className="absolute top-2 right-2 text-2xl"
                                >
                                    &times;
                                </button>
                            )}
                            {selectedComplaint && (
                                <>
                                    <div className="bg-[#E1E1E1] rounded-lg p-4 shadow-md w-full mb-4">
                                        <div className="flex">
                                            <img
                                                src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
                                                alt="Profile"
                                                className="rounded-full w-12 h-12 mr-4"
                                            />
                                            <div className="flex flex-col">
                                                <div className="font-bold">{selectedComplaint.complaint_by_name || 'Name'}</div>
                                                <div className="text-gray-500 text-sm">{selectedComplaint.created_on}</div>
                                            </div>
                                            {activeTab === 'submitted' && (
                                                <div className="ml-auto flex items-center space-x-2">
                                                    <button
                                                        className="bg-gray-300 text-gray-600 hover:bg-gray-400 p-2 rounded"
                                                        onClick={() => handleEditComplaint(selectedComplaint)}
                                                    >
                                                        <FaEdit />
                                                    </button>
                                                    <button
                                                        className="bg-gray-300 text-gray-600 hover:bg-gray-400 p-2 rounded"
                                                        onClick={() => handleDeleteComplaint(selectedComplaint)}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-4">
                                            <div className="font-bold text-sm">{selectedComplaint.title || 'Complaint Title/Subject'}</div>
                                            <div className="text-gray-600 font-bold mt-2 text-sm">{selectedComplaint.product_name || 'Product Name'}</div>
                                            <div className="text-sm">{selectedComplaint.description || 'Description not provided'}</div>
                                            {selectedComplaint.image_url && selectedComplaint.image_url.length > 0 && (
                                                <img
                                                    src={selectedComplaint.image_url[0]}
                                                    alt="Complaint"
                                                    className="w-16 h-16 rounded-lg mt-4"
                                                />
                                            )}
                                            {selectedComplaint.confirmation_document && (
                                                <div className="text-gray-500 text-sm mt-4">
                                                    Attached Document: {selectedComplaint.confirmation_document}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {selectedComplaint.reply ? (
                                        <div className="mt-4 bg-[#E1E1E1] p-4 rounded-lg shadow-md">
                                            <h2 className="font-bold text-lg mb-2">Reply</h2>
                                            <div className="flex">
                                                {selectedComplaint.reply_profile_image && (
                                                    <img
                                                        src={selectedComplaint.reply_profile_image}
                                                        alt="Profile"
                                                        className="rounded-full w-12 h-12 mr-4"
                                                    />
                                                )}
                                                <div>
                                                    <div className="font-bold">{selectedComplaint.reply_name || 'Responder'}</div>
                                                    <div>{selectedComplaint.reply}</div>
                                                    <div className="text-gray-500 text-sm">
                                                        {new Date(selectedComplaint.replied_on).toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        owner && canReply && (
                                            <div className="flex flex-col mt-2">
                                                <div className="text-lg font-bold mb-2">Reply</div>
                                                <TextArea
                                                    value={reply}
                                                    onChange={handleReplyChange}
                                                    placeholder="Reply"
                                                    height="20px"
                                                />
                                                <div className="w-40 self-end">
                                                    <Button
                                                        text="Send"
                                                        backgroundColor="bg-red-600"
                                                        textColor="text-white"
                                                        borderColor="border-red-600"
                                                        onClick={handleSendReply}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Toast />
            {showDeletePopup && (
                <DeletePopup
                    message="Are you sure you want to delete this reply?"
                    onConfirm={confirmDeleteReply}
                    onClose={cancelDelete}
                />
            )}
            {editReplyPopupVisible && (
                <EditReplyPopup
                    reply={replyToEdit}
                    onSubmit={handleEditReplySubmit}
                    onClose={() => setEditReplyPopupVisible(false)}
                />
            )}
            {isMobile && showPopup && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg p-6 w-11/12 md:w-1/2">
                        <button onClick={() => setShowPopup(false)} className="text-gray-700 float-right">
                            <FaTimes />
                        </button>
                        {selectedComplaint && (
                            <>
                                <div className="flex">
                                    <img
                                        src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
                                        alt="Profile"
                                        className="rounded-full w-12 h-12 mr-4"
                                    />
                                    <div className="flex flex-col">
                                        <div className="font-bold">{selectedComplaint.complaint_by_name || 'Name not available'}</div>
                                        <div className="text-gray-500 text-sm">{selectedComplaint.created_on}</div>
                                    </div>
                                </div>
                                <h2 className="text-lg font-bold mb-2 mt-4">{selectedComplaint.title || 'Complaint Title/Subject'}</h2>
                                <div className="text-gray-600 mb-4">
                                    {selectedComplaint.product_name || 'Product Name'}
                                </div>
                                <p>{selectedComplaint.description || 'Description not provided'}</p>
                                {selectedComplaint.image_url && selectedComplaint.image_url.length > 0 && (
                                    <img
                                        src={selectedComplaint.image_url[0]}
                                        alt="Complaint"
                                        className="w-16 h-16 rounded-lg mt-4"
                                    />
                                )}
                                {selectedComplaint.confirmation_document && (
                                    <div className="text-gray-500 text-sm mt-4">
                                        Attached Document: {selectedComplaint.confirmation_document}
                                    </div>
                                )}
                                {owner && canReply && (
                                    <div className="flex flex-col mt-6">
                                        <div className="text-lg font-bold mb-2">Reply</div>
                                        <TextArea
                                            value={reply}
                                            onChange={handleReplyChange}
                                            placeholder="Reply"
                                            height="20px"
                                        />
                                        <div className="w-40 self-end mt-4">
                                            <Button
                                                text="Send"
                                                backgroundColor="bg-red-600"
                                                textColor="text-white"
                                                borderColor="border-red-600"
                                                onClick={handleSendReply}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Complaints;
