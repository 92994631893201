import React from 'react';

const ChatList = ({ messages, users, onSelectMessage, selectedMessageId, currentUser }) => {
  if (!Array.isArray(users) || !Array.isArray(messages) || !currentUser) {
    return null;
  }

  const filteredMessages = messages.filter(msg => msg.participants.includes(currentUser.id));

  return (
    <div>
      {filteredMessages.length === 0 ? (
        <p>No chats available</p>
      ) : (
        filteredMessages.map((message) => {
          const userId = message.participants.find(participant => participant !== currentUser.id);
          const user = users.find(u => u.id === userId);

          if (!user) {
            console.log(`No user found for userId: ${userId}`);
            return null;
          }

          const isImportant = currentUser.id === message.senderUid
            ? message.isImportantForSender
            : message.isImportantForReceiver;

          return (
            <div
              key={user.id}
              onClick={() => onSelectMessage(user.id)}
              className={`flex items-center space-x-4 p-2 hover:bg-gray-200 rounded cursor-pointer w-full ${
                selectedMessageId === user.id ? 'bg-gray-200' : ''
              }`}
            >
              <img
                src={user.userImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'}
                alt={user.userName}
                className="w-10 h-10 rounded-full"
              />
              <div className="flex-grow">
                <h3 className="font-bold">{user.userName}</h3>
                <p>{message.lastMessage || 'No messages yet'}</p>
                {/* Removed the last seen display */}
              </div>
              {isImportant && (
                <span className="text-red-500 text-xs">★</span>
              )}
              {!message.lastMessageSeen && message.lastMessageUid !== currentUser.id && (
                <span className=""></span>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ChatList;
