// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDMeKkUHqbYHa0Bn-jjdd61GNxdihSm6VY",
  authDomain: "eswaf-d4041.firebaseapp.com",
  projectId: "eswaf-d4041",
  storageBucket: "eswaf-d4041.appspot.com",
  messagingSenderId: "290904372242",
  appId: "1:290904372242:web:daceb192114933879f54cb",
  measurementId: "G-HP0RJGC9DE"
};


  
    // apiKey: "AIzaSyAprqY4FTmNxckSetudQpo709RSVf4A8Ts",
    // authDomain: "eswaf-project-96178.firebaseapp.com",
    // projectId: "eswaf-project-96178",
    // storageBucket: "eswaf-project-96178.appspot.com",
    // messagingSenderId: "994119269880",
    // appId: "1:994119269880:web:d650503766546a9296aa0d",
    // measurementId: "G-CT9B8YK8FH"
  


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);  

const db = getFirestore(app);  

export { storage, auth, db };
