import React, { useState } from 'react';
import uploadImage from '../../Controller/firebase/firebase_upload_image';
import LoaderSpinner from './loader_spinner';

const VideoUpload = ({ files = [], setFiles, svgImagePath, bgColor, borderRadius, width, height }) => {
  const [loading, setLoading] = useState(false);

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) { // Check for valid video file
      setLoading(true);
      try {
        const downloadURL = await uploadImage(file); // Assuming uploadImage can handle video uploads
        setFiles([...files, downloadURL]); // Store the URL string directly
      } catch (error) {
        console.error("Error uploading video:", error);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please select a valid video file.");
    }
  };

  const removeVideo = (index) => {
    setFiles(files.filter((_, i) => i !== index)); // Remove video by index
  };

  return (
    <div className="flex">
      {files.length > 0 ? (
        files.map((url, index) => (
          <div key={index} className="relative" style={{ width: width || '80px', height: height || '90px' }}>
            <video
              src={url}
              className="object-cover w-full h-full"
              style={{ borderRadius: borderRadius || '8px', backgroundColor: bgColor || '#E6E6E6' }}
              controls
            />
            <button
              className="absolute top-0 right-0 text-white rounded-full p-1"
              onClick={() => removeVideo(index)}
            >
              &times;
            </button>
          </div>
        ))
      ) : (
        <label
          htmlFor="video-upload"
          className="cursor-pointer flex items-center justify-center"
          style={{
            backgroundColor: bgColor || '#C1C1C1',
            borderRadius: borderRadius || '8px',
            width: width || '80px',
            height: height || '90px',
          }}
        >
          {loading ? (
            <LoaderSpinner visible={true} />
          ) : (
            <>
              <img src={svgImagePath} alt="Upload" className="w-5 h-5" />
              <input type="file" accept="video/*" onChange={handleVideoUpload} className="hidden" id="video-upload" />
            </>
          )}
        </label>
      )}
    </div>
  );
};

export default VideoUpload;
