// src/View/Components/utils.js

export const formatLastSeen = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'Last seen: Unknown';
    const date = timestamp.toDate();
    return `Last seen: ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
  
  export const getLastSeenStatus = (user) => {
    if (!user || !user.lastOnlineAt) {
      return 'Offline';
    }
  
    const lastOnline = user.lastOnlineAt.toDate();
    const now = new Date();
    const diffInSeconds = Math.floor((now - lastOnline) / 1000);
  
    if (diffInSeconds < 60) {
      return 'Online';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };