import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Controller/auth";
import DeletePopup from "./delete_pop_up";
const SettingsSidebar = ({ closeSettings }) => {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false); // State to control the popup visibility
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Call the logout API
      const response = await logout();
      if (response.status === 200) {
        // If logout is successful, clear local storage
        localStorage.clear();
        // Navigate to the login page
        navigate("/");
      } else {
        // Handle any other status code, e.g., show an error message
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      // Handle errors, e.g., network issues
      console.error("Error during logout:", error);
    }
  };

  // Show the popup when the logout button is clicked
  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  // Handle confirmation from the popup
  const confirmLogout = () => {
    setShowLogoutPopup(false);
    handleLogout(); // Call logout function on confirmation
  };

  // Close the popup
  const closePopup = () => {
    setShowLogoutPopup(false);
  };

  return (
    <div className="fixed top-0 right-0 h-full w-64 bg-[#171717] text-white transition-width duration-300 z-20">
      <button onClick={closeSettings} className="text-3xl m-4">
        &times;
      </button>
      <div className="flex flex-col items-center ml-5 mb-5 mt-5 mr-5 space-y-4 font-normal">
        {/* First Container */}
        <div className="w-full px-4 bg-[#F0F0F0] text-[#000000] rounded-lg p-4">
          <Link to="/account_details" className="flex items-center">
            <div className="hover-group w-full">
              <button className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/account.svg" alt="Account Icon" className="w-6 h-6 inline-block mr-4 settings-icon" />
                Account
              </button>
            </div>
          </Link>
        </div>

        {/* Second Container */}
        <div className="w-full px-4 bg-[#F0F0F0] text-[#000000] rounded-lg p-4">
          <Link to="/privacy_policy" className="flex items-center">
            <div className="hover-group w-full">
              <button className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/privacy_policy.svg" alt="Privacy Policy Icon" className="w-5 h-5 inline-block mr-4 settings-icon" />
                Privacy Policy
              </button>
            </div>
          </Link>
          <Link to="/help_support" className="flex items-center mt-4">
            <div className="hover-group w-full">
              <button className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/help_support.svg" alt="Help & Support Icon" className="w-5 h-5 inline-block mr-4 settings-icon" />
                Help & Support
              </button>
            </div>
          </Link>
          <Link to="/terms_policies" className="flex items-center mt-4">
            <div className="hover-group w-full">
              <button className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/terms_policies.svg" alt="Terms & Policies Icon" className="w-5 h-5 inline-block mr-4 settings-icon" />
                Terms & Policies
              </button>
            </div>
          </Link>
          <Link to="/about_us" className="flex items-center mt-4">
            <div className="hover-group w-full">
              <button className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/terms_policies.svg" alt="About Us Icon" className="w-5 h-5 inline-block mr-4 settings-icon" />
                About-Us
              </button>
            </div>
          </Link>
        </div>

        <div className="w-full px-4 bg-[#F0F0F0] text-[#000000] rounded-lg p-4">
          <Link to="/report_page" className="flex items-center">
            <div className="hover-group w-full">
              <button className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/report_issue.svg" alt="Report a Problem Icon" className="w-5 h-5 inline-block mr-4 settings-icon" />
                Report a Problem
              </button>
            </div>
          </Link>
          <div className="flex items-center mt-4">
            <div className="hover-group w-full">
              <button onClick={handleLogoutClick} className="w-full text-left hover:text-[#DB0000]">
                <img src="/Assets/logout.svg" alt="Log Out Icon" className="w-5 h-5 inline-block mr-4 settings-icon" />
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>

      {showLogoutPopup && (
        <DeletePopup
          message="Are you sure you want to log out?"
          onConfirm={confirmLogout} // Log out on confirmation
          onClose={closePopup} // Close the popup on cancel
        />
      )}

      <style jsx>{`
        .hover-group:hover .settings-icon {
          filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(5735%) hue-rotate(357deg) brightness(96%) contrast(115%);
        }
      `}</style>
    </div>
  );
};

export default SettingsSidebar;
