// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const Button = ({ onClick, text, borderColor, backgroundColor, textColor, navigateTo, fontWeight }) => {
//   const navigate = useNavigate();

//   const handleClick = () => {
//     if (onClick) onClick();
//     if (navigateTo) navigate(navigateTo);
//   };

//   return (
//     <button
//       onClick={handleClick}
//       className={`w-full py-2 mt-2 border rounded ${borderColor} ${backgroundColor} ${textColor} text-sm md:text-base lg:text-lg`}
//       style={{ fontWeight }} // Apply the font weight
//     >
//       {text}
//     </button>
//   );
// };

// export default Button;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

const Button = ({ onClick, text, borderColor, backgroundColor, textColor, navigateTo, fontWeight, loading }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) onClick();
    if (navigateTo) navigate(navigateTo);
  };

  return (
    <button
      onClick={handleClick}
      className={`w-full py-2 mt-1 border rounded ${borderColor} ${backgroundColor} ${textColor} text-sm md:text-base lg:text-lg flex justify-center items-center`}
      style={{ fontWeight }} // Apply the font weight
      disabled={loading} // Disable button when loading
    >
      {loading ? (
        <ScaleLoader
          height={20}
          width={2}
          radius={2}
          margin={2}
          color="#F9F9F9"
          loading={loading}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
