// locationController.js
import { showToast } from '../View/Components/toast';
import { get_country_code } from './auth';
import { postLocation } from './auth';
export const fetchCountries = async (continent, setLoading, setCountries) => {
  setLoading(true);
  try {
    const response = await get_country_code(continent);
    console.log(response)

    if (response.status === 200) {
      setCountries(response.data.data);
    } else {
      // showToast('Error fetching countries', 'error');
    }
  } catch (error) {
    console.error('Error fetching countries:', error);
    // showToast('Error fetching countries', 'error');
  } finally {
    setLoading(false);
  }
};

export const saveLocation = async (navigate, completed, setLoading) => {
  const storedContinent = localStorage.getItem('continent');
  const storedCountry = localStorage.getItem('country');

  if (!storedCountry) {
    showToast('Please select a country.', 'error');
    return;
  }

  setLoading(true);

  const payload = {
    continent: storedContinent,
    country: storedCountry
  };

  try {
    const response = await postLocation(payload);
    if (response.status === 202) {
      navigate('/trade-selection', { state: { completed } });
    } else {
      // showToast('Error saving location. Please try again.', 'error');
    }
  } catch (error) {
    console.error('Error saving location:', error);
    // showToast('Error saving location. Please try again.', 'error');
  } finally {
    setLoading(false);
  }
};
