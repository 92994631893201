
// import React, { useState } from 'react';
// import AddDocument from '../../Components/add_doc';
// import InputBox from '../../Components/input_box';
// import Button from '../../Components/button';
// import Sidebar from '../../Components/main_navbar';
// import { useNavigate } from 'react-router-dom';
// import ReportContainer from '../../Components/report';
// import { post_manager } from '../../../Controller/company_details';
// import { showToast, Toast } from '../../Components/toast';
// import UploadDocuments from '../../../Controller/firebase/firebase_documents';

// const CompanyAccount_Managed = () => {
//   const navigate = useNavigate();
//   const [authorizationFile, setAuthorizationFile] = useState('');
//   const [manager, setManagerName] = useState('');
//   const [loading, setLoading] = useState(false); 

//   const handleBackClick = () => {
//     navigate('/login');
//   };

//   const handleViewSampleFormatClick = () => {
//     navigate('/View_sample_format'); 
//   };

//   const handleNameChange = (e) => {
//     const value = e.target.value;
//     setManagerName(value);
//   };

//   const handleFileUpload = async (file) => {
//     try {
//       setLoading(true);
//       const downloadURL = await UploadDocuments(file);
//       setAuthorizationFile(downloadURL);
//       showToast('File uploaded successfully!', 'success');
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       showToast(error.response?.data?.message || 'Error uploading file.', 'error'); // Use the error message from the response
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSave = async () => {
//     if (!manager || !authorizationFile) {
//       showToast('Please provide both manager name and authorization file.', 'error');
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await post_manager(manager, authorizationFile);
//       const message = response.data.message; // Extract message from the response

//       if (response.status === 202) {
//         console.log(response);
        
//         navigate('/linkedin_page');
//       } else if (response.status === 404) {
       
//       }
//     } catch (error) {
//       console.error('Error saving company details:', error);
      
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
//       <Sidebar />
//       <Toast /> {/* Include the Toast component */}
//       <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl  font-poppins">Company Details</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       {/* <div className="w-4/5 ml-7">
//         <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto md:p-6 lg:p-8 rounded-lg " style={{ height: '600px', width: '450px' }}>
//           <div className="flex items-center mt-10"> */}
//           <div className="lg:w-4/5 md:w-3/4 lg:mt-8 mt-4 p-4">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg" >
//           <div className="flex items-center mt-2">
//             <img src="Assets/account_managed.svg" alt="company icon" className="w-8 h-8 mr-2" />
//             <p className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Account Managed By</p>
//           </div>
//           <div className="mb-6 mt-5">
//             <InputBox
//               placeholder="Name"
//               value={manager}
//               onChange={handleNameChange}
//               className="font-poppins Light"
//               borderRadius='4px'
//             />
//           </div>
//           <div className='w-3/4'>
//           <AddDocument label="Authorization File" onFileUpload={handleFileUpload} />
//           </div>
//           <p className='text-xs text-[12px] text-[#171717]] font-poppins font-bold'>Single file size limit: 25MB file types: PDF</p>
//           <div className='mt-10'>
//             <a href="#" className="text-[#6D6D6D] underline text-sm text-[14px] font-poppins font-bold" onClick={handleViewSampleFormatClick}>View Sample Format</a>
//           </div>
//           <div className="mt-40">
//             <Button
//               text="Save"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSave}
//               loading={loading} 
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyAccount_Managed;
import React, { useState } from 'react';
import AddDocument from '../../Components/add_doc';
import InputBox from '../../Components/input_box';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import { Toast } from '../../Components/toast';
import { uploadAuthorizationFile, saveManagerDetails } from '../../../Controller/manager';

const CompanyAccount_Managed = () => {
  const navigate = useNavigate();
  const [authorizationFile, setAuthorizationFile] = useState('');
  const [manager, setManagerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(''); // To store file validation error

  const handleViewSampleFormatClick = () => {
    const fileUrl = '/docs/Authorization_Letter.docx'; // Updated path to the file in public folder
    window.open(fileUrl, '_blank'); // Opens the document in a new tab
  };

  const handleNameChange = (e) => {
    setManagerName(e.target.value);
  };

  // Handle file upload and validate PDF
  const handleFileUpload = async (file) => {
    if (file.type !== 'application/pdf') {
      setFileError('Only PDF files are allowed.');
      setAuthorizationFile(''); // Clear the file state
      return;
    }
    setFileError(''); // Clear error if the file is valid
    uploadAuthorizationFile(file, setAuthorizationFile, setLoading); // Call the controller function
  };

  const handleSave = async () => {
    if (!authorizationFile) {
      setFileError('Please upload a valid PDF file.'); // Ensure file is present before saving
      return;
    }
    saveManagerDetails(manager, authorizationFile, navigate, setLoading); // Call the controller function
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Details</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex items-center mt-2">
            <img src="Assets/account_managed.svg" alt="company icon" className="w-8 h-8 mr-2" />
            <p className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Account Managed By</p>
          </div>
          <div className="mb-6 mt-5">
            <InputBox
              placeholder="Name"
              value={manager}
              onChange={handleNameChange}
              borderRadius="4px"
            />
          </div>
          <div className="w-3/4">
            <AddDocument label="Authorization File" onFileUpload={handleFileUpload} accept="application/pdf" />
            {fileError && <p className="text-red-500 text-xs mt-1">{fileError}</p>} {/* Display file validation error */}
          </div>
          <p className="text-xs text-[12px] text-[#171717] font-poppins font-semibold">Single file size limit: 25MB file types: PDF</p>
          <div className="mt-10">
            <a href="#" className="text-[#6D6D6D] underline text-sm text-[14px] font-poppins font-semibold" onClick={handleViewSampleFormatClick}>View Sample Format</a>
          </div>
          <div className="mt-40">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAccount_Managed;
