import React from 'react';
import './multi_select.css';

const MultiSelect = ({ onChange, selectedOptions }) => {
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    let updatedOptions = [...selectedOptions];

    if (value === 'Both') {
      if (selectedOptions.includes('Both')) {
        // Unselect Both and also unselect Domestic and International
        updatedOptions = [];
      } else {
        // Select Both, Domestic, and International
        updatedOptions = ['Domestic', 'International', 'Both'];
      }
    } else {
      if (value === 'Domestic') {
        if (selectedOptions.includes('Domestic')) {
          // Unselect Domestic
          updatedOptions = updatedOptions.filter(opt => opt !== 'Domestic');
        } else {
          // Select Domestic and unselect International
          updatedOptions = ['Domestic'];
        }
      }

      if (value === 'International') {
        if (selectedOptions.includes('International')) {
          // Unselect International
          updatedOptions = updatedOptions.filter(opt => opt !== 'International');
        } else {
          // Select International and unselect Domestic
          updatedOptions = ['International'];
        }
      }

      // If both Domestic and International are selected, automatically select Both
      if (updatedOptions.includes('Domestic') && updatedOptions.includes('International')) {
        updatedOptions = [...updatedOptions, 'Both'];
      }
    }

    // Update state and local storage
    onChange(updatedOptions);
    localStorage.setItem('area_of_operation', JSON.stringify(updatedOptions));
  };

  return (
    <div className="flex flex-col items-start">
      {/* Show Domestic and International checkboxes */}
      <label className="flex items-center mb-4 cursor-pointer">
        <input
          type="checkbox"
          value="Domestic"
          checked={selectedOptions.includes('Domestic')}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4 font-helvetica"
        />
        <span className="ml-2 text-[15px] lg:text-lg font-gotham font-light">Domestic</span>
      </label>
      <label className="flex items-center mb-4 cursor-pointer">
        <input
          type="checkbox"
          value="International"
          checked={selectedOptions.includes('International')}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4"
        />
        <span className="ml-2 text-[15px] lg:text-lg font-gotham font-light">International</span>
      </label>

      {/* Always show the Both checkbox */}
      <label className="flex items-center cursor-pointer">
        <input
          type="checkbox"
          value="Both"
          checked={selectedOptions.includes('Both')}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4"
        />
        <span className="ml-2 text-[15px] lg:text-lg font-gotham font-light">Both</span>
      </label>
    </div>
  );
};

export default MultiSelect;
