import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OTPInputComponent from "../../Components/otp_input";
import Button from "../../Components/button";
import { Toast } from "../../Components/toast";
import CenteredImageContainer from "../../Components/black_container";
import { verifyOtpAndNavigate } from "../../../Controller/reset_otp_page_controller";
const ResetOtpPassword = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        const emailFromStorage = localStorage.getItem('email');
        if (emailFromStorage) {
            setEmail(emailFromStorage);
        }
    }, []);

    const handleChange = (otp) => {
        setOtp(otp);
    };

    const handleSubmit = async () => {
        verifyOtpAndNavigate(email, otp, navigate, setLoading); // Call the controller function
    };

    return (
        <div className="h-full w-full flex bg-[#FBFCF8] font-poppins">
            <Toast />
            <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
                <div className='w-4/5 h-full'> 
                    <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
                </div>
            </div>
            <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
                <div className="p-6 w-full max-w-2/5">
                    <div className="flex items-center mb-6">
                        <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
                    </div>
                    <h2 className="text-3xl font-semibold text-[#0D0907] mb-6">Email verification</h2>
                    <p className="text-sm text-[#9F9F9F] mb-6">Please enter the OTP sent to your mail</p>
                    <div className="flex justify-center items-center mb-4">
                        <OTPInputComponent otp={otp} handleChange={handleChange} />
                    </div>
                    <p className="flex justify-center text-sm mb-4">
                        Didn't receive the code? <span className="text-red-600 cursor-pointer font-bold">Re-send</span>
                    </p>
                    <div className='mt-10'>
                        <Button
                            text="Save"
                            backgroundColor="bg-[#DB0000]"
                            textColor="text-white"
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetOtpPassword;
