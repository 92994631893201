// categoryController.js
import { showToast } from '../View/Components/toast';
import { get_sub_companies_api } from './auth';
import { postsubcategory } from './auth';
export const fetchCategories = async (setCategories, setCategoryMap, setLoading) => {
  setLoading(true);
  try {
    const response = await get_sub_companies_api();
    if (response.status === 200 && response.data.status_code === 200) {
      const companies = response.data.data;
      if (Array.isArray(companies)) {
        const fetchedCategories = companies.map(item => item.name);
        const categoryMap = {};
        companies.forEach(item => {
          categoryMap[item.name] = item.id;
        });
        setCategories(fetchedCategories);
        setCategoryMap(categoryMap);
        localStorage.setItem('fetchedCompanies', JSON.stringify(companies));
      }
    }
  } catch (error) {
    console.error("Error fetching companies:", error);
  } finally {
    setLoading(false);
  }
};

export const handleSaveCategory = async (
    selectedOption, 
    otherInputValue, 
    categoryMap, 
    navigate, 
    setLoading, 
    completed
  ) => {
    if (!selectedOption) {
      showToast('Please select an option', 'error');
      return;
    }
  
    setLoading(true);
  
    if (selectedOption === 'other') {
      if (!otherInputValue.trim()) {
        showToast('Please enter a value for Other', 'error');
        setLoading(false);
        return;
      }
  
      const category = localStorage.getItem('category');
      const payload = {
        category,
        sub_category_name: otherInputValue.trim(),
      };
  
      try {
        const response = await postsubcategory(payload);
        
        // Check if the status_code inside response.data is 200 or 201
        if (response.status === 200 || response.data.status_code === 201) {
          const newSubCategoryId = response.data.id;
          localStorage.setItem('sub_category', newSubCategoryId);
          // showToast(response.data.message, 'success'); // Show the success message
          setLoading(false); // Stop loading before navigating
          navigate('/area-of-operation', { state: { completed } });
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        console.error('Error in postsubcategory:', error);
        // showToast('An error occurred while saving the subcategory', 'error');
        setLoading(false);
      }
    } else {
      const selectedId = categoryMap[selectedOption];
      localStorage.setItem('sub_category', selectedId);
      setLoading(false);
      navigate('/area-of-operation', { state: { completed } });
    }
  };
  