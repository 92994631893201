import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReportContainer from "../../Components/report";

const categories = [
  { key: 'logistics', smallUrl: './logisticsmobile.svg', largeUrl: './new-logistics.svg' },
  { key: 'financial_institutions', smallUrl: './financialinstituitionsmobile.svg', largeUrl: './new-financial.svg' },
  { key: 'start_up', smallUrl: './start-up-mobile.svg', largeUrl: './new-start-up.svg' },
  { key: 'legal', smallUrl: './legal_mobile.svg', largeUrl: './new-legal.svg' },
  { key: 'precious_stones_metals', smallUrl: './precious(2).svg', largeUrl: './new-precious.svg' },
  { key: 'investor', smallUrl: './investor-mobile.svg', largeUrl: './new-investor.svg' },
  { key: 'seller', smallUrl: './seller-mobile.svg', largeUrl: './new-seller.svg' },
  { key: 'market_place', smallUrl: './marketplacemobile.svg', largeUrl: './new-market.svg' },
  { key: 'projects', smallUrl: './projects(2).svg', largeUrl: './new-projects.svg' },
  { key: 'buyer', smallUrl: './buyermobile35827.svg', largeUrl: './new-buyer.svg' }
];


const TradeRolePageSelection = () => {
  const [imageUrls, setImageUrls] = useState(() => {
    const initialUrls = {};
    categories.forEach(category => {
      initialUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
    });
    return initialUrls;
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { completed } = location.state || { completed: [] };

  useEffect(() => {
    console.log('completed:', completed);

    const handleResize = () => {
      const newUrls = {};
      categories.forEach(category => {
        newUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
      });
      setImageUrls(newUrls);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [completed]);

  const handleNavigation = (key) => {
    localStorage.setItem('category', key); 
    navigate('/category-listings', { state: { completed } });
  };

  return (
    <div className="bg-[rgba(242,243,243,1)] min-h-screen p-4 overflow-y-auto">
      {/* Updated Header Section */}
      <div className="ml-5 mt-4">
        <h1 className="text-2xl font-bold text-black">Select Trade Role</h1>
        <p className="text-gray-600 mt-2">Please Select your Category from this</p>
      </div>
      <div className="absolute top-5 right-6">
        <ReportContainer />
      </div>
      <div className="p-0 mt-10">
        <div className="hex-grid-container-wrapper">
          <div className="hex-grid-container">
            {categories.map((category) => (
              <div
                key={category.key}
                className={`hex-item ${category.key}`}
                onClick={() => handleNavigation(category.key)}
              >
                <img src={imageUrls[category.key]} alt={category.key} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeRolePageSelection;
