import React from 'react';
import Discover from '../../Components/discover_screen';
import Explore from '../../Components/explore_screen';
import Sidebar from '../../Components/main_navbar';


const DiscoverExplore = () => (
  <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna">
    <Sidebar />
    <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
      <h1 className="text-black font-bold sm:text-lg lg:text-xl">Discover</h1>
    </div>
    <div className="border-b border-black mt-4 md:mt-[25px]"></div>
    <div className="lg:w-4/5 h-full w-full overflow-y-auto overflow-x-hidden">
      <div className="lg:h-1/4 w-full md:h-1/3 sm:h-1/4">
        <Discover />
      </div>
      <div className="lg:h-full lg:w-full mt-32 lg:ml-7">
        <h2 className="text-xl text-[#4A4A4A] font-bold p-4">Explore</h2>
        <Explore />
      </div>
    </div>
  </div>
);

export default DiscoverExplore;
