// import React, { useState, useEffect } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import ComplaintCard from '../../Components/complaint_card';
// import TextArea from '../../Components/text_area';
// import Button from '../../Components/button';
// import InputBox from '../../Components/input_box';
// import AddDocument from '../../Components/add_doc';
// import ReviewImageUpload from '../../Components/review_image_add';
// import LoaderSpinner from '../../Components/loader_spinner';
// import { FaSpinner } from 'react-icons/fa'; // Import FontAwesome Spinner
// import { useNavigate } from 'react-router-dom';
// import { uploadComplaint, getComplaintsAgainstCompany } from '../../../Controller/complaints';
// import { showToast } from '../../Components/toast';
// import { Toast } from '../../Components/toast';

// const AddComplaints = () => {
//   const [selectedComplaint, setSelectedComplaint] = useState(null);
//   const [complaints, setComplaints] = useState([]);
//   const [showForm, setShowForm] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [showMobileDetail, setShowMobileDetail] = useState(false);
//   const [authorizationFile, setAuthorizationFile] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalComplaints, setTotalComplaints] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const complaintsPerPage = 5;
//   const navigate = useNavigate();

//   // Get company_uid from localStorage
//   const company_uid = localStorage.getItem('uid');

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };
//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   useEffect(() => {
//     fetchComplaints();
//   }, [currentPage]);

//   const fetchComplaints = async () => {
//     try {
//       if (currentPage === 1) {
//         setLoading(true); // Show main loading spinner for the first page
//       } else {
//         setLoadingMore(true); // Show spinner for loading more pages
//       }

//       const skip = (currentPage - 1) * complaintsPerPage;
//       const res = await getComplaintsAgainstCompany(company_uid, skip, complaintsPerPage);

//       const complaintsArray = res.data.data;
//       if (Array.isArray(complaintsArray)) {
//         const mappedComplaints = complaintsArray.map((item) => ({
//           complaint_by_name: item.complaint_by_name,
//           complaint_to: item.complaint_to,
//           product_name: item.product_name,
//           title: item.title,
//           description: item.description,
//           image_url: item.image_url && item.image_url.length > 0 ? item.image_url[0] : '/profile.webp',
//           confirmation_document: item.confirmation_document,
//           date: new Date(item.created_on).toLocaleDateString(),
//           complaint_by_profile_image: item.complaint_by_profile_image || '/profile.webp',
//           reply: item.reply,
//           reply_name: item.reply_name,
//           reply_profile_image: item.reply_profile_image,
//           updated_on: item.updated_on
//         }));

//         setComplaints((prevComplaints) => [...prevComplaints, ...mappedComplaints]);
//         setTotalComplaints(res.data.total || complaintsArray.length);
//       } else {
//         console.error('Expected an array for complaints, but got:', complaintsArray);
//         showToast('Failed to load complaints.', 'error');
//       }
//     } catch (error) {
//       console.error('Error fetching complaints:', error);
//       showToast('Error fetching complaints.', 'error');
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };

//   const handleLoadMore = () => {
//     setCurrentPage((prevPage) => prevPage + 1);
//   };

//   const handleViewComplaint = (complaint) => {
//     setSelectedComplaint(complaint);
//     setShowForm(false);
//     if (isMobile) {
//       setShowMobileDetail(true);
//     }
//   };

//   const handleReportComplaint = () => {
//     setSelectedComplaint(null);
//     setShowForm(true);
//     if (isMobile) {
//       setShowMobileDetail(true);
//     }
//   };

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col md:flex-row w-full h-full bg-[#F2F3F3] font-techna overflow-y-auto">
//       <Toast /> {/* Toast notifications */}
//       <Sidebar />
//       <div className="flex flex-col w-full">
//         <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//           <div className="w-full flex justify-start items-center space-x-2">
//             <img
//               src="/Assets/arrow_back.svg"
//               alt="Back"
//               className="cursor-pointer mr-2 h-6 w-6"
//               onClick={handleBackClick}
//             />
//             <h1 className="text-black font-bold text-xl">Complaints</h1>
//           </div>
//         </div>
//         <div className="border-b border-black mt-4 md:mt-[25px] grow"></div> {/* Fixed middle line */}
//         <div className="flex flex-col md:flex-row h-full">
//           <div className={`${isMobile ? 'w-full' : 'md:w-1/3'} border-r border-gray-400`}>
//             <div className="flex flex-col items-center p-3 md:p-9 ">
//               <Button
//                 text="Report Your Complaint"
//                 backgroundColor="bg-white"
//                 textColor="text-red-600"
//                 borderColor="border-red-600"
//                 onClick={handleReportComplaint}
//               />
//               {loading ? (
//                 <div className="my-4">
//                   <FaSpinner className="animate-spin text-gray-500 text-2xl" />
//                 </div>
//               ) : (
//                 <>
//                   {complaints.length > 0 ? (
//                     complaints.map((complaint, index) => (
//                       <ComplaintCard
//                         key={index}
//                         complaint={complaint}
//                         onView={handleViewComplaint}
//                       />
//                     ))
//                   ) : (
//                     <div className="text-center text-black mt-4">There are No Complaints Reported</div>
//                   )}
//                   {loadingMore && (
//                     <div className="my-4">
//                       <FaSpinner className="animate-spin text-gray-500 text-2xl" />
//                     </div>
//                   )}
//                   {complaints.length < totalComplaints && (
//                     <Button
//                       text="Load More"
//                       backgroundColor="bg-blue-600"
//                       textColor="text-white"
//                       borderColor="border-blue-600"
//                       onClick={handleLoadMore}
//                     />
//                   )}
//                 </>
//               )}
//             </div>
//           </div>
//           {/* Complaint Detail Section */}
//           {selectedComplaint && !showForm && (!isMobile || (isMobile && showMobileDetail)) && (
//             <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-7`}>
//               {isMobile && (
//                 <button
//                   onClick={() => setShowMobileDetail(false)}
//                   className="absolute top-2 right-2 text-2xl"
//                 >
//                   &times;
//                 </button>
//               )}
              
//               <div className="bg-[#E1E1E1] rounded-lg p-4 shadow-md w-full mb-4 font-helvetica ">
//                 <div className="flex">
//                   <img
//                     src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
//                     alt="Profile"
//                     className="rounded-full w-12 h-12 mr-4"
//                   />
//                   <div className="flex flex-col">
//                     <div className="font-bold">{selectedComplaint.complaint_by_name}</div>
//                     <div className="text-gray-500 text-sm">{new Date(selectedComplaint.created_on).toLocaleDateString()}</div>
//                   </div>
//                 </div>
//                 <div className="mt-4">
//                   <div className="font-bold text-sm">{selectedComplaint.title}</div>
//                   <div className="text-gray-600 font-bold mt-2 text-sm">{selectedComplaint.product_name}</div>
//                   <div className="text-sm mt-2">{selectedComplaint.description}</div>
//                   {selectedComplaint.image_url && (
//                     <img
//                       src={selectedComplaint.image_url}
//                       alt="Complaint"
//                       className="w-16 h-16 rounded-lg mt-4"
//                     />
//                   )}
//                   {selectedComplaint.confirmation_document && (
//                     <div className="text-gray-500 text-sm mt-4">
//                       Attached Document: {selectedComplaint.confirmation_document}
//                     </div>
//                   )}
//                 </div>
//               </div>

//               {/* Reply Section */}
//               {selectedComplaint.reply && (
//                 <div className="flex justify-between items-center p-4 bg-[#E1E1E1] rounded-lg mb-4">
//                   <div className="flex items-start">
//                     <img
//                       src={selectedComplaint.reply_profile_image || '/profile.webp'}
//                       alt="Reply Profile"
//                       className="rounded-full w-12 h-12 mr-4"
//                     />
//                     <div className="flex flex-col">
//                       <div className="font-bold">Reply</div>
//                       <div className="text-sm mt-2">{selectedComplaint.reply || 'No reply available'}</div>
//                     </div>
//                   </div>
//                   {/* Time and Date at the end */}
//                   <div className="text-gray-500 text-sm">
//                     {selectedComplaint.updated_on
//                       ? new Date(selectedComplaint.updated_on + 'Z').toLocaleString('en-US', {
//                           year: 'numeric',
//                           month: '2-digit',
//                           day: '2-digit',
//                           hour: '2-digit',
//                           minute: '2-digit',
//                           hour12: false,
//                         })
//                       : 'Not Found'}
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddComplaints;
import React, { useState, useEffect, useRef } from 'react';

import Sidebar from '../../Components/main_navbar';
import ComplaintCard from '../../Components/complaint_card';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import InputBox from '../../Components/input_box';
import AddDocument from '../../Components/add_doc';
import ReviewImageUpload from '../../Components/review_image_add';
import LoaderSpinner from '../../Components/loader_spinner'; // Loader Spinner
import { FaSpinner } from 'react-icons/fa'; // FontAwesome Spinner
import { useNavigate } from 'react-router-dom';
import { uploadComplaint, getComplaintsAgainstCompany } from '../../../Controller/complaints';
import { showToast } from '../../Components/toast';
import { Toast } from '../../Components/toast';


const AddComplaints = () => {
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [complaints, setComplaints] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileDetail, setShowMobileDetail] = useState(false);
  const [authorizationFile, setAuthorizationFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalComplaints, setTotalComplaints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const listRef = useRef(null); // Ref for the left section scroll event

  const company_uid = localStorage.getItem('company_uid');  // Fetch company_uid from localStorage
  const complaintsPerPage = 5;
  const navigate = useNavigate();

  // Handle scrolling for infinite loading
  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10 && !loadingMore && complaints.length < totalComplaints) {
        setCurrentPage((prevPage) => prevPage + 1); // Load next page
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchComplaints();
  }, [currentPage]);

  const fetchComplaints = async () => {
    try {
      setLoading(currentPage === 1); // Show loading spinner if it's the first page
      setLoadingMore(currentPage > 1); // Show loading spinner for more pages
  
      const skip = (currentPage - 1) * complaintsPerPage;
      const res = await getComplaintsAgainstCompany(company_uid, skip, complaintsPerPage);
      console.log('get response', res);
  
      const complaintsArray = res.data.data; // Adjust based on the correct structure
      if (Array.isArray(complaintsArray)) {
        const mappedComplaints = complaintsArray.map((item) => ({
          id: item.id,
          complaint_by_name: item.complaint_by_name || 'Unknown',  // Fallback if name is empty
          complaint_by_profile_image: item.complaint_by_profile_image || '/profile.webp',  // Fallback if no image
          complaint_to: item.complaint_to,
          product_name: item.product_name,
          title: item.title,
          description: item.description,
          image_url: item.image_url && item.image_url.length > 0 ? item.image_url[0] : '/profile.webp',
          confirmation_document: item.confirmation_document,
          date: new Date(item.created_on).toLocaleDateString(),  // Format date for display
          reply: item.reply,
          reply_name: item.reply_name,
          reply_profile_image: item.reply_profile_image,
          replied_on: new Date(item.replied_on).toLocaleDateString(),
        }));
  
        setComplaints((prevComplaints) => [...prevComplaints, ...mappedComplaints]);
        setTotalComplaints(res.data.total || complaintsArray.length);
      } else {
        console.error('Expected an array for complaints, but got:', complaintsArray);
        showToast('Failed to load complaints.', 'error');
      }
    } catch (error) {
      console.error('Error fetching complaints:', error);
      showToast('Error fetching complaints.', 'error');
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };
  

  const handleViewComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setShowForm(false);
    if (isMobile) {
      setShowMobileDetail(true); // Show modal only on mobile view
    }
  };

  const handleReportComplaint = () => {
    setSelectedComplaint(null);
    setShowForm(true);
    if (isMobile) {
      setShowMobileDetail(true);
    }
  };

  const handleFileUpload = (file) => {
    setAuthorizationFile(file);
  };

  const handleFormSubmit = async (newComplaint) => {
    try {
      setFormSubmitting(true); // Start loading spinner on form submission
      const complaint_to = localStorage.getItem('uid');

      const res = await uploadComplaint(
        complaint_to,
        newComplaint.productName,
        newComplaint.title,
        newComplaint.description,
        authorizationFile ? authorizationFile.name : '',
        newComplaint.images
      );

      console.log(res);

      if (res.status === 201) {
        const createdComplaint = res.data.data;

        const complaint = {
          complaint_by_name: createdComplaint.complaint_by_name, // Correct name
          complaint_to_name: createdComplaint.complaint_to_name, // Correct name for complaint_to
          product_name: createdComplaint.product_name,
          title: createdComplaint.title,
          description: createdComplaint.description,
          image_url: createdComplaint.image_url.length > 0
            ? createdComplaint.image_url[0]  // Complaint image, take the first in the array
            : '/profile.webp', // Fallback image in case no image is provided
          confirmation_document: createdComplaint.confirmation_document,
          date: new Date(createdComplaint.created_on).toLocaleDateString(),
          complaint_by_profile_image: createdComplaint.complaint_by_profile_image || '/profile.webp', // Fallback to default
          complaint_to_profile_image: createdComplaint.complaint_to_profile_image || '/profile.webp', // Fallback to default
        };

        setComplaints((prevComplaints) => [...prevComplaints, complaint]);
        setShowForm(false);
        setShowMobileDetail(false);
        setSelectedComplaint(complaint);
        showToast('Complaint submitted successfully.', 'success');
      } else {
        console.error(`Error: Received status code ${res.status}`);
        showToast('Error submitting complaint.', 'error');
      }
    } catch (error) {
      console.error('Error submitting complaint:', error);
      showToast('Error submitting complaint.', 'error');
    } finally {
      setFormSubmitting(false); // Stop loading spinner after form submission
    }
  };

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  return (
    <div className="flex flex-col md:flex-row w-full h-full bg-[#F2F3F3] font-helvetica">
      <Toast /> {/* Toast notifications */}
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
          <div className="w-full flex justify-start items-center space-x-2">
            <img
              src="/Assets/arrow_back.svg"
              alt="Back"
              className="cursor-pointer mr-2 h-6 w-6"
              onClick={handleBackClick}
            />
            <h1 className="text-black font-bold text-xl">Complaints</h1>
          </div>
        </div>
        <div className="flex h-full">
          {/* Left Column */}
          <div className="md:w-1/3 h-full flex flex-col overflow-y-auto border-r border-gray-400" ref={listRef} onScroll={handleScroll}>
            <div className="flex flex-col items-center p-3 md:p-9 space-y-6">
              <Button
                text="Report Your Complaint"
                backgroundColor="bg-white"
                textColor="text-red-600"
                borderColor="border-red-600"
                onClick={handleReportComplaint}
              />
              {complaints.length > 0 ? (
                complaints.map((complaint, index) => (
                  <ComplaintCard
                    key={index}
                    complaint={complaint}
                    onView={handleViewComplaint}
                  />
                ))
              ) : (
                <div className="text-center text-black mt-4">There are No Complaints Reported</div>
              )}
              {loadingMore && (
                <div className="my-4">
                  <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                </div>
              )}
            </div>
          </div>

          {/* Partition Line */}
          <div className="hidden md:block border-l-2 border-gray-400" style={{ height: '100%' }}></div>

          {/* Right Column */}
          {selectedComplaint && !showForm && !isMobile && (
            <div className="md:w-2/3 lg:w-1/3 p-7 overflow-hidden"> {/* Desktop view */}
              <div className="bg-[#E1E1E1] rounded-lg p-4 shadow-md w-full mb-4">
                <div className="flex">
                  <img
                    src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
                    alt="Profile"
                    className="rounded-full w-12 h-12 mr-4"
                  />
                  <div>
                    <div className="font-bold">{selectedComplaint.complaint_by_name}</div>
                    <div className="text-gray-600 text-sm">

                    {new Date(selectedComplaint.replied_on).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                <div className="mt-4 lg:ml-1 ml-10">
                <div className="text-gray-600 text-sm">{selectedComplaint.title}</div>

                  <div className="text-gray-600 font-bold mt-2 text-sm mt-2">{selectedComplaint.product_name}</div>
                  <div className="mt-2 text-sm break-words mt-2">{selectedComplaint.description}</div>
                  <img
                    src={selectedComplaint.image_url || '/profile.webp'}
                    alt="Complaint"
                    className="w-16 h-16 rounded-lg mt-4"
                  />
                  <div className="text-gray-500 text-sm mt-4 break-words">{selectedComplaint.confirmation_document}</div>
                </div>
              </div>
              {/* Separate reply section */}
              {selectedComplaint.reply && (
                <div className="mt-4 bg-[#E1E1E1] rounded-lg p-4 shadow-md border border-gray-300">
                  <div className="flex justify-between items-center pb-2">
                    <div className="font-bold font-helvetica text-black">Reply</div>
                    <div className="text-gray-600 text-sm">
                      {new Date(selectedComplaint.replied_on).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{' '}
                      {new Date(selectedComplaint.replied_on).toLocaleDateString()}
                    </div>
                  </div>
                  <div className="mt-4 text-gray-600 break-words">
                    <p>{selectedComplaint.reply}</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Modal for mobile view */}
          {isMobile && selectedComplaint && !showForm && showMobileDetail && (
  <div
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    onClick={(e) => {
      if (e.target === e.currentTarget) {
        setShowMobileDetail(false); // Close modal when clicking outside
      }
    }}
  >
    <div
      className="bg-[#FFFFFF] rounded-lg p-6 w-11/12 md:w-2/3 lg:w-1/3"
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
    >
      <div className="flex justify-between items-center pb-2">
        <div className="font-bold text-lg">Complaint Details</div>
      </div>

      {/* Complaint details */}
      <div className="mt-4">
        <div className="flex items-center">
          <img
            src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
            alt="Profile"
            className="rounded-full w-12 h-12 mr-4"
          />
          <div>
            <div className="font-bold">{selectedComplaint.complaint_by_name || 'Name not available'}</div>
            {/* Format and display created_on date */}
            <div className="text-gray-500 text-sm">
              {new Date(selectedComplaint.created_on).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </div>
          </div>
        </div>

        {/* Complaint Subject and Product Name */}
        <div className="mt-4">
          <div className="font-bold text-sm text-gray-600">Complaint Title/Subject</div>
          <div className="text-sm">{selectedComplaint.title}</div>
        </div>

        <div className="mt-2">
          <div className="font-bold text-sm text-gray-600">Product Name</div>
          <div className="text-sm">{selectedComplaint.product_name}</div>
        </div>

        {/* Description */}
        <div className="mt-4">
          <p className="text-gray-600 text-sm">
            Sir,
            <br />
            {selectedComplaint.description}
          </p>
        </div>

        {/* Complaint Image */}
        {selectedComplaint.image_url && (
          <img
            src={selectedComplaint.image_url}
            alt="Complaint"
            className="w-16 h-16 rounded-lg mt-4"
          />
        )}

        {/* Confirmation Document */}
        <div className="text-gray-500 text-sm mt-4 break-words">{selectedComplaint.confirmation_document}</div>
      </div>

      {/* Reply Section */}
      {selectedComplaint.reply && (
        <div className="mt-4 bg-[#E1E1E1] rounded-lg p-4 shadow-md border border-gray-300">
          <div className="flex justify-between items-center pb-2">
            <div className="font-bold font-helvetica text-black">Reply</div>
            <div className="text-gray-600 text-sm">
              {new Date(selectedComplaint.replied_on).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{' '}
              {new Date(selectedComplaint.replied_on).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </div>
          </div>
          <div className="mt-4 text-gray-600 break-words">
            <p>{selectedComplaint.reply}</p>
          </div>
        </div>
      )}
    </div>
  </div>
)}


          {showForm && (
            <div className="md:w-2/3 lg:w-1/3 p-7 overflow-hidden"> {/* No scroll on the right */}
              <ComplaintSubmissionForm
                onSubmit={handleFormSubmit}
                handleFileUpload={handleFileUpload}
                formSubmitting={formSubmitting}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ComplaintSubmissionForm = ({ onSubmit, handleFileUpload, formSubmitting }) => {
  const [productName, setProductName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reviewImages, setReviewImages] = useState([]);

  const handleSubmit = () => {
    if (title.trim() && description.trim()) {
      onSubmit({
        productName,
        title,
        description,
        images: reviewImages,
      });
      setProductName('');
      setTitle('');
      setDescription('');
      setReviewImages([]);
    }
  };

  return (
    <div className="p-4 space-y-8 mt-4">
      <InputBox
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        placeholder="Product Name"
        borderRadius="1px"
      />
      <InputBox
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title/Subject"
        borderRadius="1px"
      />
      <TextArea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        height="20px"
      />
      <AddDocument 
        label="Authorization File" 
        onFileUpload={handleFileUpload} 
        accept="application/pdf" 
      />
      <div className="mt-4">
        <ReviewImageUpload 
          reviewImages={reviewImages} 
          setReviewImages={setReviewImages} 
          svgImagePath="/Assets/upload-icon.svg" 
          bgColor="#E6E6E6" 
          borderRadius="8px" 
          width="80px" 
          height="90px" 
        />
      </div>
      <div className="mt-9">
        <Button
          text={formSubmitting ? <LoaderSpinner visible={true} /> : "Submit"} // Show loader inside button during submission
          backgroundColor="bg-[#DB0000]"
          textColor="text-white"
          borderColor="border-red-600"
          onClick={handleSubmit}
          disabled={formSubmitting} // Disable button while submitting
        />
      </div>
    </div>
  );
};

export default AddComplaints;
