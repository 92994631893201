import { api } from './api'; // Assuming api is already configured in this file
import { POST_MEDIA_IMAGE_URL ,GET_MEDIA_IMAGES_BY_USER_URL,GET_MEDIA_IMAGE_BY_ID_URL,DELETE_MEDIA_IMAGE_URL} from './urls';// Assuming MEDIA_UPLOAD_URL is the URL for your media endpoint
import { GET_ALL_MEDIA_IMAGES_URL } from './urls';
// API to upload media (image/video)
const uploadMedia = async (title, description, imageUrlArray) => {
  try {
    const payload = {
      title: title,
      description: description,
      image_url: imageUrlArray, // Array of image/video URLs
    };

    const res = await api.post( POST_MEDIA_IMAGE_URL , payload);
    return res;
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};
const getMediaImagesByUser = async (skip, limit) => {
    const id = localStorage.getItem('company_id'); 
    console.log('company_id', id); 
    
    if (!id) {
      console.error("No company UID found in localStorage");
      return;
    }
  
    try {
      const res = await api.get(GET_MEDIA_IMAGES_BY_USER_URL(id), {
        params: {
          skip: skip,
          limit: limit,
        },
      });
  
      return res;
    } catch (error) {
      console.error("Error fetching media images:", error);
      throw error;
    }
  };
  
  const getMediaImageById = async (id) => {
    try {
      const res = await api.get(GET_MEDIA_IMAGE_BY_ID_URL(id)); // Use the URL function to get the correct URL
      return res;
    } catch (error) {
      console.error("Error fetching media image by ID:", error);
      throw error;
    }
  };
  
  const deleteMediaById = async (id) => {
    try {
      const res = await api.delete(GET_MEDIA_IMAGE_BY_ID_URL(id)); 
      return res;
    } catch (error) {
      console.error("Error deleting media:", error);
      throw error;
    }
  };

  const getAllMedia = async (skip, limit) => {
    try {
      // Passing skip and limit as query parameters
      const res = await api.get(GET_ALL_MEDIA_IMAGES_URL, { // Removed the parentheses
        params: { skip, limit },
      });
      return res;
    } catch (error) {
      console.error("Error fetching media images:", error);
      throw error;
    }
  };


export { uploadMedia,getMediaImagesByUser,getMediaImageById ,deleteMediaById,getAllMedia};
