import React from 'react';
import { FaTrashAlt } from 'react-icons/fa'; // Import trash icon

const FeedbackCard = ({ feedback, onView, onDelete, companyUid }) => {
  // Truncate description if longer than 50 characters
  const truncatedDescription = feedback.description.length > 20 ? feedback.description.substring(0, 20) + '...' : feedback.description;

  // Check if the date is valid, otherwise show 'No Date'
  const feedbackDate = feedback.created_on ? new Date(feedback.created_on) : null;
  const formattedDate = feedbackDate && !isNaN(feedbackDate) ? feedbackDate.toLocaleDateString('en-GB') : 'No Date';

  return (
    <div className="bg-gray-200 rounded-lg p-4 flex justify-between items-center mb-4 shadow-md w-full">
      <div className="flex items-center w-full">
        {/* Profile Image */}
        <img
          src={feedback.feedbacker_profile_image || "/path_to_default_image"} // Use feedbacker_profile_image directly from feedback object
          alt="Profile"
          className="rounded-full w-12 h-12 mr-4"
        />

        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center w-full">
            {/* Display the feedbacker's name */}
            <div className="font-bold text-lg">{feedback.feedbacker_company_name || "No Name"}</div>

            {/* Format and display the date */}
            <div className="text-gray-500 text-sm">
              {formattedDate} {/* Display the formatted date or 'No Date' if invalid */}
            </div>
          </div>

          {/* Feedback Description */}
          <div className="text-sm mt-2">
            {truncatedDescription}
            {/* More link */}
            <a href="#" className="text-[#6D6D6D] font-bold ml-2 underline" onClick={() => onView(feedback)}>
              more...
            </a>
          </div>
        </div>
      </div>

      {/* Show delete icon only if feedback was submitted by the current user */}
      {feedback.feedback_by === companyUid && (
        <FaTrashAlt 
          className="text-red-600 cursor-pointer ml-4" 
          onClick={() => onDelete(feedback)} // Call onDelete when delete icon is clicked
        />
      )}
    </div>
  );
};

export default FeedbackCard;
