import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import { Link } from 'react-router-dom';

const AccountPage = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const handleToggle = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#ffffff] font-poppins">
      <Sidebar />
      <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
        <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Account</h1>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex justify-center items-center w-4/5 mt-10 mx-auto lg:ml-8">
        <div className="bg-gray-300 rounded-lg w-full md:w-[700px] p-5 md:p-10">
          <div className="flex justify-between mb-5 ">
            <div className="flex items-center space-x-3">
              <img src="Assets/notification.svg" alt="Notification Icon" className="w-5 h-5" />
              <span className="text-sm md:text-lg font-semibold">Notifications</span>
            </div>
            <div
              className={`cursor-pointer w-7 h-4 md:w-9 md:h-6 flex items-center bg-[#171717] rounded-full p-1 ${notificationsEnabled ? 'bg-[#171717]' : 'bg-[#171717]'}`}
              onClick={handleToggle}
            >
              <div
                className={`bg-[#737373] w-3 h-3 md:w-4 md:h-4 rounded-full transform ${notificationsEnabled ? 'translate-x-3 md:translate-x-3' : ''}`}
              ></div>
            </div>
          </div>
          
          <Link to="/forgot-password-page">
            <div className="flex items-center space-x-3 cursor-pointer mb-5">
              <img src="Assets/security.svg" alt="Change Password Icon" className="w-5 h-5" />
              <span className="text-sm md:text-lg font-semibold">Change Password</span>
            </div>
          </Link>
          {/* <Link to="/details_phone">
            <div className="flex items-center space-x-3 cursor-pointer mb-5">
              <img src="/Assets/phone.svg" alt="Change Password Icon" className="w-5 h-5" />
              <span className="text-sm md:text-lg font-semibold">Change PhoneNumber</span>
            </div>
          </Link> */}
          {/* <Link to="/email">
            <div className="flex items-center space-x-3 cursor-pointer mb-5">
              <img src="/email23.svg" alt="Change Password Icon" className="w-5 h-5" />
              <span className="text-sm md:text-lg font-semibold">Change Email</span>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
