import React from 'react';
import Image2 from '../../Components/login_image2.jsx';
import PasswordInputField from '../../Components/password-input-field.jsx';
import Button from '../../Components/button.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import InputBox from '../../Components/input_box.jsx';
import { ACCESS_TOKEN } from '../../../Controller/Constants/constants.js';
import { REFRESH_TOKEN } from '../../../Controller/Constants/constants.js';
import { check_email } from '../../../Controller/auth.js';
import { showToast } from '../../Components/toast.jsx';
import LoaderSpinner from '../../Components/loader_spinner.jsx';
import { resetPasswordMail } from '../../../Controller/auth.js';
import CenteredImageContainer from '../../Components/black_container.jsx';
// const SignUp = () => {
//   const [company, setCompany] = useState('');
//   const[email,setEmail]=useState('')


//   const handleCompanyChange = (e) => {
//     setCompany(e.target.value);
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   return (
//     <div className="bg-[#DB0000] h-full flex flex-col md:flex-row p-6 overflow-y-auto ">
//       <div className="w-full md:w-1/2 flex flex-col justify-center items-center mb-6 md:mb-0">
//         <div className="mb-3 h-22 w-22">
//           <img src="./image1.svg" alt="image1" />
//         </div>
//         <div className="mt-12">
//           <Image2 imgSrc="./image2.svg" />
//         </div>
//       </div>
//       <div className="w-full md:w-1/2 flex justify-center items-center p-2">
//         <div className="bg-[#F2F2F2] p-8 rounded-lg shadow-lg" style={{ width: '34rem', height: '23rem' }}>
//           <h1 className="text-2xl font-poppins font-normal mb-4 text-[#171717]">Sign up</h1>
//           <form className="flex flex-col space-y-5">
//             <InputBox
//               type="email"
//               placeholder="Company Name"
//               value={company}
//               onChange={handleCompanyChange}
//               borderRadius="4px"
//               backgroundColor="#F2F2F2"
//               className="placeholder-gray-700 font-helvetica h-full"
//               border="2px solid rgba(162, 162, 162, 1)"
//               padding="10px"
//             />
//             <div className="w-full">
            


//             <InputBox
//               type="email"
//               placeholder="Email"
//               value={email}
//               onChange={handleEmailChange}
//               borderRadius="4px"
//               backgroundColor="#F2F2F2"
//               className="placeholder-gray-700 font-helvetica h-full"
//               border="2px solid rgba(162, 162, 162, 1)"
//               padding="10px"
//             />

//             </div>
         
//             <div className="flex justify-center mt-4 w-full">
//               <div className="w-2/3 mt-3">
//                 <Button text="Sign up" navigateTo="/email-verification"   backgroundColor="bg-custom-button-color" 
//               textColor="text-white" 
//               className="w-full md:w-3/4 md:ml-4" />
//               </div>
//             </div>
//           </form>
         
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;



// const SignUp = () => {
//   const [company, setCompany] = useState('');
//   const[email,setEmail]=useState('')


//   const handleCompanyChange = (e) => {
//     setCompany(e.target.value);
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   return (
//     <div className="bg-[#DB0000] h-full flex flex-col md:flex-row p-6 overflow-y-auto ">
//       <div className="w-full md:w-1/2 flex flex-col justify-center items-center mb-6 md:mb-0">
//         <div className="mb-3 h-22 w-22">
//           <img src="./image1.svg" alt="image1" />
//         </div>
//         <div className="mt-12">
//           <Image2 imgSrc="./image2.svg" />
//         </div>
//       </div>
//       <div className="w-full md:w-1/2 flex justify-center items-center p-2">
//         <div className="bg-[#F2F2F2] p-8 rounded-lg shadow-lg" style={{ width: '34rem', height: '23rem' }}>
//           <h1 className="text-2xl font-poppins font-normal mb-4 text-[#171717]">Sign up</h1>
//           <form className="flex flex-col space-y-5">
//             <InputBox
//               type="email"
//               placeholder="Company Name"
//               value={company}
//               onChange={handleCompanyChange}
//               borderRadius="4px"
//               backgroundColor="#F2F2F2"
//               className="placeholder-gray-700 font-helvetica h-full"
//               border="2px solid rgba(162, 162, 162, 1)"
//               padding="10px"
//             />
//             <div className="w-full">
            


//             <InputBox
//               type="email"
//               placeholder="Email"
//               value={email}
//               onChange={handleEmailChange}
//               borderRadius="4px"
//               backgroundColor="#F2F2F2"
//               className="placeholder-gray-700 font-helvetica h-full"
//               border="2px solid rgba(162, 162, 162, 1)"
//               padding="10px"
//             />

//             </div>
         
//             <div className="flex justify-center mt-4 w-full">
//               <div className="w-2/3 mt-3">
//                 <Button text="Sign up" navigateTo="/email-verification"   backgroundColor="bg-custom-button-color" 
//               textColor="text-white" 
//               className="w-full md:w-3/4 md:ml-4" />
//               </div>
//             </div>
//           </form>
         
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;

import { sendResetPasswordEmail } from '../../../Controller/reset_password_mail_controller.js';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    sendResetPasswordEmail(email, navigate, setLoading); // Call the controller function
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-7 font-poppins">
        <div className="p-6 w-full max-w-2/5 space-y-5">
          <div className="flex items-center mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <h1 className="text-2xl font-poppins font-semibold mb-4 text-[#171717]">Login Help</h1>
          <form className="flex flex-col space-y-7" onSubmit={handleSignUp}>
            <div className="space-y-3">
              <p className="text-base font-poppins font-semibold text-[#171717]">Find your Account</p>
              <p className="text-sm font-poppins text-[#171717]">Enter the Email linked to your Account</p>
            </div>
            <InputBox
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              borderRadius="4px"
              className="placeholder-gray-700 font-helvetica h-full"
              border="2px solid rgba(162, 162, 162, 1)"
              padding="10px"
            />
            <div className="">
              <Button
                text={loading ? <LoaderSpinner visible={loading} /> : "Next"}
                backgroundColor="bg-custom-button-color"
                textColor="text-white"
                type="submit"
                disabled={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
