import React, { useEffect, useState } from "react";
import './hexagon_body.css';

const smallScreenUrls = {
    logistics: './logisticsmobile.svg',
    financial: './financialinstituitionsmobile.svg',
    startup: './startupmobile.png',
    legal: './legal_mobile.svg',
    preciousstones: './precious_mobile.png',
    invest: './investormobile.png',
    seller: './sellermobile.png',
    marketplace: './marketplacemobile.png',
    projects: './projectsmobile.png',
    buyer: './buyermobile35827.svg'
  };
  
  const largeScreenUrls = {
    logistics: './logistics.svg',
    financial: './finacial.svg',
    startup: './startup.png',
    legal: './legal.png',
    preciousstones: './preciousstones.png',
    invest: './invest.png',
    seller: './seller.png',
    marketplace: './marketplace.png',
    projects: './projects.png',
    buyer: './buyer.png'
  };
  
  function HexGrid() {
      const [imageUrls, setImageUrls] = useState(largeScreenUrls);
  
      useEffect(() => {
          const handleResize = () => {
              if (window.innerWidth <= 768) {
                  setImageUrls(smallScreenUrls);
              } else {
                  setImageUrls(largeScreenUrls);
              }
          };
  
          handleResize(); // Set the initial state
          window.addEventListener('resize', handleResize);
  
          return () => window.removeEventListener('resize', handleResize);
      }, []);
  
      return (
          <div className="hex-grid-container-wrapper">
              <div className='hex-grid-container'>
                  <div className='hex-item logistics'>
                      <img src={imageUrls.logistics} alt="logistics" />
                  </div>
                  <div className='hex-item financial'>
                      <img src={imageUrls.financial} alt="financialinstitutions" />
                  </div>
                  <div className='hex-item startup'>
                      <img src={imageUrls.startup} alt="startup" />
                  </div>
                  <div className='hex-item legal'>
                      <img src={imageUrls.legal} alt="legal" />
                  </div>
                  <div className='hex-item preciousstones'>
                      <img src={imageUrls.preciousstones} alt="preciousstones" />
                  </div>
                  <div className='hex-item invest'>
                      <img src={imageUrls.invest} alt="invest" />
                  </div>
                  <div className='hex-item seller'>
                      <img src={imageUrls.seller} alt="seller" />
                  </div>
                  <div className='hex-item marketplace'>
                      <img src={imageUrls.marketplace} alt="marketplace" />
                  </div>
                  <div className='hex-item projects'>
                      <img src={imageUrls.projects} alt="projects" />
                  </div>
                  <div className='hex-item buyer'>
                      <img src={imageUrls.buyer} alt="buyer" />
                  </div>
              </div>
          </div>
      );
  }
  
  export default HexGrid;
  