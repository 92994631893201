// import React from 'react';
// import Sidebar from '../Components/MainNavbar';
//import LeftSidebar from '../Components/left_sidebar';
// import ActivePlans from '../Components/active_plans';

// const Subscription = () => {
//   return (
//     <div className="h-full overflow-hidden">
//     <div className="w-full flex mt-11 ml-12">
//         <h1 className=" text-2xl font-normal leading-[30.14px] text-black">My Subscription</h1>
//     </div>
//     <div className="w-full h-0 mt-6 border-t border-black"></div>
//     <ActivePlans/>
//     <div>
//         <button className="text-red-500 font-bold py-2 px-4 rounded" style={{ width: '420px', height: '60px', top: '641px', left: '198px', gap: '0px', borderRadius: '8px ', border: '1px solid #DB0000' }}>
//           Other Subscription Plans
//         </button>
//         </div>
//     </div>
         
      

//       /*<div className="flex justify-between items-center pt-">
//         <h1 className="font-techna text-[24px] font-normal leading-[30.14px]">
//           My Subscription
//         </h1>
//       </div>
//       <div className="border-b border-black py-6 "></div>
//       {/* Rest of the content */
     
      
      
      
      
    
    
//   );
// };

// export default Subscription;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import CurrentPlan from '../../Components/current_plan';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';

const Subscription = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
    <Sidebar/>
    <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
              <div className="w-full flex justify-start items-center space-x-2">
                  
                <img
                  src="/Assets/arrow_back.svg"
                  alt="Back"
                  className="cursor-pointer mr-2 h-6 w-6"
                  onClick={handleBackClick}
                />
                <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription</h1>  
                 </div>
              
            </div>
        <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <div className="w-full md:w-[600px] h-auto md:h-[700px] mt-7 p-4 md:ml-64">
        <p className="font-bold text-base sm:text-lg">Active Plans</p>
        
          <CurrentPlan />
       
        <div className="text-right mt-7">
          <a
            href="#"
            className="text-red-600 underline mt-2 md:mt-4 text-sm md:text-base lg:text-lg"
          >
            Cancel Plan
          </a>
        </div>
        <div className="mt-12 mx-4 md:mx-20">
          <Button
            text="Other Subscription Plans"
            borderColor="border-red-600"
            textColor="text-red-600"
            fontWeight="bold"
            navigateTo="/subscription_plans"
          />
        </div>
      </div>
    </div>
  );
};

export default Subscription;
