// import api from "./api";
// import { CREATE_MANAGEMENT_MEMBER_URL } from "./urls";


// // Get all company members
// const getCompanyMembers = async () => {
//     return await api.get("/management/");
// }


// // Create a new management member
// const createManagementMember = async (name,position,image_url) => {
//     const res = await api.post(CREATE_MANAGEMENT_MEMBER_URL, {
//         name: name,
//         position:position,
//         image_url:image_url
//     });
//     return res;
// }

// // Get a specific member by ID
// const getMember = async (id) => {
//     return await api.get(`/management/${id}`);
// }

// // Update a specific member by ID
// const updateMember = async (id, memberObject) => {
//     return await api.put(`/management/${id}`, memberObject);
// }

// // Delete a specific member by ID
// const deleteMember = async (id) => {
//     return await api.delete(`/management/${id}`);
// }

// export { getCompanyMembers, createManagementMember, getMember, updateMember, deleteMember };
import { api } from "./api";
import { CREATE_MANAGEMENT_MEMBER_URL, GET_COMPANY_MEMBERS_URL, UPDATE_MEMBER_URL, DELETE_MEMBER_URL, DELETE_COMPANY_MEMBERS_URL,GET_MEMBERS_BY_COMPANY_URL } from "./urls";


const getCompanyMembers = async () => {
  const res = await api.get(GET_COMPANY_MEMBERS_URL);
  return res.data; // Assuming the data is in res.data
};



const createManagementMember = async (name, position, image_url) => {
    const res = await api.post(CREATE_MANAGEMENT_MEMBER_URL, {
      name: name,
      position: position,
      image_url: image_url
    });
    return res.data; 
  };
  

  const updateManagementMember = async (id, name, position, image_url) => {
    const res = await api.put(UPDATE_MEMBER_URL(id), {
      name: name,
      position: position,
      image_url: image_url
    });
    return res.data;
  };
  


const deleteManagementMember = async (id) => {
  const res = await api.delete(DELETE_MEMBER_URL(id));
  return res.data;
};
 
const getmembersbyuid = async (company_uid, skip = 0, limit = 10) => {
  const res = await api.get(GET_MEMBERS_BY_COMPANY_URL(company_uid, skip, limit));
  return res.data; // Assuming the data is in res.data
};

export { getCompanyMembers, createManagementMember, updateManagementMember, deleteManagementMember,getmembersbyuid };
