import React from 'react';
import Steps from '../../Components/company_list_tile';
import Sidebar from '../../Components/main_navbar';
const CompanyPhotosPage = () => {
  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins ">
    <Sidebar />
    <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
      <h1 className="text-black font-bold text-xl">Company Photos</h1>
    </div>
    <div className="border-b border-black mt-4 md:mt-[25px]"></div>
        {/* <div className="flex flex-col items-center mx-auto justify-center p-4 w-4/5 space-y-7 lg:ml-7 pl-7"> */}
        <div className="flex flex-col mx-auto items-center mt-10 lg:ml-7 lg:w-4/5  overflow-auto">
          <h2 className="text-center text-lg font-semibold">Add company Photos One by one</h2>
          <div className="max-w-lg w-full space-y-5">
            <Steps 
              text={
                <>
                  <p className="text-lg font-bold font-poppins">Photo of the Company Signboard from Outside</p>
                  <p className="text-sm font-poppins">Provide a photo of the company board as seen from the outside.</p>
                  

                </>
                
              }
              destination="/photo-signboard"
            />
            <Steps 
              text={
                <>
                  <p className="text-lg font-bold">Interior Photo of the Company Premises</p>
                  <p className="text-sm">Provide a photo of the inside of the company premises.</p>
                </>
              }
              destination="/photo-premises"
            />
            <Steps 
              text={
                <>
                  <p className="text-lg font-bold">Group Photo of the Company Crew</p>
                  <p className="text-sm">Provide a photo of the company team for our records.</p>
                </>
              }
              destination="/photo-crew"
            />
            <Steps 
              text={
                <>
                  <p className="text-lg font-bold">Photo of the CEO in the Office</p>
                  <p className="text-sm">Please share a photo of the CEO in their office. This is required.</p>
                </>
              }
              destination="/photo-ceo"
            />
          </div>
        </div>
      </div>
    
  );
};

export default CompanyPhotosPage;
