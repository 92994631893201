import React, { useState } from 'react';
import uploadImage from '../../Controller/firebase/firebase_upload_image'; // Your image upload function
import LoaderSpinner from './loader_spinner'; // Assuming you have a loader component

const ReviewImageUpload = ({ reviewImages, setReviewImages, svgImagePath, bgColor, borderRadius, width, height }) => {
  const [loading, setLoading] = useState(false);

  const handleReviewImageUpload = async (e) => {
    const uploadedFiles = Array.from(e.target.files); // Convert the FileList into an array
    setLoading(true);

    try {
      // Upload all selected files and retrieve their URLs
      const uploadedFileUrls = await Promise.all(
        uploadedFiles.map(async (file) => {
          const downloadURL = await uploadImage(file); // Upload the file and get the download URL
          return downloadURL; // Return the download URL
        })
      );

      // Append new URLs to the existing list of review images
      setReviewImages([...reviewImages, ...uploadedFileUrls]); 
    } catch (error) {
      console.error("Error uploading review image:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to remove a review image from the list
  const removeReviewImage = (index) => {
    setReviewImages(reviewImages.filter((_, i) => i !== index));
  };

  return (
    <div className="flex">
      {/* Review Image Upload Button */}
      <label
        htmlFor="review-image-upload"
        className="cursor-pointer flex items-center justify-center"
        style={{
          backgroundColor: bgColor || '#C1C1C1',
          borderRadius: borderRadius || '8px',
          width: width || '80px',
          height: height || '90px',
        }}
      >
        {loading ? (
          <LoaderSpinner visible={true} />
        ) : (
          <>
            <img src={svgImagePath} alt="Upload" className="w-5 h-5" />
            <input
              type="file"
              accept="image/*" // Accept only image files
              multiple // Allow multiple file selection
              onChange={handleReviewImageUpload} // Call the file upload handler
              className="hidden"
              id="review-image-upload" // Ensure a unique ID
            />
          </>
        )}
      </label>

      {/* Display uploaded review images in a row */}
      <div className="flex ml-4 space-x-4">
        {reviewImages.map((url, index) => (
          <div key={index} className="relative" style={{ width: width || '80px', height: height || '90px' }}>
            {/* Uploaded review image preview */}
            <img
              src={url}
              alt="review"
              className="object-cover w-full h-full"
              style={{ borderRadius: borderRadius || '8px', backgroundColor: bgColor || '#E6E6E6' }}
            />
            {/* Remove button for each review image */}
            <button
              className="absolute top-0 right-0 text-white rounded-full p-1"
              onClick={() => removeReviewImage(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewImageUpload;
