import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SharePopup from '../../Components/share_alert';
import VerifiedPopup from '../../Components/pop_up';
import Sidebar from '../../Components/main_navbar';
import { get_all_User_video } from '../../../Controller/upload_videos';
import { FaSpinner } from 'react-icons/fa'; // Importing the spinner icon

const Video = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const videoUrl = queryParams.get('video');

    const [videos, setVideos] = useState([]); // State to store videos
    const [skip, setSkip] = useState(0); // For pagination
    const limit = 5; // Limit to 5 videos per fetch
    const [loading, setLoading] = useState(false); // Loading state for spinner
    const [hasMore, setHasMore] = useState(true); // To know if there are more videos to load
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showVerifiedPopup, setShowVerifiedPopup] = useState(false);
    const [showReportOptions, setShowReportOptions] = useState(false);
    const [reportSelected, setReportSelected] = useState(false);

    const videoContainerRef = useRef(null); // Ref for scrolling

    // Fetch videos from API with pagination
    const fetchVideos = async () => {
        if (loading || !hasMore) return; // Prevent fetching if already loading or no more videos
        setLoading(true);

        console.log("Fetching videos with skip:", skip, "and limit:", limit); // Log skip and limit
        try {
            const res = await get_all_User_video(skip, limit); // API call
            console.log("API Response:", res); // Log API response

            if (res.status === 200 && res.data.data.length > 0) {
                const newVideos = res.data.data;

                // Use a functional update to ensure we append new videos correctly
                setVideos((prevVideos) => [...prevVideos, ...newVideos]);

                // Check if less than limit videos are returned, indicating no more videos
                if (newVideos.length < limit) {
                    setHasMore(false); // No more videos to load
                }
            } else {
                setHasMore(false); // No more videos to load
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            setLoading(false);
            console.log('Total videos after fetching:', videos.length); // Debugging total videos
        }
    };

    // Load the first set of videos on component mount
    useEffect(() => {
        fetchVideos(); // Fetch the first set of videos on mount
    }, [skip]); // Triggered every time skip changes

    // Infinite scroll: load more when user scrolls near the bottom
    const handleScroll = () => {
        if (videoContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = videoContainerRef.current;
            // When user scrolls close to the bottom
            if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && hasMore) {
                console.log('Reached bottom, fetching more videos...');
                setSkip((prevSkip) => prevSkip + limit); // Increment skip for the next batch
            }
        }
    };

    useEffect(() => {
        if (videoContainerRef.current) {
            videoContainerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (videoContainerRef.current) {
                videoContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading, hasMore]); // Re-run when loading or hasMore changes

    const togglePlayPause = (videoRef) => {
        if (videoRef.paused) {
            videoRef.play();
        } else {
            videoRef.pause();
        }
    };

    const handleShareClick = () => setShowSharePopup(true);
    const handleCloseSharePopup = () => setShowSharePopup(false);
    const handleVerifiedClick = () => setShowVerifiedPopup(true);
    const handleCloseVerifiedPopup = () => setShowVerifiedPopup(false);
    const toggleReportOptions = () => setShowReportOptions(!showReportOptions);
    const handleReportClick = () => setReportSelected(!reportSelected);

    return (
        <div className="flex w-full h-full bg-black">
            <Sidebar />
            <div className="flex-1 flex flex-col items-center justify-center overflow-y-auto" ref={videoContainerRef}>
                {/* Discover Section */}
                <div className="sticky top-0 w-full bg-black z-10 p-4">
                    {/* Sticky header to stay on top */}
                    <div className="flex items-center">
                        <div className="w-14 h-15 bg-white rounded-md p-1">
                            <Link to="/discover-page">
                                <img src="/discover-box.svg" alt="Discover" className="w-12 h-12" />
                            </Link>
                        </div>
                        <h1 className="text-white font-bold text-2xl ml-4">Discover</h1>
                    </div>
                    <div className="border-b border-gray-900 mt-2"></div> {/* Border below Discover */}
                </div>

                {/* Display all videos below the Discover heading */}
                <div className="p-2 flex flex-col items-center space-y-4 mt-[260px]">
                    {/* Increased top margin */}
                    {videos.map((video, index) => (
                        <div key={index} className="relative">
                            <video
                                src={video.video_url}
                                className="rounded-lg"
                                style={{ width: '350px', height: '540px', objectFit: 'cover' }}
                                onClick={(e) => togglePlayPause(e.target)}
                            />
                            {/* Displaying 4 icons on the left */}
                            <div className="absolute top-24 mt-56 right-2 flex flex-col space-y-7">
                                <img 
                                    src="/report.svg" 
                                    alt="Report" 
                                    className="w-6 h-6 cursor-pointer" 
                                    onClick={toggleReportOptions} 
                                />
                                <img 
                                    src="/follow.svg" 
                                    alt="Follow" 
                                    className="w-6 h-6" 
                                />
                                <img 
                                    src="/share.svg" 
                                    alt="Share" 
                                    className="w-6 h-6 cursor-pointer" 
                                    onClick={handleShareClick} 
                                />
                                <img 
                                    src="/verified.svg" 
                                    alt="Verified" 
                                    className="w-6 h-6 cursor-pointer" 
                                    onClick={handleVerifiedClick} 
                                />
                            </div>

                            {/* Show Report options when report icon is clicked */}
                            {showReportOptions && (
                                <div className="absolute top-24 mt-56 right-10 w-40 bg-gray-800 p-2 rounded-md">
                                    <p 
                                        className={`text-sm cursor-pointer p-2 rounded-md ${reportSelected ? 'text-red-500' : 'text-white'} hover:text-red-500 hover:bg-gray-700`} 
                                        onClick={handleReportClick}
                                    >
                                        Report
                                    </p>
                                    <p className="text-sm text-white cursor-pointer p-2 rounded-md hover:text-red-500 hover:bg-gray-700">Follow</p>
                                </div>
                            )}

                            {/* Use default image if logo not available */}
                            <div className="absolute inset-x-0 top-10 bottom-0 w-full bg-opacity-70 p-3 rounded-b-lg mt-[320px]">
                                <div className="flex items-center space-x-2 mb-1">
                                    <img 
                                        src={video.logo || './profile.webp'} 
                                        alt="ID" 
                                        className="w-7 h-7 rounded-full" 
                                    />
                                    <p className="text-white">{video.title}</p>
                                    {video.verified && (
                                        <img
                                            src="/tick.svg"
                                            alt="Verified"
                                            className="w-4 h-4 cursor-pointer"
                                            onClick={handleVerifiedClick}
                                        />
                                    )}
                                </div>
                                <p className="text-sm text-white pr-5">
                                    {video.description.length > 100
                                        ? `${video.description.substring(0, 100)}...`
                                        : video.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Show loading spinner */}
                {loading && (
                    <div className="mt-4 text-white">
                        <FaSpinner className="animate-spin" size="2em" />
                    </div>
                )}

                {showSharePopup && <SharePopup onClose={handleCloseSharePopup} />}
                {showVerifiedPopup && <VerifiedPopup onClose={handleCloseVerifiedPopup} />}
            </div>
        </div>
    );
};

export default Video;
