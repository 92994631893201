import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

const CountryDropdown = ({ selectedCountry, onChange, countries }) => {
  const [isOpen, setIsOpen] = useState(false); // Manage dropdown visibility
  const [searchQuery, setSearchQuery] = useState(''); // Manage search input

  // Find the selected country data (for displaying flag and country name)
  const selectedCountryData = countries.find(country => country.country === selectedCountry);

  // Handle when a country is selected
  const handleOptionClick = (country) => {
    onChange({ target: { value: country.country } });
    setIsOpen(false); // Close dropdown after selecting
    setSearchQuery(''); // Reset search input
  };

  // Toggle dropdown visibility when the arrow icon is clicked
  const handleIconClick = () => {
    setIsOpen(!isOpen);
  };

  // Filter countries to prioritize those that start with the search query
  const filteredCountries = countries
    .filter(country =>
      country.country.toLowerCase().startsWith(searchQuery.toLowerCase()) // Countries that start with the search query
    )
    .concat(
      countries.filter(country =>
        !country.country.toLowerCase().startsWith(searchQuery.toLowerCase()) && // Avoid duplicates
        country.country.toLowerCase().includes(searchQuery.toLowerCase()) // Countries that contain the search query
      )
    );

  return (
    <div className="relative inline-block w-full">
      <div 
        className="flex items-center w-full bg-white border border-gray-300 rounded-md leading-none h-10 cursor-pointer"
        onClick={handleIconClick} // Toggle dropdown when clicking the main box
      >
        {/* Display selected country flag or placeholder flag */}
        {selectedCountryData ? (
          <img
            src={selectedCountryData.flag}
            alt={selectedCountryData.country}
            className="h-5 w-5 ml-2"
          />
        ) : (
          <img
            src="./flag-ui.svg"  // Default placeholder flag
            alt="Default flag"
            className="h-5 w-5 ml-4"
          />
        )}

        {/* Display selected country name or placeholder */}
        <div className="flex-grow px-2 text-gray-700 text-sm">
          {selectedCountry ? selectedCountry : 'Select a country'}
        </div>

        {/* Partition line */}
        <div className="h-full w-[1px] bg-gray-300 mx-4"></div>

        {/* Dropdown arrow icon */}
        <RiArrowDropDownLine className="text-2xl text-[#DB0000] mr-4" />
      </div>

      {/* Dropdown list */}
      {isOpen && (
        <div className="absolute z-50 w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg">
          {/* Search input */}
          <input
            type="text"
            placeholder="Search country..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-3 py-2 border-b border-gray-300 focus:outline-none"
          />
          
          {/* Filtered country list */}
          <ul className="max-h-60 overflow-auto">
            {filteredCountries.length > 0 ? (
              filteredCountries.map(country => (
                <li
                  key={country.country_code}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                  onClick={() => handleOptionClick(country)} // Select country on click
                >
                  <div className="flex items-center">
                    <img src={country.flag} alt={country.country} className="h-5 w-5 mr-2" />
                    {country.country}
                  </div>
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-gray-500">No countries found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;
