
// import React from "react";

// import { useNavigate } from "react-router-dom";
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";

// import StarRating from "../../Components/star_rating";
// import ImageUpload from "../../Components/image_upload";
// import InputBox from "../../Components/input_box";
// import TextArea from "../../Components/text_area";
// import AddDocument from "../../Components/add_doc";
// import { useState } from "react";

// const ReviewAdd = () => {
//   const navigate = useNavigate();
//   const [productName, setProductName] = useState('');
//   const [review, setReview] = useState('');
//   const [rating, setRating] = useState(0);
//   const [images, setImages] = useState([]);
  
//   const starRatingStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   };

//   const starStyles = {
//     margin: ' 10px', // Adjust the spacing as needed
//     cursor: 'pointer'
//   };



//   const handleBackClick = () => {
//     navigate('');
//   };
//   const OnClick = () => {
//     navigate('/report_page'); 
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//       <Sidebar />
//       <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
//             <div className="w-full flex justify-start items-center space-x-2">
                
//               <img
//                 src="/Assets/arrow_back.svg"
//                 alt="Back"
//                 className="cursor-pointer mr-2 h-6 w-6"
//                 onClick={handleBackClick}
//               />
//               <h1 className="text-black font-semibold text-xl">Reviews</h1>   </div>
//                <div className="mr-5 md:mr-7 pr-5 flex self-end"> 
//           <img
//             src="/Assets/dots.svg" // Make sure this path is correct
//             alt="Upload"
//             className="cursor-pointer h-5 w-5"
//             onClick={OnClick}
//           />
//              </div>
//           </div>
//           <div className="border-b border-black mt-4 md:mt-[25px]"></div>
           
//           <div className="w-4/5 ml-7">
//           <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-poppins" style={{ height: '600px', width: '450px' }}>
//           <h3 className="text-sm md:text-base font-semibold mb-4 text-[#4A4A4A]">Rate the Product</h3>
//               <div style={starRatingStyles}>
//                   {[1, 2, 3, 4, 5].map((star) => (
//                       <svg
//                           key={star}
//                           style={starStyles}
//                           className={rating >= star ? 'filled' : 'empty'}
//                           onClick={() => setRating(star)}
//                           viewBox="0 0 24 24"
//                           width="29"
//                           height="29"
//                           fill={rating >= star ? '#DB0000' : '#F2F3F3'}
//                           stroke={rating >= star ? '#DB0000' : '#DB0000'}
//                           strokeWidth="2"
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                       >
//                           <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
//                       </svg>
//                   ))}
//               </div>
//               <p className="text-sm md:text-base font-semibold mb-4 text-[#4A4A4A]">Add Review</p>
//               <InputBox
//                   value={productName}
//                   onChange={(e) => setProductName(e.target.value)}
//                   placeholder="Product Name"
//                   borderRadius="1px"
//               />
//               <TextArea
//                   value={review}
//                   onChange={(e) => setReview(e.target.value)}
//                   placeholder="Sleek design, great performance. Earbuds are worth every penny."
//                   className="mt-6"
//               />
             
//               <h3 className="text-sm md:text-base font-semibold mt-4 mb-2 text-[#4A4A4A]">Confirmation of your purchase</h3>
//               <AddDocument label="Add Documents" />
//               {/* <ImageUpload images={images} setImages={setImages} svgImagePath="/Assets/review.svg" /> */}
//               <div className="mt-9">
//               <Button
//                   text="Submit"
//                   backgroundColor="bg-[#DB0000]"
//                   textColor="text-white" 
//                   navigateTo="/reviews_page"
                  
//               />
//               </div>
//           </div>
//       </div>
//       </div>
//   );
// };

// export default ReviewAdd;
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";
// import StarRating from "../../Components/star_rating";
// import InputBox from "../../Components/input_box";
// import TextArea from "../../Components/text_area";
// import AddDocument from "../../Components/add_doc";
// import FileUpload from "../../Components/image_upload"; // Adjust the import path as needed
// import { submitReview } from "../../../Controller/reviews"; // Adjust the import path for the API call

// const ReviewAdd = () => {
//   const navigate = useNavigate();
//   const [productName, setProductName] = useState('');
//   const [review, setReview] = useState('');
//   const [rating, setRating] = useState(0);
//   const [files, setFiles] = useState([]); // To handle file uploads
//   const [document, setDocument] = useState(''); // Confirmation document
//   const reviewing_uid = 'your-reviewing-uid'; // Replace with the actual reviewing_uid

//   const starRatingStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   };

//   const starStyles = {
//     margin: '10px',
//     cursor: 'pointer',
//   };

//   const handleBackClick = () => {
//     navigate('');
//   };

//   const OnClick = () => {
//     navigate('/report_page');
//   };

//   // Handle Submit - Call the Review API
//   const handleSubmit = async () => {
//     // Prepare the review data
//     const reviewData = {
//       rating: rating,
//       title: productName,
//       description: review,
//       image_url: files.map(file => file.url), // Extract URLs from uploaded files
//       confirmation_document: document, // Confirmation document
//     };

//     try {
//       const response = await submitReview(reviewing_uid, reviewData);
//       if (response.status === 201) {
//         console.log("Review Created Successfully", response);
//         // Optionally navigate to a success page or show a message
//         navigate('/reviews_page'); // Navigate to reviews page after success
//       }
//     } catch (error) {
//       console.error("Error submitting the review:", error);
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-semibold text-xl">Reviews</h1>
//         </div>
//         <div className="mr-5 md:mr-7 pr-5 flex self-end">
//           <img
//             src="/Assets/dots.svg"
//             alt="Upload"
//             className="cursor-pointer h-5 w-5"
//             onClick={OnClick}
//           />
//         </div>
//       </div>
//       <div className="border-b border-black mt-2 md:mt-[25px]"></div>
//       <div className="w-4/5 ml-7">
//         <div
//           className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 bg-[] rounded-lg font-poppins"
//           style={{ height: '600px', width: '450px' }}
//         >
//           <h3 className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Rate the Product</h3>
//           <div style={starRatingStyles}>
//             {[1, 2, 3, 4, 5].map((star) => (
//               <svg
//                 key={star}
//                 style={starStyles}
//                 className={rating >= star ? 'filled' : 'empty'}
//                 onClick={() => setRating(star)}
//                 viewBox="0 0 24 24"
//                 width="29"
//                 height="29"
//                 fill={rating >= star ? '#DB0000' : '#FFFFFF'}
//                 stroke={rating >= star ? '#DB0000' : '#DB0000'}
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               >
//                 <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
//               </svg>
//             ))}
//           </div>
//           <p className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Add Review</p>
//           <InputBox
//             value={productName}
//             onChange={(e) => setProductName(e.target.value)}
//             placeholder="Product Name"
//             borderRadius="1px"
//           />
//           <TextArea
//             value={review}
//             onChange={(e) => setReview(e.target.value)}
//             placeholder="Sleek design, great performance. Earbuds are worth every penny."
//             className="mt-4"
//           />
//           <h3 className="text-sm md:text-base font-semibold mt-2 mb-2 text-[#4A4A4A]">Confirmation of your purchase</h3>
//           <AddDocument label="Add Documents" setDocument={setDocument} />

//           {/* Using FileUpload Component */}
//           <FileUpload
//             files={files}
//             setFiles={setFiles}
//             svgImagePath="/Assets/review.svg"
//             bgColor="#C4C4C4"
//             borderRadius="8px"
//             width="80px"
//             height="90px"
//           />

//           <div className="mt-2">
//             <Button
//               text="Submit"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSubmit} // Trigger submit on button click
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReviewAdd;
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";
// import InputBox from "../../Components/input_box";
// import TextArea from "../../Components/text_area";
// import AddDocument from "../../Components/add_doc";
// import ReviewImageUpload from "../../Components/review_image_add";
// import { add_review } from "../../../Controller/reviews"; // Adjust the import path for the API call

// const ReviewAdd = () => {
//   const navigate = useNavigate();
//   const [productName, setProductName] = useState('');
//   const [review, setReview] = useState('');
//   const [rating, setRating] = useState(0);
//   const [documentFiles, setDocumentFiles] = useState([]); // To handle document uploads
//   const [reviewImages, setReviewImages] = useState([]); // To handle review image uploads
//   const reviewing_uid = 'cid_9204722eece2480cb310ae0e1f2dad58'; // Hardcoded company UID for testing

//   const onDocumentUpload = (file) => {
//     setDocumentFiles([file]);
//     console.log("Document uploaded:", file);
//   };

//   const onReviewImageUpload = (files) => {
//     setReviewImages(files); 
//     console.log("Review images uploaded:", files);
//   };

//   const handleSubmit = async () => {
//     const payload = {
//       rating: rating,
//       title: productName,
//       description: review,
//       document: documentFiles.length > 0 ? documentFiles[0] : null,
//       images: reviewImages, 
//     };

//     try {
//       const response = await add_review(reviewing_uid, payload);
//       console.log("Review submitted successfully:", response);
//       navigate('/success_page');
//     } catch (error) {
//       console.error("Error submitting review:", error);
//       alert("There was an error submitting your review. Please try again.");
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={() => navigate('')}
//           />
//           <h1 className="text-black font-semibold text-xl">Reviews</h1>
//         </div>
//         <div className="mr-5 md:mr-7 pr-5 flex self-end">
//           <img
//             src="/Assets/dots.svg"
//             alt="Upload"
//             className="cursor-pointer h-5 w-5"
//             onClick={() => navigate('/report_page')}
//           />
//         </div>
//       </div>
//       <div className="border-b border-black mt-2 md:mt-[25px]"></div>
//       <div className="w-4/5 ml-7">
//         <div
//           className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 rounded-lg font-poppins"
//           style={{ height: '600px', width: '450px' }}
//         >
//           <h3 className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Rate the Product</h3>
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             {[1, 2, 3, 4, 5].map((star) => (
//               <svg
//                 key={star}
//                 style={{ margin: '10px', cursor: 'pointer' }}
//                 className={rating >= star ? 'filled' : 'empty'}
//                 onClick={() => setRating(star)}
//                 viewBox="0 0 24 24"
//                 width="29"
//                 height="29"
//                 fill={rating >= star ? '#DB0000' : '#FFFFFF'}
//                 stroke={rating >= star ? '#DB0000' : '#DB0000'}
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               >
//                 <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
//               </svg>
//             ))}
//           </div>
//           <p className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Add Review</p>
//           <InputBox
//             value={productName}
//             onChange={(e) => setProductName(e.target.value)}
//             placeholder="Product Name"
//             borderRadius="1px"
//           />
//           <TextArea
//             value={review}
//             onChange={(e) => setReview(e.target.value)}
//             placeholder="Sleek design, great performance. Earbuds are worth every penny."
//             className="mt-4"
//           />
//           <h3 className="text-sm md:text-base font-semibold mt-2 mb-2 text-[#4A4A4A]">Confirmation of your purchase</h3>
          
//           {/* Add Document Component */}
//           <AddDocument label="Add Documents" onFileUpload={onDocumentUpload} accept=".pdf,.docx,.jpg" />

//           {/* Review Image Upload Component */}
//           <ReviewImageUpload
//             reviewImages={reviewImages}
//             setReviewImages={onReviewImageUpload}
//             svgImagePath="/Assets/review.svg"
//             bgColor="#C4C4C4"
//             borderRadius="8px"
//             width="80px"
//             height="90px"
//           />

//           <div className="mt-2">
//             <Button
//               text="Submit"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSubmit} 
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReviewAdd;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import InputBox from "../../Components/input_box";
import TextArea from "../../Components/text_area";
import AddDocument from "../../Components/add_doc";
import ReviewImageUpload from "../../Components/review_image_add";
import { add_review } from "../../../Controller/reviews"; // Adjust the import path for the API call
import UploadDocuments from "../../../Controller/firebase/firebase_documents";

const ReviewAdd = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [documentUrl, setDocumentUrl] = useState(''); // To store the uploaded document's URL
  const [reviewImages, setReviewImages] = useState([]); // To handle review image uploads
  const reviewing_uid = 'cid_9204722eece2480cb310ae0e1f2dad58'; // Hardcoded company UID for testing

  const onDocumentUpload = async (file) => {
    try {
      const downloadURL = await UploadDocuments(file);
      setDocumentUrl(downloadURL);
      console.log("Document uploaded and available at:", downloadURL);
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("Failed to upload document. Please try again.");
    }
  };

  const handleSubmit = async () => {
    const payload = {
      rating: rating,
      title: productName,
      description: review,
      confirmation_document: documentUrl || null, // Use the uploaded document's URL, or null if not uploaded
      image_url: reviewImages, // This should now be a list of URLs
    };

    try {
      const response = await add_review(reviewing_uid, payload);
      console.log("Review submitted successfully:", response);
      navigate('/success_page');
    } catch (error) {
      console.error("Error submitting review:", error);
      alert("There was an error submitting your review. Please try again.");
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={() => navigate('')}
          />
          <h1 className="text-black font-semibold text-xl">Reviews</h1>
        </div>
        <div className="mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/dots.svg"
            alt="Upload"
            className="cursor-pointer h-5 w-5"
            onClick={() => navigate('/report_page')}
          />
        </div>
      </div>
      <div className="border-b border-black mt-2 md:mt-[25px]"></div>
      <div className="w-4/5 ml-7">
        <div
          className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 rounded-lg font-poppins"
          style={{ height: '600px', width: '450px' }}
        >
          <h3 className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Rate the Product</h3>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {[1, 2, 3, 4, 5].map((star) => (
              <svg
                key={star}
                style={{ margin: '10px', cursor: 'pointer' }}
                className={rating >= star ? 'filled' : 'empty'}
                onClick={() => setRating(star)}
                viewBox="0 0 24 24"
                width="29"
                height="29"
                fill={rating >= star ? '#DB0000' : '#FFFFFF'}
                stroke={rating >= star ? '#DB0000' : '#DB0000'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
              </svg>
            ))}
          </div>
          <p className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Add Review</p>
          <InputBox
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name"
            borderRadius="1px"
          />
          <TextArea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Sleek design, great performance. Earbuds are worth every penny."
            className="mt-4"
          />
          <h3 className="text-sm md:text-base font-semibold mt-2 mb-2 text-[#4A4A4A]">Confirmation of your purchase</h3>
          
          {/* Add Document Component */}
          <AddDocument label="Add Documents" onFileUpload={onDocumentUpload} accept=".pdf,.docx,.jpg" />

          {/* Review Image Upload Component */}
          <ReviewImageUpload
            reviewImages={reviewImages}
            setReviewImages={setReviewImages} // Corrected function
            svgImagePath="/Assets/review.svg"
            bgColor="#C4C4C4"
            borderRadius="8px"
            width="80px"
            height="90px"
          />

          <div className="mt-2">
            <Button
              text="Submit"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSubmit} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAdd;
