import React, { useEffect, useRef } from 'react';

const PopupMenu = ({ isOpen, onClose }) => {
  const menuRef = useRef(null); // Reference to the menu container

  useEffect(() => {
    if (!isOpen) return;

    // Function to check if click is outside the menu
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose(); // Close the menu if the click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount or when the menu is closed
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex bg-black opacity-60 overlay" style={{ marginLeft: 0 }}></div>
      <div ref={menuRef} className="absolute right-0 top-full mt-2 bg-transparent shadow-none rounded-lg z-10">
        <div className="bg-[#F9F9F9] backdrop-blur-sm rounded-lg shadow-lg w-52 p-3">
          <button className="block w-full text-left px-4 py-2 hover:bg-gray-100/60" onClick={onClose}>
            <span className="flex items-center">
              <img src="/Assets/block.svg" alt="Block" className="w-5 h-5 mr-2" />
              Block
            </span>
          </button>
          <button className="block w-full text-left px-4 py-2 hover:bg-gray-100/60" onClick={onClose}>
            <span className="flex items-center">
              <img src="/Assets/filedock.svg" alt="Report" className="w-5 h-5 mr-2" />
              Report
            </span>
          </button>
          {/* <button className="block w-full text-left px-4 py-2 hover:bg-gray-100/60" onClick={onClose}>
            <span className="flex items-center">
              <img src="/Assets/move_important.svg" alt="Move to important" className="w-5 h-5 mr-2" />
              Move to important
            </span>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default PopupMenu;
