// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import StarRating from '../../Components/star_rating';

// const FollowUp = () => {
//   const [selectedMessageId, setSelectedMessageId] = useState(null);

//   const messages = [
//     {
//       id: 1,
//       company: 'Cyber Blue Electronic',
//       imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQRHapI-bVGL2U4BoJOv-3bVY0qjn0yEJG7Q&s',
//       rating: 5,
//       companyId: '4378585',
//     },
//     {
//       id: 2,
//       company: 'Simple art International Co.',
//       imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQRHapI-bVGL2U4BoJOv-3bVY0qjn0yEJG7Q&s',
//       rating: 4,
//       companyId: '4378585',
//     },
//     {
//       id: 3,
//       company: 'Taizhou Industry Co Limited',
//       imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQRHapI-bVGL2U4BoJOv-3bVY0qjn0yEJG7Q&s',
//       rating: 3,
//       companyId: '4378585',
//     },
//     {
//         id: 4,
//         company: 'Simple art International Co.',
//         imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQRHapI-bVGL2U4BoJOv-3bVY0qjn0yEJG7Q&s',
//         rating: 4,
//         companyId: '4378585',
//       },
//       {
//         id: 5,
//         company: 'Taizhou Industry Co Limited',
//         imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQRHapI-bVGL2U4BoJOv-3bVY0qjn0yEJG7Q&s',
//         rating: 3,
//         companyId: '4378585',
//       },
//     // Add more messages as needed
//   ];

//   const handleSelectMessage = (id) => {
//     setSelectedMessageId(id);
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-techna">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//           />
//           <h1 className="text-[#171717] font-bold sm:text-lg lg:text-xl">Follow Up</h1>
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className='lg:w-4/5 justify-items-center '>
//       <div className="mt-5 lg:w-3/4  mx-2 grid  grid-cols-2  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 lg:ml-32 ">
//         {messages.map((message) => (
//           <div
//             key={message.id}
//             className={" w-5/4 h-60  content-evenly rounded-lg shadow-sm bg-[#C4C4C4] border ${selectedMessageId === message.id ? ''} cursor-pointer"}
//             onClick={() => handleSelectMessage(message.id)}
         

//           >
//             <div className="flex flex-col items-center ">
//               <img src={message.imgSrc} alt={message.company} className="h-16 w-16 rounded-full mb-4" />
//               <h2 className="font-semibold text-center">{message.company}</h2>
//               <p className="text-sm text-gray-600 text-center">ID: {message.companyId}</p>
//               <div className="flex mt-2">
//                 <StarRating rating={message.rating} />
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     </div>
//   );
// };

// export default FollowUp;
import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import StarRating from '../../Components/star_rating';

const FollowUp = () => {
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  // Currently empty messages array to simulate no data scenario
  const messages = [];

  const handleSelectMessage = (id) => {
    setSelectedMessageId(id);
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          {/* <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
          /> */}
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Follow Up</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 justify-items-center">
        <div className="mt-5 lg:w-3/4 mx-2 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 lg:ml-32">
          {messages.length === 0 ? (
            <div className="flex items-center justify-center w-full h-60 col-span-3">
              <p className="text-gray-500 text-xl">No data available</p>
            </div>
          ) : (
            messages.map((message) => (
              <div
                key={message.id}
                className={`w-full h-60 content-evenly rounded-lg shadow-sm bg-[#C4C4C4] border ${
                  selectedMessageId === message.id ? '' : ''
                } cursor-pointer`}
                onClick={() => handleSelectMessage(message.id)}
              >
                <div className="flex flex-col items-center">
                  <img src={message.imgSrc} alt={message.company} className="h-16 w-16 rounded-full mb-4" />
                  <h2 className="font-semibold text-center">{message.company}</h2>
                  <p className="text-sm text-gray-600 text-center">ID: {message.companyId}</p>
                  <div className="flex mt-2">
                    <StarRating rating={message.rating} />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default FollowUp;
