import React, { useState } from 'react';
import LoaderSpinner from './loader_spinner';

const CatalogPicker = ({ width = '360px', height = '480px', onFileSelect, loading }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileSelect) {
      setFileName(file.name);
      onFileSelect(file);
    }
  };

  return (
    <div
      className="bg-[#D9D9D9] flex flex-col items-center justify-center relative"
      style={{ width, height }}
    >
      {loading ? (
        <LoaderSpinner visible={loading} />
      ) : (
        <>
          <input
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            onChange={handleFileChange}
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
          />
          <img src="Assets/review.svg" alt="Plus Icon" className="w-6 h-6" />
          {!fileName && (
            <p className="mt-4 text-black text-center font-semibold text-xs">
              Please add your Product Catalog
            </p>
          )}
          {fileName && !loading && (
            <p className="mt-2 text-black text-center font-semibold text-xs">
              Selected file: {fileName}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default CatalogPicker;