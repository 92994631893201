// import React from 'react';
// import AddReview from '../Components/review';
// import Sidebar from '../Components/main_navbar';


// import { useNavigate } from "react-router-dom";



// const AddReviews = () => {
//     const navigate = useNavigate();
//     const handleBackClick = () => {
//       navigate('reviews_page');
//     };
// return(
//     <>
//     <Sidebar/>
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-poppins ">
   

//    <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//    <img 
//           src="/Assets/arrow_back.svg" 
//           alt="Back" 
//           className="cursor-pointer mr-6" 
//           onClick={handleBackClick} 
//         />
//      <h1 className="text-black font-semibold text-2xl">Reviews</h1>
//    </div>
//    <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
// <AddReview/>

// </div>
// </>
// );    
// };

// export default AddReviews;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import StarRating from './StarRating';
// import ImageUpload from './ImageUpload';
// import './AddReview.css'; // Assume this contains the necessary CSS

// const AddReview = () => {
//   const navigate = useNavigate();
//   const [productName, setProductName] = useState('');
//   const [review, setReview] = useState('');
//   const [rating, setRating] = useState(0);
//   const [images, setImages] = useState([]);

//   const handleSubmit = () => {
//     // Handle form submission, save the review data
//     // Add the new review to the reviews data and navigate back to reviews page
//     const newReview = {
//       name: productName,
//       comment: review,
//       rating,
//       image: images[0], // Assume only one image for simplicity
//     };
//     // Update the reviewsData (assume you have a function to update it)
//     // updateReviewsData(newReview);
//     navigate('/');
//   };

//   return (
//     <div className="add-review-page">
//       <h2>Add Review</h2>
//       <input
//         type="text"
//         placeholder="Product Name"
//         value={productName}
//         onChange={(e) => setProductName(e.target.value)}
//       />
//       <textarea
//         placeholder="Write your review..."
//         value={review}
//         onChange={(e) => setReview(e.target.value)}
//       />
//       <StarRating rating={rating} setRating={setRating} />
//       <ImageUpload images={images} setImages={setImages} />
//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// };

// export default AddReview;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import FileUpload from '../../Components/image_upload';
import InputBox from '../../Components/input_box';
import TextArea from '../../Components/text_area';
import { uploadMedia } from '../../../Controller/company_products';

const CompanyProduct = () => {
    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state for API call

    const handleBackClick = () => {
        navigate('/view_photos');
    };

    const handleUploadMedia = async () => {
        // Validation for fields
        if (!productName || !description || images.length === 0) {
            alert("Please fill all fields and upload at least one image.");
            return;
        }

        setLoading(true); // Start loading

        try {
            const imageUrlArray = images.map(img => img.url); // Collect all uploaded image URLs
            const response = await uploadMedia(productName, description, imageUrlArray); // Call API
            if (response.status === 201) {
                console.log('Media uploaded successfully:', response.data);
                // Navigate to the next page after successful upload
                navigate("/view_photos");
            } else {
                console.error("Error uploading media:", response);
                alert('Failed to upload media.');
            }
        } catch (error) {
            console.error("Error uploading media:", error);
            alert('An error occurred during upload.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
            <Sidebar />
            <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
                <div className="flex items-center space-x-2">
                    <img
                        src="/Assets/arrow_back.svg"
                        alt="Back"
                        className="cursor-pointer mr-2 h-6 w-6"
                        onClick={handleBackClick}
                    />
                    <h1 className="text-black font-semibold text-xl">Post</h1>
                </div>
            </div>
            <div className="border-b border-black mt-4 md:mt-[25px]"></div>
            <div className="w-4/5 ml-7">
                <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-4 rounded-lg shadow-lg font-poppins" style={{ height: '600px', width: '450px' }}>
                    {/* Product Name Field */}
                    <div className="mt-8">
                        <p className="font-semibold">Product Name</p>
                        <InputBox
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder="Product Name"
                            borderRadius="1px"
                        />
                    </div>

                    {/* Description Field */}
                    <div className="mt-3">
                        <p className="font-semibold">Description</p>
                        <TextArea
                            value={description} // Bind the value to the description state
                            onChange={(e) => setDescription(e.target.value)} // Update the description state
                            placeholder="Write a brief description of the product"
                            className="mt-2"
                            maxLength={500} // Optional: Add character limit
                        />
                    </div>

                    {/* File Upload Component */}
                    <FileUpload
                        files={images}
                        setFiles={setImages}
                        svgImagePath="/Assets/grey_plus.svg"
                        bgColor="#E6E6E6"
                        borderRadius="8px"
                        width="90px"
                        height="90px"
                        type="image"
                    />

                    {/* Display Loading or Next Button */}
                    <div className="mt-24">
                        <Button
                            text={loading ? 'Uploading...' : 'Next'} // Show loading state in button text
                            borderColor="border-red-600"
                            backgroundColor="bg-[#DB0000]"
                            textColor="text-white"
                            onClick={handleUploadMedia} // Call handleUploadMedia on click
                            loading={loading} // Show loading if API call is in progress
                            disabled={loading} // Disable button when loading
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyProduct;
