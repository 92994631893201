import { api } from "./api";
import { 
  DELETE_PRODUCT_URL, 
  GET_USER_BY_ID_URL, 
  GET_PRODUCTS_URL, 
  CREATE_PRODUCT_URL, 
  GET_PRODUCT_BY_ID_URL ,
  GET_PRODUCTS_BY_COMPANY_URL,
} from "./urls";

const getProducts = async () => {
  const res = await api.get(GET_PRODUCTS_URL);
  return res;
};

const getProductById = async (id) => {
  const res = await api.get(GET_PRODUCT_BY_ID_URL(id));
  return res;
};

const createProduct = async (items) => {
    const res = await api.post(CREATE_PRODUCT_URL, {
      items:items
    });
    return res; 
  };

const deleteProduct = async (id) => {
  const res = await api.delete(DELETE_PRODUCT_URL(id));
  return res;
};

const getUserById = async (id) => {
  const res = await api.get(GET_USER_BY_ID_URL(id));
  return res;
};
const getProductsByCompanyUid = async (uid) => {
  const res = await api.get(GET_PRODUCTS_BY_COMPANY_URL(uid));
  return res;
};

export { getProducts, getProductById, createProduct, deleteProduct, getUserById,getProductsByCompanyUid };
