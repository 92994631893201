import React from 'react';

const SearchSuggestions = ({ suggestions, onSelectSuggestion }) => {
  return (
    <div className="absolute left-0 right-0 bg-white border border-gray-300 rounded shadow-lg z-10">
      {suggestions.length > 0 ? (
        suggestions.map((suggestion, index) => (
          <div
            key={index}
            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
            onClick={() => onSelectSuggestion(suggestion)}
          >
            {suggestion}
          </div>
        ))
      ) : (
        <div className="px-4 py-2 text-gray-500">No suggestions found</div>
      )}
    </div>
  );
};

export default SearchSuggestions;
