import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CenteredImageContainer from '../Components/black_container';
import InputBox from '../Components/input_box';
import DocumentUpload from '../Components/document_upload';
import MapButton from '../Components/map_button';
import { update_company_details } from '../../Controller/company_details';
import LoaderSpinner from '../Components/loader_spinner';
import iconUp from './Icon-up.svg';


const EditForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState('');

  // Retrieve values from localStorage
  const storedCompanyName = localStorage.getItem('companyName') || '';
  const storedWebsite = localStorage.getItem('website') || '';
  const storedAccountManager = localStorage.getItem('manager') || '';
  const storedOfficeLocation = localStorage.getItem('location') || '';

  // Retrieve documents from localStorage
  const storedAuthorizationDocument = localStorage.getItem('authorization_document') || '';
  const storedTradingLicense = localStorage.getItem('trading_license') || '';
  const storedTenancyContract = localStorage.getItem('tenancy_contract') || '';
  const storedTradingRegister = localStorage.getItem('trading_register') || '';

  // Initialize state
  const [companyName, setCompanyName] = useState(storedCompanyName);
  const [website, setWebsite] = useState(storedWebsite);
  const [accountManager, setAccountManager] = useState(storedAccountManager);
  const [officeLocation, setOfficeLocation] = useState(storedOfficeLocation);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [address, setAddress] = useState('');

  // Retrieve remarks from localStorage when component mounts
  useEffect(() => {
    const storedRemarks = localStorage.getItem('remarks') || 'No remarks available';
    setRemarks(storedRemarks);
  }, []);

  const handleSelectLocation = (locationDetails) => {
    const { address, latitude, longitude } = locationDetails;
    setAddress(address);
    setLatitude(latitude);
    setLongitude(longitude);
    setIsAddressSelected(true);
    setOfficeLocation(address);
  };

  const handleSave = async () => {
    setLoading(true);

    // Construct the payload
    const payload = {
      companyName,
      website,
      officeLocation,
      latitude,
      longitude,
      accountManager,
      authorizationDocument: storedAuthorizationDocument,
      tradingLicense: storedTradingLicense,
      tradingRegister: storedTradingRegister,
      tenancyContract: storedTenancyContract,
      type: false,
    };

    console.log('Payload:', payload);

    try {
      const res = await update_company_details(
        companyName,
        website,
        officeLocation,
        latitude,
        longitude,
        accountManager,
        storedAuthorizationDocument,
        storedTradingLicense,
        storedTradingRegister,
        storedTenancyContract,
        payload.type
      );

      if (res.status === 202) {
        console.log('Success:', res.data);
        navigate('/home');
      } else {
        console.error('Error:', res);
      }
    } catch (error) {
      console.error('API Call Failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full h-full overflow-y-auto bg-[#FBFCF8] font-gotham">
      <div className="hidden lg:flex w-full lg:w-2/5 h-full overflow-y-auto">
        <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
      </div>
      <div className="flex flex-col w-full lg:w-3/5 h-full p-8">
        <h2 className="text-2xl font-semibold mb-1 font-gotham">Updates</h2>
        <p className="text-lg mb-6 text-gray-500 font-gotham">Fill the field to update the form</p>

        <div className="bg-white shadow-lg rounded-lg p-8 overflow-y-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            <div className="lg:col-span-1">
              <div className="flex flex-col">
                <label className="text-[#989898] mb-2 font-gotham">Company name</label>
                <InputBox
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="font-gotham"
                />
              </div>

              <div className="flex flex-col mt-6">
                <label className="text-[#989898] mb-2 font-gotham">Website</label>
                <InputBox
                  placeholder="www.eswaf.com"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  className="font-gotham"
                />
              </div>

              <div className="flex flex-col mt-6">
                <label className="text-[#989898] mb-2 font-gotham">Account managed by</label>
                <InputBox
                  placeholder="Name"
                  value={accountManager}
                  onChange={(e) => setAccountManager(e.target.value)}
                  className="font-gotham"
                />
              </div>
            </div>

            <div className="lg:col-span-1 flex flex-col">
              <label className="text-[#989898] mb-2 font-gotham">Office Location</label>
              <div className="flex flex-col">
                <InputBox
                  placeholder="Ernakulam, edapally, kerala"
                  value={officeLocation}
                  onChange={(e) => setOfficeLocation(e.target.value)}
                  className="font-gotham"
                />
                <div className="w-full mt-4">
                  <MapButton onAddressSelect={handleSelectLocation} padding="15px" />
                </div>
              </div>
            </div>
          </div>

          <h3 className="text-xl font-semibold mb-4 font-gotham">Upload Documents</h3>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <DocumentUpload
              label="Authorization file"
              initialFileName={storedAuthorizationDocument}
              documentType="authorization_document"
              imgSrc={iconUp}
            />

            <DocumentUpload
              label="Trading License"
              initialFileName={storedTradingLicense}
              documentType="trading_license"
              imgSrc={iconUp}
            />

            <DocumentUpload
              label="Tenancy Contract"
              initialFileName={storedTenancyContract}
              documentType="tenancy_contract"
              imgSrc={iconUp}
            />

            <DocumentUpload
              label="Trading Register"
              initialFileName={storedTradingRegister}
              documentType="trading_register"
              imgSrc={iconUp}
            />
          </div>

          <button
            className="bg-[#DB0000] text-white px-6 py-3 mt-8 rounded-lg w-full flex justify-center items-center font-gotham"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <LoaderSpinner visible={true} /> : "Save"}
          </button>

          <div className="mt-4">
            <h3 className="text-xl font-semibold font-gotham mb-4">Remarks</h3>
            <div className="flex items-start">
              <img src="./remarks_arrow.svg" alt="Arrow" className="w-4 h-4 mt-1 mr-2" />
              <p className="text-gray-600 text-[#2D3748] font-gotham font-medium">
                <span className="text-[#2D3748] font-gotham">Remarks: </span>
                {remarks}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditForm;
