import React, { useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa'; // Import three-dot icon

const PostDetails = ({ post, onPostDeleted, isMyPosts }) => {
  const [showMenu, setShowMenu] = useState(false);

  if (!post) {
    return <div>No post details available</div>; // Safeguard against null post
  }

  const handleDelete = async () => {
    try {
      await onPostDeleted(post.id); // Call the delete function passed from PostList
      setShowMenu(false); // Hide the menu after deletion
    } catch (error) {
      console.error('Failed to delete post:', error);
    }
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-md w-full mb-4 flex flex-col items-center">
      <div className="relative bg-gray-100 rounded-lg p-6 flex flex-col items-center w-[300px]">
        {isMyPosts && (
          <>
            <FaEllipsisV 
              className="absolute top-2 right-2 cursor-pointer text-gray-500 text-2xl" 
              onClick={() => setShowMenu(!showMenu)}
            />
            {showMenu && (
              <div className="absolute top-6 right-0 w-24 bg-white shadow-lg rounded-lg z-10">
                <div
                  className="cursor-pointer text-sm p-2 hover:bg-gray-200"
                  onClick={handleDelete}
                >
                  Delete
                </div>
              </div>
            )}
          </>
        )}

        <img
          src={post.profile_image_url || '/profile.webp'}
          alt="Company Logo"
          className="rounded-full w-[70px] h-[70px] mb-4"
        />

        <div className="text-center">
          <div className="font-poppins font-bold text-[#181717] text-lg">
            {post.company_name}
          </div>
          <div className="text-[#181717] text-sm font-gotham mt-2">
            Product Name: {post.name}
          </div>
          <div className="text-sm text-[#181717] font-gotham text-[#6B6B6B]">
            Category: {post.category}
          </div>
          <div className="text-sm font-gotham text-[#6B6B6B]">
            Quantity: {post.quantity}
          </div>
        </div>
      </div>

      <div className="mt-6 w-[300px] text-center">
        <h3 className="font-gotham font-bold text-black">Detailed Requirements</h3>
        <p className="text-sm font-gotham text-[#6B6B6B] mt-2 leading-relaxed">
          {post.details}
        </p>
      </div>

      {post.detail_document && (
        <div className="mt-6 flex items-center justify-between bg-gray-100 p-4 rounded-lg w-[300px]">
          <div className="flex items-center">
            <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-3" />
            <span className="text-sm font-gotham text-[#181717]">Document</span>
          </div>
          <a
            href={post.detail_document}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-gotham text-red-600 underline"
          >
            View
          </a>
        </div>
      )}
    </div>
  );
};

export default PostDetails;
