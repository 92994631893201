import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../Components/button';
import CategorySelectList from '../../Components/category_select_list';
import InputBox from '../../Components/input_box';
import { Toast } from '../../Components/toast';
import LoaderSpinner from '../../Components/loader_spinner';
import CenteredImageContainer from '../../Components/black_container.jsx';
import ReportContainer from '../../Components/report.jsx';
import { fetchCategories } from '../../../Controller/sub_category_controller.js';
import { handleSaveCategory } from '../../../Controller/sub_category_controller.js';
const CategorySelection = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [headerText, setHeaderText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { completed } = location.state || { completed: [] };

  useEffect(() => {
    fetchCategories(setCategories, setCategoryMap, setLoading); // Call controller function to fetch categories
  }, []);

  useEffect(() => {
    const category = localStorage.getItem('category');
    if (category) {
      setHeaderText(formatCategoryName(category));
    }
  }, []);

  const formatCategoryName = (category) => {
    return category.split('_').map(word => {
      if (word === 'stones') {
        return 'Stones/';
      } else if (word === 'metals') {
        return 'Metals';
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join(' ');
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setShowOtherInput(option === 'other');
    if (option === 'other') {
      localStorage.setItem('sub_category', 'other');
    } else {
      const selectedId = categoryMap[option];
      if (selectedId) {
        localStorage.setItem('sub_category', selectedId);
      }
    }
  };

  const handleOtherInputChange = (event) => {
    setOtherInputValue(event.target.value);
  };

  const handleButtonClick = async () => {
    handleSaveCategory(
      selectedOption, 
      otherInputValue, 
      categoryMap, 
      navigate, 
      setLoading, 
      completed
    ); // Call controller function to handle saving
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/category_new.svg" />
        </div>
      </div>
      <div className="absolute top-5 right-6">
        <ReportContainer />
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
        <div className="p-6 w-full max-w-2/5 overflow-auto">
          <h1 className="text-2xl font-bold text-black mb-4">{headerText}</h1>
          <p className="text-base lg:text-lg text-gray-600 mb-4">Please Select Your sub category from this</p>
          {loading ? (
            <LoaderSpinner />
          ) : (
            <CategorySelectList
              onChange={handleOptionChange}
              selectedOption={selectedOption}
              categories={categories}
            />
          )}
          {showOtherInput && (
            <div className='mt-7'>
            <InputBox
              placeholder="Other"
              padding="14px"
              value={otherInputValue}
              onChange={handleOtherInputChange}
              className="mt-4 "
            />
            </div>
          )}
          <div className='mt-5'>
          <Button
            text="Save"
            onClick={handleButtonClick}
            backgroundColor="bg-[#DB0000]"
            textColor="text-white"
            fontWeight="font-bold"
            loading={loading}
            className="w-full mt-6 "
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySelection;
