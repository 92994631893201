import React from 'react';

const CurrentPlan = () => {
  return (
    <div className="flex flex-col items-center font-techna mt-4 md:mt-6">
      <div className="bg-[#D6D6D6] w-full md:w-[600px] h-16 flex items-center justify-center rounded-t-lg">
        <span className="text-base md:text-lg font-normal text-[#1C1C1C]">Current Plan</span>
      </div>
      <div className="bg-[#DB0000] w-full md:w-[600px] h-auto md:h-[200px] flex flex-col p-4 text-[#E0E0E0] rounded-b-lg">
        <div className="flex flex-col md:flex-row justify-between h-full">
          <div className="order-2 md:order-1 flex flex-col justify-center text-lg md:text-xl lg:text-2xl font-normal ml-2">
            <span className='text-base sm:text-lg md:text-xl font-bold'>Gold Supplier</span>
            <span className='text-base sm:text-lg md:text-xl font-bold'>Standard Extended</span>
          </div>
          <div className="order-1 md:order-2 flex flex-col justify-between text-right mr-2 mb-2 md:mb-0 md:h-full">
            <div className="text-xs lg:text-xs">Expire Date</div>
            <div className="hidden md:block text-base sm:text-lg md:text-xl font-bold md:mt-auto">$2999/year</div>
          </div>
        </div>
        <div className="order-3 md:hidden text-right  text-base sm:text-lg md:text-xl font-bold">$2999/year</div>
      </div>
    </div>
  );
};

export default CurrentPlan;