import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Ensure this line is here to import Tailwind CSS
import { BrowserRouter as Router } from 'react-router-dom';
import './fonts/helvetica-light.ttf';
import './fonts/techna-sans.otf';
import App from './App';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
  