import React from 'react';

const GoldSupplier = ({ backgroundColor, textColor, title, subtitle, price, height, width, onClick }) => {
    return (
        <div 
            className="p-4 sm:p-6 flex justify-between items-center rounded-lg font-techna cursor-pointer" 
            style={{ backgroundColor, height, width }}
            onClick={onClick} // Add onClick handler
        >
            <div className="flex flex-col">
                <span className="text-base sm:text-lg md:text-xl font-bold" style={{ color: textColor }}>{title}</span>
                <span className="text-base sm:text-lg md:text-xl font-bold" style={{ color: textColor }}>{subtitle}</span>
            </div>
            <span className="text-base sm:text-lg md:text-xl font-bold" style={{ color: textColor }}>{price}</span>
        </div>
    );
};

export default GoldSupplier;
