import axios from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./Constants/constants";
import { showToast } from "../View/Components/toast";
import { BASE_URL } from "./urls";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 205 && response.config.method === 'post') {
      // Show success toast for POST requests
      showToast(response.data.message || "Success", "success");
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Show not authenticated toast for 401 errors
        showToast("Not Authenticated. Please login again.", "error");
        // Optionally, you can also navigate the user to the login page here
      } else if (error.response.status >= 400 && error.response.status <= 405 && error.response.config.method === 'post') {
        // Show error toast for POST requests with other 4xx status codes
        showToast(error.response.data.message || "An error occurred", "error");
      }
    }
    return Promise.reject(error);
  }
);

const refreshapi = axios.create({
  baseURL: BASE_URL,
});

refreshapi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(REFRESH_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

refreshapi.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 205 && response.config.method === 'post') {
      showToast(response.data.message || "Success", "success");
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Show not authenticated toast for 401 errors
        showToast("Not Authenticated. Please login again.", "error");
        // Optionally, you can also navigate the user to the login page here
      } else if (error.response.status >= 400 && error.response.status <= 405 && error.response.config.method === 'post') {
        // Show error toast for POST requests with other 4xx status codes
        showToast(error.response.data.message || "An error occurred", "error");
      }
    }
    return Promise.reject(error);
  }
);

export { api, refreshapi };
