import React, { useState } from 'react';
import TextArea from './text_area';
import Button from './button';


const EditReplyPopup = ({ reply, onSubmit, onClose }) => {
    const [updatedReply, setUpdatedReply] = useState(reply.text);

    const handleUpdate = () => {
        onSubmit(updatedReply);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-lg">
                <h2 className="text-xl font-bold mb-4">Edit Reply</h2>
                <TextArea
                    value={updatedReply}
                    onChange={(e) => setUpdatedReply(e.target.value)}
                    placeholder="Update your reply"
                />
                <div className="flex justify-end space-x-4 mt-4">
                    <Button
                        text="Cancel"
                        backgroundColor="bg-gray-500"
                        textColor="text-white"
                        onClick={onClose}
                    />
                    <Button
                        text="Update"
                        backgroundColor="bg-red-600"
                        textColor="text-white"
                        borderColor="border-red-600"
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditReplyPopup;
