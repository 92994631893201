import { POST_REPORT} from "./urls";
import { api } from "./api";

const post_report= async (title,description,image) => {
    const res = await api.post(POST_REPORT, {
      title: title,
      description: description,
      image: image
     

    });
    return res; 
  };
  export  {post_report}