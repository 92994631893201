import React from 'react';

const ImageButton = ({ onClick, imageSrc, buttonText }) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-col items-center justify-center p-2"
      style={{
        backgroundColor: '#DB0000', // Set the button color
        color: 'white',
        width: '170px',
        height: '60px',
        borderRadius: '10px',
        border: 'none',
        fontFamily: 'Poppins, sans-serif',
        cursor: 'pointer',
        outline: 'none',
      }}
    >
      {/* The dynamic image inside the button */}
      <img
        src={imageSrc} // Image source as a prop
        alt={buttonText} // Use button text as alt for accessibility
        className="w-6 h-6"
        style={{
          border: '',
          padding: '',
        }}
      />
      {/* The dynamic text under the image */}
      <span className="text-white mt-1 text-xs font-normal">{buttonText}</span>
    </button>
  );
};

export default ImageButton;
