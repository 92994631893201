// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import Steps from '../../Components/company_list_tile';
// import StepsCompleted from '../../Components/steps_completed';  // import the new component

// const CompanyDetails = () => {
//   const [completedSteps, setCompletedSteps] = useState(0);

//   const handleCompleteStep = () => {
//     if (completedSteps < 3) {
//       setCompletedSteps(prevSteps => prevSteps + 1);
//     } else {
//       // Close the page when all steps are completed
//       window.close();
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-poppins">
//       <Sidebar />
      
      // <div className="flex items-center justify-between w-4/5 px-4  mt-4 md:mt-[40px] ">
      // <img
      //   src="Assets/Close_round.svg"
      //   alt="close"
      //   className="w-6 h-6 cursor-pointer"
      //   onClick={handleCompleteStep}
      // />
//       <div className="flex-grow flex justify-center ml-7 text-xs ">
//         <StepsCompleted completedSteps={completedSteps} />
//       </div>
     
//     </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
     
//       <div className="flex flex-col mx-auto items-center mt-10 w-4/5 lg:ml-7 overflow-auto">
//         <p className=' text-[#232323] text-center '>Complete your profile to get verified.</p>
//         <div className="max-w-lg w-full mt-10 ">
//           <Steps number="1" text="Add your company details and complete your profile to get verified." destination="/company_logo" />
//           <Steps number="2" text="Please add and verify your number to complete your profile and get verified." destination="/details_phone" />
//           <Steps number="3" text="Please add and verify LinkedIn to complete your profile and get verified." destination="/linkedin_page" />
//           <Steps number="4" text="Add your products." destination="/product_service" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDetails;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Steps from '../../Components/company_list_tile';
import StepsCompleted from '../../Components/steps_completed';
import Button from '../../Components/button';
import ReportContainer from '../../Components/report';



const CompanyDetails = () => {
  const location = useLocation();
  let completedStepsFromLogin;

  try {
    completedStepsFromLogin = location.state.completedStepsFromLogin;
    console.log(completedStepsFromLogin)
  } catch {
    completedStepsFromLogin = [];
  }

  const stepKeys = [
    'COMPANY_DETAILS_DESCRIPTION_COMPLETED',
    'COMPANY_DETAILS_MANAGER_COMPLETED',
   
    'COMPANY_DETAILS_DOCUMENTS_COMPLETED'
  ];

  const [completedSteps, setCompletedSteps] = useState([]);
  const [incompleteSteps, setIncompleteSteps] = useState(stepKeys);

  useEffect(() => {
    console.log('Completed steps passed from login:', completedStepsFromLogin);

    if (Array.isArray(completedStepsFromLogin) && completedStepsFromLogin.length > 0) {
      const newCompletedSteps = [];
      const newIncompleteSteps = [...stepKeys];

      // Load existing completion status from localStorage
      stepKeys.forEach((key) => {
        const isStepCompleted = localStorage.getItem(key) === 'true';
        if (isStepCompleted) {
          newCompletedSteps.push(key);
          const index = newIncompleteSteps.indexOf(key);
          if (index > -1) {
            newIncompleteSteps.splice(index, 1);
          }
        }
      });

      // Update completion status based on the data passed from login
      completedStepsFromLogin.forEach((step) => {
        if (newIncompleteSteps.includes(step)) {
          newCompletedSteps.push(step);
          newIncompleteSteps.splice(newIncompleteSteps.indexOf(step), 1);
          localStorage.setItem(step, 'true');
        }
      });

      setCompletedSteps(newCompletedSteps);
      setIncompleteSteps(newIncompleteSteps);
    }

    return () => {
      // Cleanup function to avoid memory leaks
      setCompletedSteps([]);
      setIncompleteSteps([
        'COMPANY_DETAILS_DESCRIPTION_COMPLETED',
        'COMPANY_DETAILS_MANAGER_COMPLETED',
        
        'COMPANY_DETAILS_DOCUMENTS_COMPLETED'
      ]);
    };
  }, [completedStepsFromLogin]);

  const stepMap = {
    'COMPANY_DETAILS_DESCRIPTION_COMPLETED': { number: "1", text: "Add your company details", destination: "/address" },
    'COMPANY_DETAILS_MANAGER_COMPLETED': { number: "2", text: "Add your company manager", destination: "/account_manager" },
   
    'COMPANY_DETAILS_DOCUMENTS_COMPLETED': { number: "3", text: "Add your company documents", destination: "/company_docs" }
  };

  const handleNextClick = () => {
    // Implement your logic for the next button click here
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-normal">
      <Sidebar />
      <div className="flex items-center justify-between w-4/5  mt-4 md:mt-[40px]">
        <div className="flex-grow flex justify-center ml-7 text-xs">
          <StepsCompleted completedSteps={completedSteps.length} />
        </div>
        <ReportContainer/>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex flex-col mx-auto items-center mt-10 w-4/5 lg:ml-7 overflow-auto">
        <p className='text-[#232323] font-semibold text-center'>Complete your profile to get verified.</p>
        <div className="max-w-lg w-full mt-10 text-[#232323]">
          {incompleteSteps.map((step, index) => (
            <Steps key={index} {...stepMap[step]} />
          ))}
          {completedSteps.length > 0 && (
            <div className="mt-4">
              <p className="text-left text-[#232323]">Completed</p>
              {completedSteps.map((step, index) => (
                <div key={index} className="flex justify-between items-center font-normal mt-4">
                  <p className="text-left">{stepMap[step].text}</p>
                  <img src="/Assets/steps_tick.svg" alt="Completed" className="w-6 h-6 ml-2" />
                </div>
              ))}
            </div>
          )}
          {incompleteSteps.length === 0 && (
            <div className="mt-8 flex justify-center">
              {/* <Button
                text="Next"
                borderColor="border-red-600"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                onClick={handleNextClick}
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
