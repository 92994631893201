import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import VideoCard from '../../Components/video_display_card';
import DeletePopup from '../../Components/delete_pop_up';
import { get_user_videos_api, delete_video_Id } from '../../../Controller/upload_videos'; // Import the API functions

const UploadedVideo = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch videos and set the list of videos (without video URLs, which will be fetched later)
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videoData = await get_user_videos_api(); // Fetch the video list
        console.log('Fetched videos:', videoData);
        if (Array.isArray(videoData)) {
          setVideos(videoData); // Set the list of videos in the state
        } else {
          console.error('Invalid response format.');
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleUploadClick = () => {
    navigate('/upload_video');
  };

  const handleDeleteClick = (video) => {
    setSelectedVideo(video);
    setShowPopup(true);
  };

  const confirmDelete = async () => {
    if (!selectedVideo) return;
    try {
      await delete_video_Id(selectedVideo.id); // Call the API function to delete the video
      setVideos(videos.filter(video => video.id !== selectedVideo.id)); // Remove video from the list
      setSelectedVideo(null);
    } catch (error) {
      console.error("Error deleting video:", error);
    } finally {
      setShowPopup(false); // Close the popup
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const toggleMenu = (index) => {
    setShowMenu(showMenu === index ? null : index);
  };

  // Navigate to the VideoPlayer component and pass the videoId via state
  const handleVideoClick = (videoId) => {
    navigate(`/video_player`, { state: { videoId } }); // Pass videoId to the VideoPlayer component
  };

  return (
    <div className="flex w-full h-full bg-[#F2F3F3] font-poppins">
      <Sidebar />
      <div className="flex flex-col flex-grow">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
          <div className="w-full flex justify-start items-center space-x-2">
            {/* <img
              src="/Assets/arrow_back.svg"
              alt="Back"
              className="cursor-pointer mr-2 h-6 w-6"
              onClick={handleBackClick}
            /> */}
            <h1 className="text-black font-semibold text-xl">Upload Video</h1>
          </div>
          <div className="mr-5 md:mr-7 pr-5 flex self-end">
            {/* <img
              src="/Assets/review.svg"
              alt="Upload"
              className="cursor-pointer h-6 w-6"
              onClick={handleUploadClick}
            /> */}
          </div>
        </div>
        <div className="border-b border-black mt-4 md:mt-[25px]"></div>
        <div className="lg:w-1/2 md:w-2/3 sm:w-2/3 p-5 lg:ml-5 mx-auto overflow-y-auto">
          {isLoading ? (
            <p></p>
          ) : videos.length === 0 ? (
            <p>No videos uploaded yet.</p>
          ) : (
            <div className="h-[800px] sm:h-[540px] grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 p-8">
              {videos.map((video, index) => (
                <div key={index} className="relative">
                  <VideoCard
                    videoId={video.id}
                    thumbnail={video.thumbnail || '/path-to-default-thumbnail'}
                    onClick={() => handleVideoClick(video.id)} // Trigger navigation on click
                  />
                  <div className="absolute top-0 right-0 m-2 md:m-4">
                    <img
                      src="/Assets/dots.svg"
                      alt="Menu"
                      className="cursor-pointer h-5 w-5 md:h-6 md:w-6"
                      onClick={() => toggleMenu(index)}
                    />
                  </div>
                  {showMenu === index && (
                    <div className="absolute top-10 right-0 bg-white border rounded shadow-lg">
                      <button
                        className="p-2 text-red-600 hover:bg-gray-100 w-full text-left"
                        onClick={() => handleDeleteClick(video)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <DeletePopup
          onClose={closePopup}
          onConfirm={confirmDelete} // Pass the confirmDelete function
          video={selectedVideo} // Pass the selected video to the popup
        />
      )}
    </div>
  );
};

export default UploadedVideo;
