import React, { useEffect, useState } from 'react';

const ComplaintCard = ({ complaint, onView }) => {
  const [profileImageUrl, setProfileImageUrl] = useState('/profile.webp');

  useEffect(() => {
    const storedProfileImageUrl = localStorage.getItem('profile_image_url');
    if (storedProfileImageUrl) {
      setProfileImageUrl(storedProfileImageUrl);
    }
  }, []);

  const formattedDate = complaint.date || 'Date not available';

  const truncateDescription = (description) => {
    return description.length > 50 ? `${description.substring(0, 50)}...` : description;
  };

  return (
    <div className="flex p-4 bg-[#E1E1E1] rounded-lg shadow-md mb-7 w-full items-center mt-5">
      <img
        src={complaint.complaint_by_profile_image || profileImageUrl}
        alt="Profile"
        className="rounded-full w-12 h-12 mr-4 mb-12"
      />
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-start">
          <div className="flex flex-col">
            <h3 className="font-bold text-sm text-black">
              {complaint.complaint_by_name || 'Unknown'}
            </h3>
            <span className="text-gray-500 text-sm">
              {formattedDate} {/* Display formatted date */}
            </span>
          </div>
          <button
            onClick={() => onView(complaint)}
            className="text-red-600 font-bold text-sm"
          >
            View
          </button>
        </div>
        <div className="mt-4">
          <h4 className="font-bold text-sm text-black">
            {complaint.title || 'Complaint Title/Subject'}
          </h4>
          <p className="text-gray-600 text-sm mt-1">
            {truncateDescription(complaint.description)}
          </p>
        </div>
      </div>
      <img
        src={complaint.image_url || '/profile.webp'}
        alt="Complaint"
        className="w-16 h-16 rounded-lg ml-4"
      />
    </div>
  );
};

export default ComplaintCard;
