import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import CatalogPicker from '../../Components/catalog_picker';
import Button from '../../Components/button';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import UploadDocuments from '../../../Controller/firebase/firebase_documents';
import { showToast, Toast } from '../../Components/toast'; // Import the toast functions
import { update_catalogue_api } from '../../../Controller/company_details';
const AddCatalog = () => {
  const navigate = useNavigate();
  const [catalogURL, setCatalogURL] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleBackClick = () => {
    navigate('');
  };

  const handleFileSelect = async (file) => {
    setUploading(true);
    try {
      const downloadURL = await UploadDocuments(file);
      console.log('File uploaded successfully:', downloadURL);
      setCatalogURL(downloadURL);
      showToast('File uploaded successfully.', 'success');
    } catch (error) {
      console.error('Error uploading file:', error);
      showToast('Error uploading file.', 'error');
    } finally {
      setUploading(false);
    }
  };

  const handleSaveClick = async () => {
    if (!catalogURL) {
      showToast('Please upload a catalog file before saving.', 'error');
      return;
    }

    const payload = {
      catalogue: catalogURL,
    };

    try {
      const response = await update_catalogue_api(payload);
      console.log('Documents updated successfully:', response);
      showToast('Catalog saved successfully.', 'success');
      navigate('/profile1');
    } catch (error) {
      console.error('Error updating documents:', error);
      showToast('Error saving catalog.', 'error');
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna">
      <Sidebar />
      <Toast /> {/* Add the Toast component */}
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          
          <h1 className="text-[#171717] font-bold sm:text-lg lg:text-xl">Add Catalog</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="w-4/5 lg:ml-7 mx-auto">
        <div
          className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-techna"
          style={{ height: '500px', width: '450px' }}
        >
          <div className="flex justify-center items-center flex-grow ">
            <CatalogPicker height="450px" width="450px" onFileSelect={handleFileSelect} loading={uploading} />
          </div>
          <div className="mt-8">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSaveClick}
              disabled={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCatalog;

