import React from 'react';

const WebLinkModal = ({ isOpen, onClose, link }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-poppins ">
      <div className="absolute inset-0 bg-black opacity-50 " onClick={onClose}></div>
      <div className="bg-[#F2F2F2] p-8  rounded-lg shadow-lg z-10   mx-auto w-[500px]">
        <h2 className="text-lg font-semibold mb-4">Web Links</h2>
        <div className="flex items-center">
          <img src="/Assets/website.svg" alt="Website Icon" className="w-6 h-6 mr-2" />
          <p>{link}</p>
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={onClose} className="bg-[#DB0000] text-white px-3 py-2 rounded-lg">Close</button>
        </div>
      </div>
    </div>
  );
};

const BottomSheet = ({ isOpen, onClose, link }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      <div className="absolute inset-0  opacity-50" onClick={onClose}></div>
      <div className="bg-[#E1E1E1] p-4 rounded-t-lg shadow-lg z-10">
        <h2 className="text-lg font-semibold mb-4">Web Link</h2>
        <div className="flex items-center">
          <img src="/Assets/website.svg" alt="Website Icon" className="w-6 h-6 mr-2" />
          <p>{link}</p>
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={onClose} className="bg-[#DB0000] text-white px-4 py-2 rounded-lg">Close</button>
        </div>
      </div>
    </div>
  );
};

export { WebLinkModal, BottomSheet };
