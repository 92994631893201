import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { get_subscriptions_api, post_user_payments_api } from '../../../Controller/subscriptions';

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  let product_id = state?.product_id;
  const backgroundColor = state?.backgroundColor || "#DB0000"; 

  if (!product_id) {
    product_id = sessionStorage.getItem('product_id');
  }

  console.log('location.state:', state); // Debug log
  console.log('product_id:', product_id); // Debug log

  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!product_id) {
      setError('No product ID provided');
      setLoading(false);
      return;
    }

    const fetchPlanDetails = async () => {
      try {
        const response = await get_subscriptions_api();
        if (response.status === 200) {
          const selectedPlan = response.data.data.find(p => p.product_id === product_id);
          setPlan(selectedPlan);
        } else {
          setError('Failed to fetch subscription details');
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        setError('An error occurred while fetching subscription details');
      } finally {
        setLoading(false);
      }
    };

    fetchPlanDetails();
  }, [product_id]);

  const handlePayNowClick = async () => {
    try {
      const payload = { product_id };
      const paymentResponse = await post_user_payments_api(payload);

      if (paymentResponse.status === 200) {
        console.log(paymentResponse)
        const checkoutUrl = paymentResponse.data.data.checkout_url;
        console.log(checkoutUrl)
        window.location.href = checkoutUrl;
      } else {
        console.error('Failed to post user payment');
      }
    } catch (error) {
      console.error('Error posting user payment:', error);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={() => navigate('/subscription_plans')}
          />
          <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription</h1>
        </div>
        <div className="mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/crown.svg"
            alt="crown"
            className="h-8 w-8"
          />
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <div className="flex justify-center w-4/5 lg:ml-5 mx-auto overflow-auto">
        <div className="w-full md:w-[550px] bg-[#F0F0F0] mt-7">
          {plan && (
            <GoldSupplier
              backgroundColor={backgroundColor}
              textColor="#F9F9F9"
              title={plan.name}
              subtitle="Standard Extended"
              price={`${plan.price}$`}
              height="150px"
              width="full"
            />
          )}
         
         <ul className="font-semibold mt-4">
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Exclusive Discounts Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Exclusive Discounts</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Free Shipping Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Free Shipping</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Early Access to Sales Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Early Access to Sales</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Personalized Recommendations Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Personalized Recommendations</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Dedicated Customer Support Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Dedicated Customer Support</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Ad-Free Experience Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Ad-Free Experience</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Content Access Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Content Access</span>
            </li>
            <li className="flex items-center mb-3">
              <img src="/Assets/crown.svg" alt="Early Product Testing Icon" className="w-6 h-6 inline-block mr-2" />
              <span>Early Product Testing</span>
            </li>
          </ul>
          <a
            href="#"
            className="text-[#DB0000] font-light text-[16px] leading-[19.06px] underline ml-4 mt-3"
          >
            More Information
          </a>
          <div className="lg:mx-16 mt-5">
            <Button
              text="Pay Now"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handlePayNowClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionDetails;
