import React, { useState } from 'react';
import { PiEyeLight } from "react-icons/pi";
import { PiEyeSlashLight } from "react-icons/pi";
import { FaLock, FaUnlock } from 'react-icons/fa';
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";

const PasswordInputField = ({
  placeholder = "Enter password",
  name,
  value,
  onChange,
  inputClassName = '',
  fontFamily = 'font-helvetica',
  borderRadius = "4px", // Set default border radius to 4px
  backgroundColor = "#FFFFFF",
  padding = "12px",
  marginBottom = "auto",
  placeholderColor = '#8D8D8D', 
  autoComplete = "new-password",
  textColor = '#8D8D8D',
  fontSize = "12px", 
  fontWeight = 400,
  borderColor = "#E8E8E8", // Add border color prop
  borderWidth = "1px", // Add border width prop
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <style>
        {`
          .custom-placeholder::placeholder {
            color: ${placeholderColor};
            font-family: 'Poppins', sans-serif;
            font-weight: ${fontWeight}; 
            font-size: ${fontSize}; 
          }
          .custom-placeholder {
            font-family: 'Poppins', sans-serif;
            font-weight: ${fontWeight}; 
            font-size: ${fontSize};
          }
        `}
      </style>
      <div className={`w-full mb-2 ${inputClassName}`}>
        <div
          className="flex items-center"
          style={{
            borderRadius,
            backgroundColor,
            padding,
            marginBottom,
            border: `${borderWidth} solid ${borderColor}`, // Apply the border style
          }}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            className={`w-full custom-placeholder ${fontFamily}`}
            name={name}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            style={{
              backgroundColor,
              border: 'none',
              outline: 'none',
              color: textColor,
              fontFamily: 'Poppins, sans-serif',
              fontWeight: fontWeight,
              fontSize: fontSize,
            }}
          />
          <div className="ml-2 cursor-pointer" onClick={togglePasswordVisibility}>
          {showPassword ? <CiUnlock className="text-[#9F9F9F]" /> : <CiLock className="text-[#9F9F9F]" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordInputField;
