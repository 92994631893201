// companyDocsController.js
import { post_documents } from './company_details';
import { showToast } from '../View/Components/toast';

export const saveCompanyDocuments = async (documents, navigate, setLoading) => {
  if (!documents['Tenancy Contract']) {
    showToast('Please add the Tenancy Contract before saving.', 'error');
    return;
  }

  setLoading(true);

  try {
    const response = await post_documents(
      documents['Tenancy Contract'],
      documents['Trading Register'],
      documents['Trading License']
    );
    
    const message = response.data.message;

    if (response.status === 202) {
      console.log(response)
      navigate('/home');
    } else {
      // showToast('Failed to save documents', 'error');
    }
  } catch (error) {
    console.error('Error updating documents:', error);
    // showToast('Error updating documents', 'error');
  } finally {
    setLoading(false);
  }
};
