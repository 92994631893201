import React from 'react';

import GoldSupplier from '../../Components/gold_supplier';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';

const PaymentSuccessful = () => {
  const navigate = useNavigate();
  
  const handleBackClick = () => {
    navigate('/subscription_details');
  };
  
  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
    <Sidebar/>
    <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
              <div className="w-full flex justify-start items-center space-x-2">
                  
                <img
                  src="/Assets/arrow_back.svg"
                  alt="Back"
                  className="cursor-pointer mr-2 h-6 w-6"
                  onClick={handleBackClick}
                />
                <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription</h1>   </div>
                 <div className="mr-5 md:mr-7 pr-5 flex self-end"> 
            <img
              src="/Assets/crown.svg" 
              alt="crown"
              className="h-8 w-8"
              
            />
               </div>
            </div>
    
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <div className="flex justify-center w-4/5 lg:ml-10 mx-auto">
    <div className="w-full md:w-[550px] bg-[#F0F0F0] mt-7  ">
          <div className='ml-2 mr-2 mb-2 mt-2'>
            <GoldSupplier 
              backgroundColor="#DB0000" 
              textColor="#F9F9F9" 
              title="Gold Supplier" 
              subtitle="Standard Extended" 
              price="2999$/year" 
              height="150px" 
              width="full" 
            />
          </div>
          <div className='mt-5 flex justify-center'>
        <img src="Assets/pay_successful.svg" alt="company icon"className="lg:w-80 lg:h-72 " />
        </div>
       
        <div className='flex justify-center  mt-4 ' >
     
        <p className="text-lg font-bold">Payment Successful</p>
        </div>
        
        <div className="flex justify-center mt-5 lg:mx-16">
        <Button
          text="Done"
          borderColor="border-red-600"
          backgroundColor="bg-[#DB0000]"
          textColor="text-white"
          navigateTo="/details_page"
        />
        </div>
        
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
